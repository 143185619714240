import { NavbarProps } from "../types/types";
import { HiOutlineMenu } from "react-icons/hi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { TfiClose } from "react-icons/tfi";
import { Link } from "react-router-dom";
import Back from "./Back";
import { RiMenuUnfold2Line } from "react-icons/ri";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Navbar = ({
  toggleTabNavigation,
  tabNavigation,
  setShowSidebar,
  showSidebar,
}: NavbarProps) => {
  const auth = sessionStorage.getItem("authState");
  const authState = auth && JSON.parse(auth);
  return (
    <div className="flex z-10 fixed gap-3 w-full items-center justify-between bg-white py-4 px-4 lg:px-8">
      <div className="flex lg:hidden items-center gap-3 w-fit">
        <figure className="w-14">
          <img src="/assets/logo.png" alt="logo" />
        </figure>
        <div onClick={toggleTabNavigation} className="text-3xl lg:hidden">
          {!tabNavigation ? <HiOutlineMenu /> : <TfiClose />}
        </div>
      </div>
      <div
        className={`items-center gap-3 w-fit ${
          showSidebar ? "hidden" : "md:flex"
        }`}
      >
        <div
          className="bg-gray-100 rounded-[50%] w-10 h-10 flex justify-center items-center mt-1"
          onClick={() => setShowSidebar(true)}
        >
          <MdKeyboardDoubleArrowRight className="text-secondary text-xl cursor-pointer" />
        </div>
        <figure className="w-24">
          <img src="/assets/logo.png" alt="logo" />
        </figure>
      </div>
      <section
        className={`flex items-center gap-4 justify-between ${
          showSidebar ? "w-[80%]" : "w-full"
        }`}
      >
        <div className="hidden md:block">
          <Back />
        </div>
        <section className="w-full">
          <input
            type="search"
            name="search"
            id="search"
            disabled
            className="bg-[#F7F8FA] p-3 w-full text-sm md:block hidden"
          />
        </section>
        <section className="flex gap-3 items-center">
          <div className="flex items-center">
            {/* <IoIosSearch className="w-8 h-8 md:hidden block" /> */}
            <button type="button">
              <Link to={"notifications"}>
                <IoIosNotificationsOutline className="w-8 h-8" />
              </Link>
            </button>
          </div>
          <div className="flex items-center">
            <figure className="w-12 h-12 rounded-md">
              <img src="/assets/Avatar.png" alt="avatar" />
            </figure>
            <div className="p-3 text-sm ">
              <p className="font-bold">{authState.firstName}</p>
              <p className="text-[#93A3AB]">{authState.adminLevel}</p>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Navbar;
