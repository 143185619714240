import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { useAppSelector } from "../../redux/hooks";

const OutrightIncompleteUsers = ({
  isLoading,
  searchedUser,
  searchResults,
  handleDeleteClick,
  indexOfFirstPost,
  currentItems,
}: any) => {
  const { status } = useAppSelector((store) => store.users);

  return (
    <tbody className="px-4 text-nowrap">
      {status === "loading" ? (
        <tr>
          <td colSpan={11} className="text-center p-3">
            <LoadingSpinner />
          </td>
        </tr>
      ) : searchedUser.length > 0 ? (
        searchResults && searchResults.length > 0 ? (
          searchResults
            .slice()
            .reverse()
            .map((data: any, index: number) => (
              <tr
                className="border-b border-gray-300 py-2 hover:bg-gray-50"
                key={index}
              >
                <td className="text-secondary p-2">
                  {index + indexOfFirstPost + 1}
                </td>
                <td className="p-2">
                  {data.firstName && data.lastName
                    ? `${data.lastName} ${data.firstName}`
                    : "Not available"}
                </td>
                <td className="p-2">{data.email}</td>
                <td className="p-2">
                  {data.phoneNumber ? data.phoneNumber : "Not available"}
                </td>
                <td className="p-2 text-nowrap flex justify-evenly">
                  <button type="button" title="call customer">
                    <a href={`tel:${data?.phoneNumber}`}>
                      <FaPhone />
                    </a>
                  </button>
                  <button type="button" title="text via whatsapp">
                    <a
                      href={`https://wa.me/${data?.phoneNumber?.replace(
                        /^0/,
                        "234"
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp />
                    </a>
                  </button>
                </td>

                <td className="p-2 capitalize">
                  {data.address ? data.address.toLowerCase() : "Not available"}
                </td>
                <td className="p-2">{data.isComplete}</td>
                <td className="p-2">
                  {new Date(data.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </td>
                <td className="p-2">
                  {data.userPassword ? data.userPassword : "not found"}
                </td>
                <td className="p-2">
                  <button
                    type="button"
                    className={`${
                      isLoading ? "bg-red-100" : "bg-red-600"
                    } p-2 text-sm text-white rounded-md`}
                    onClick={() => handleDeleteClick(data)}
                    disabled={isLoading}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td colSpan={10} className="text-center text-secondary p-3">
              User with email not found
            </td>
          </tr>
        )
      ) : currentItems.length > 0 ? (
        currentItems.map((data: any, index: number) => {
          return (
            <tr
              className="border-b border-gray-300 py-2 hover:bg-gray-50"
              key={index}
            >
              <td className="text-secondary p-2">
                {index + indexOfFirstPost + 1}
              </td>
              <td className="p-2">
                {data.firstName && data.lastName
                  ? `${data.firstName} ${data.lastName}`
                  : "Not available"}
              </td>
              <td className="p-2">{data.email}</td>
              <td className="p-2">
                {data.phoneNumber ? data.phoneNumber : "Not available"}
              </td>
              <td className="p-2 text-nowrap flex justify-evenly">
                <button type="button" title="call customer">
                  <a href={`tel:${data?.phoneNumber}`}>
                    <FaPhone />
                  </a>
                </button>
                <button type="button" title="text via whatsapp">
                  <a
                    href={`https://wa.me/${data?.phoneNumber?.replace(
                      /^0/,
                      "234"
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp />
                  </a>
                </button>
              </td>
              <td className="p-2 capitalize">
                {data.address ? data.address.toLowerCase() : "Not available"}
              </td>
              <td className="p-2">{data.isComplete}</td>
              <td className="p-2">
                {new Date(data.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </td>
              <td className="p-2">
                {data.userPassword ? data.userPassword : "not found"}
              </td>
              <td className="p-2">
                <button
                  type="button"
                  className={`${
                    isLoading ? "bg-red-100" : "bg-red-600"
                  } p-2 text-sm text-white rounded-md`}
                  onClick={() => handleDeleteClick(data)}
                  disabled={isLoading}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={10} className="text-center text-secondary p-5">
            No user available
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default OutrightIncompleteUsers;
