import { useState, useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { formatNumber } from "../redux/thunk";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import BarChart from "../pages/Dashboard/BarChart";
import LineGraph from "../pages/Dashboard/LineGraph";
import ActivityLog from "./dashboard/ActivityLog";

const AdminBox = ({
  isLoading,
  figure,
  text,
  icon,
  change,
}: {
  isLoading: boolean;
  figure: number;
  text: string;
  icon: string;
  change: number;
}) => {
  return (
    <div
      className={`bg-white p-5 rounded-md border border-green-300 shadow-md min-w-[20rem] flex-shrink-0 ${
        isLoading && "animate-pulse "
      }`}
    >
      <section className=" mb-4 gap-5 flex justify-between items-center">
        <div>
          <h1 className="text-sm font-medium pb-4 text-gray-700 capitalize">
            {text}
          </h1>
          <h1 className="font-semibold text-3xl break-words">
            {isLoading ? "0" : formatNumber(figure)}
          </h1>
        </div>
        <figure>
          <img src={icon} alt="" />
        </figure>
      </section>
      <section className="flex items-center gap-1">
        <span
          className={`inline-flex text-sm items-center gap-2 ${
            change > 0 ? "text-secondary" : "text-red-400"
          }`}
        >
          {change > 0 ? <FaArrowTrendUp /> : <FaArrowTrendDown />}
          {Math.abs(change)}%
        </span>
        <p className="text-sm">
          {change > 0 ? "Up from last month" : "Down from last month"}
        </p>
      </section>
    </div>
  );
};

const AdminDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [stats, setStats] = useState<any>({});
  const [loanPercent, setLoanPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState("month");

  useEffect(() => {
    if (Object.keys(stats).length > 0) {
      const percent = Math.round(
        (stats.totalPaidLoans.overall / stats.totalLoans.overall) * 100
      );
      setLoanPercent(percent);
    }
  }, [stats]);

  useEffect(() => {
    getDashboardStats();
  }, []);

  const getDashboardStats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getDashboardStatistic`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setStats({});
    }
  };

  return (
    <main>
      <div className="flex items-center gap-5 pb-10 mb-8 mt-[1.5rem] overflow-x-auto w-full">
        <AdminBox
          text="Verified Users"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0
              ? stats.verifiedCustomers.overall
              : "0"
          }
          icon={"/assets/users.png"}
          change={
            Object.keys(stats).length > 0 ? Math.floor(stats.verifiedCustomers.change) : 0
          }
        />
        <AdminBox
          text="Total Students"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0 ? stats.studentCustomers.overall : "0"
          }
          icon={"/assets/yellowBox.png"}
          change={
            Object.keys(stats).length > 0 ? Math.floor(stats.studentCustomers.change) : 0
          }
        />
        <AdminBox
          text="Total Employed"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0
              ? stats.employedCustomers.overall
              : "0"
          }
          icon={"/assets/greenGraph.png"}
          change={
            Object.keys(stats).length > 0 ? Math.floor( stats.employedCustomers.change) : 0
          }
        />
        <AdminBox
          text="Total Self-Employed"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0
              ? stats.selfEmployedCustomers.overall
              : "0"
          }
          icon={"/assets/yellowBox.png"}
          change={
            Object.keys(stats).length > 0
              ? Math.floor(stats.selfEmployedCustomers.change)
              : 0
          }
        />
        <AdminBox
          text="Total Orders"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0 ? stats.totalOrders.overall : "0"
          }
          icon={"/assets/users.png"}
          change={
            Object.keys(stats).length > 0 ? Math.floor(stats.totalOrders.change) : 0
          }
        />
        <AdminBox
          text="Total Loan"
          isLoading={isLoading}
          figure={Math.round(
            Object.keys(stats).length > 0
              ? stats.totalLoans.overall.toFixed(2)
              : "0"
          )}
          icon={"/assets/yellowBox.png"}
          change={Math.round(
            Object.keys(stats).length > 0
              ? Math.floor(stats.totalLoans.change)
              : 0
          )}
        />
        <AdminBox
          text="Total paid"
          isLoading={isLoading}
          figure={Math.round(
            Object.keys(stats).length > 0
              ? stats.totalPaidLoans.overall.toFixed(2)
              : "0"
          )}
          icon={"/assets/greenGraph.png"}
          change={
            Object.keys(stats).length > 0
              ? Math.floor(stats.totalPaidLoans.change)
              : 0
          }
        />
        <AdminBox
          text="Total bad loan"
          isLoading={isLoading}
          figure={0}
          icon={"/assets/users.png"}
          change={0}
        />
        <AdminBox
          text="Expected for this month"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0
              ? stats.totalExpectedPayments.overall.toFixed(2)
              : "0"
          }
          icon={"/assets/yellowBox.png"}
          change={
            Object.keys(stats).length > 0
              ? Math.floor(stats.totalExpectedPayments.change)
              : 0
          }
        />
        <AdminBox
          text="faqs count"
          isLoading={isLoading}
          figure={Object.keys(stats).length > 0 ? stats.faqs.overall : "0"}
          icon={"/assets/greenGraph.png"}
          change={Object.keys(stats).length > 0 ? stats.faqs.change : "0"}
        />
        <AdminBox
          text="Total disapprovals"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0
              ? stats.disapprovedOrders.overall
              : "0"
          }
          icon={"/assets/users.png"}
          change={
            Object.keys(stats).length > 0 ? Math.floor(stats.disapprovedOrders.change) : 0
          }
        />
        <AdminBox
          text="Total approved"
          isLoading={isLoading}
          figure={
            Object.keys(stats).length > 0 ? stats.approvedOrders.overall : "0"
          }
          icon={"/assets/yellowBox.png"}
          change={
            Object.keys(stats).length > 0 ? Math.floor(stats.approvedOrders.change) : 0
          }
        />
      </div>
      <section className="flex gap-3">
        <section className="flex flex-col gap-3 w-1/2 overflow-y-hidden">
          <section className="bg-white rounded-b-lg">
            <div className="mb-10">
              <h3 className="font-bold text-lg">Orders</h3>
              <p className="font-bold text-sm">This Year</p>
            </div>
            <section className="flex items-end relative mb-10">
              <p className="border-b-2 font-semibold">Order Summary</p>
            </section>
            <section>
              <LineGraph />
            </section>
          </section>

          <section className="bg-white p-5 rounded-b-lg">
            <div className="flex justify-between items-center mb-10">
              <h3 className="font-semibold text-lg">Expectation Reached</h3>
            </div>
            <section className="px-2 mb-5 w-4/5 mx-auto text-center">
              <p className="text-sm text-gray-400">Verified Users Reached</p>
              <p className="text-3xl my-3">
                {Object.keys(stats).length > 0
                  ? formatNumber(stats.verifiedCustomers.overall)
                  : "0"}
              </p>
              <div className="flex justify-between">
                <button
                  className={`text-sm font-semibold ${
                    active === "day" ? "text-black" : "text-gray-400"
                  }`}
                  onClick={() => setActive("day")}
                >
                  Day
                </button>
                <button
                  className={`text-sm font-semibold ${
                    active === "week" ? "text-black" : "text-gray-400"
                  }`}
                  onClick={() => setActive("week")}
                >
                  Week
                </button>
                <button
                  className={`text-sm font-semibold ${
                    active === "month" ? "text-black" : "text-gray-400"
                  }`}
                  onClick={() => setActive("month")}
                >
                  Month
                </button>
                <button
                  className={`text-sm font-semibold ${
                    active === "year" ? "text-black" : "text-gray-400"
                  }`}
                  onClick={() => setActive("year")}
                >
                  Year
                </button>
              </div>
            </section>
            <div className="mt-10">
              <div className="flex justify-between items-center mb-10">
                <h3 className="font-semibold text-lg">REQUEST APPROVAL</h3>
              </div>
              <section className="flex items-end px-2 relative mb-5">
                <p className="border-b-2 font-bold border-red-500 block w-fit">
                  Bar
                </p>
                <img
                  src="/assets/line.svg"
                  alt=""
                  className="absolute -bottom-1 right-0 w-11/12"
                />
              </section>
            </div>
            <section className="h-full">
              <BarChart />
            </section>
          </section>
        </section>
        <section className="w-1/2">
          <section className="w-full">
            <h3 className="font-bold text-lg">Loans</h3>
            <p className="font-semibold text-2xl my-2 inline-flex items-center gap-3">
              N
              {formatNumber(
                Object.keys(stats).length > 0
                  ? stats.totalLoans.overall.toFixed(2)
                  : "0"
              )}
              <span className="font-normal text-sm">Total Loan Disbursed</span>
            </p>
            <div className="border bg-gray-200 rounded-full w-full h-3">
              <div
                className="bg-secondary h-full rounded-full"
                style={{ width: `${loanPercent}%` }}
              ></div>
            </div>
            <section className="my-7 flex items-center gap-10">
              <section className="flex gap-2 items-center">
                <div className="w-10 bg-secondary h-1"></div>
                <div>
                  <p className="text-secondary text-sm">Total paid</p>
                  <p className="font-semibold text-sm">
                    N
                    {Object.keys(stats).length > 0
                      ? formatNumber(Math.round(stats.totalPaidLoans.overall))
                      : 0}
                  </p>
                </div>
              </section>
              <section className="flex gap-2 items-center">
                <div className="w-10 bg-gray-200 border h-1"></div>
                <div>
                  <p className="text-red-500 text-sm">Total Bad</p>
                  <p className="font-semibold text-sm">N0</p>
                </div>
              </section>
            </section>
            <ActivityLog />
          </section>
        </section>
      </section>
    </main>
  );
};

export default AdminDetails;
