import { MdOutlineCancel } from "react-icons/md";
import Modal, { ModalContent } from "../elements/Modal";
import { formatNumber } from "../../redux/thunk";

const LoanInfo = ({ loanDetails, closeModal }: any) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <section className="w-full h-[28rem] overflow-y-scroll p-2">
          <h3 className="font-bold">Loan Information</h3>
          <div className="flex justify-end">
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <section className="flex items-center gap-3">
            <figure className="w-16 h-16">
              <img
                src="/assets/Avatar.png"
                className="w-full h-full object-cover"
                alt="avatar"
              />
            </figure>
            <section>
              <h3 className="font-bold mb-2">
                {loanDetails.lastName} {loanDetails.firstName}
              </h3>
              <p className="text-gray-400 text-sm">Wallet Balance</p>
              <p className="tetx-sm font-semibold">
                {formatNumber(Math.floor(loanDetails.walletBalance))}
              </p>
            </section>
          </section>
          <section className="mt-3">
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Loan date:</p>
              <p className="w-1/2">
                {new Date(loanDetails.loanInitialDate).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                )}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Amount collected:</p>
              <p className="w-1/2">
                {formatNumber(loanDetails.amountCollected.toFixed(1))}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Amount paid:</p>
              <p className="w-1/2">
                {formatNumber(loanDetails.amountPaid.toFixed(1))}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Defaulted amount:</p>
              <p className="w-1/2">
                {formatNumber(loanDetails.defaultAmount.toFixed(1))}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Installment default:</p>
              <p className="w-1/2">
                {formatNumber(loanDetails.InstallmentDefault.toFixed(1))}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Overdue amount:</p>
              <p className="w-1/2">
                {formatNumber(loanDetails.OverdueAmount.toFixed(1))}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p>Order status:</p>
              <p className="w-1/2">{loanDetails.orderStatus}</p>
            </div>
          </section>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default LoanInfo;
