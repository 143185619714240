import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

export const Assignment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("All");
  const [admins, setAdmins] = useState([]);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const allPagesToSelect = [
    "Push notification",
    "News update",
    "Web Visitors",
    "Packages",
    "Store",
    "Logistics",
    "Restaurants",
    "Referrals",
    "Dietitians",
    "Vendors",
    "Staff",
    "Operations",
    "Virtual Account",
    "E-Verification 1",
    "SE-Verification 1",
    "S-Verification 1",
    "Document Check",
    "Credit Score",
    "Institute Upload",
    "Next Pay",
    "E-mandate",
    "Initiate Mandate",
    "Incomplete Users",
    "User Details",
    "Orders",
    "Order History",
    "Defaulters",
    "Create Admin",
    "Customer Care",
    "FAQ",
    "Repayment History",
    "Web App",
  ];

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllAdmins = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-admins-except-superadmin`
      );
      setIsLoading(false);
      setAdmins(res.data);
    } catch (error) {
      console.error(error);
      setAdmins([]);
      setIsLoading(false);
    }
  };

  const addRoleHandler = async ({
    id,
    roleValue,
  }: {
    id: string;
    roleValue: string;
  }) => {
    const payload = {
      staffId: id,
      role: roleValue,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/addAdminRole`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getAllAdmins();
      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error("Unable to add admin role!");
    }
  };

  const removeRoleHandler = async (adminID: string, roleId: string) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete-admin-role/${adminID}/${roleId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message);
      getAllAdmins();
    } catch (error) {
      console.error(error);
      toast.error("Unable to remove admin role!");
    }
  };

  return (
    <main className="w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`overflow-x-auto ${isLoading && "animate-pulse h-[50vh]"}`}
        >
          <div className="flex items-center justify-between p-6">
            <h1 className="text-base font-semibold">Assignment</h1>
            <div className="flex items-center">
              <label htmlFor="filterAdmin">
                Sort by:
                <select
                  name="filterAdmin"
                  id="filterAdmin"
                  className="border p-2 ml-4"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="ssa">SSA</option>
                  <option value="admin">Admin</option>
                </select>
              </label>
            </div>
          </div>
          <section className="w-full p-5 overflow-x-auto">
            <table
              className={`p-5 text-center w-[1200px] ${
                isLoading && "animate-pulse h-[60vh]"
              }`}
            >
              <thead className="bg-gray-50 font-bold h-12">
                <tr className="p-5">
                  <th className="text-sm text-nowrap px-2">S/N</th>
                  <th className="text-sm text-nowrap px-2">Name</th>
                  {allPagesToSelect.map((page, index) => (
                    <th className="text-sm text-nowrap px-2" key={index}>
                      {page}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="px-4">
                {admins && admins.length > 0 ? (
                  admins
                    .filter((admin: any) => {
                      if (filter === "all") {
                        return admin;
                      } else if (filter === "ssa") {
                        return admin.adminLevel === "semi-superadmin";
                      } else if (filter === "admin") {
                        return admin.adminLevel === "admin";
                      }
                      return true;
                    })
                    .map((filteredAdmin: any, index) => {
                      const handleCheck = (value: string) => {
                        return filteredAdmin.adminRole.some(
                          (role: any) => role.role === value
                        );
                      };

                      return (
                        <tr
                          className="border-b border-gray-300 py-3"
                          key={index}
                        >
                          <td className="text-secondary p-2 font-semibold">
                            {index + 1}
                          </td>
                          <td className="p-2 text-nowrap">
                            {filteredAdmin.lastName} {filteredAdmin.firstName}
                          </td>
                          {allPagesToSelect.map((page, index) => (
                            <td className="p-2" key={index}>
                              <input
                                type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                checked={handleCheck(page)}
                                onChange={() => {
                                  if (handleCheck(page)) {
                                    const roleId = filteredAdmin.adminRole.find(
                                      (role: any) => role.role === page
                                    )._id;
                                    removeRoleHandler(filteredAdmin.id, roleId);
                                  } else {
                                    addRoleHandler({
                                      id: filteredAdmin.id,
                                      roleValue: page,
                                    });
                                  }
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-secondary text-center pt-2 text-sm"
                    >
                      No Admins Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </main>
  );
};
