import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { allCustomerNextPay, formatNumber } from "../redux/thunk";
import Pagination from "../components/Pagination";
import { IoSearchOutline } from "react-icons/io5";
import { FaPhone, FaRegCopy, FaWhatsapp } from "react-icons/fa6";
import toast from "react-hot-toast";

export const NextPay = () => {
  const [occupationFilter, setOccupationFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        data?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(allCustomerNextPay()).then((response: any) => {
      setData(response.payload);
      setIsLoading(false);
    });
  }, [dispatch]);
  const filterAndMapData = () => {
    if (data.message) {
      return [];
    }

    const nextPayData = data.filter((data: any) => {
      if (occupationFilter === "all") {
        return data.employment;
      } else if (occupationFilter === "employed") {
        return data.employment === "employed";
      } else if (occupationFilter === "self-employed") {
        return data.employment === "selfEmployed";
      } else if (occupationFilter === "student") {
        return data.employment === "student";
      }
      return true;
    });
    return nextPayData.filter((data: any) => {
      const currentDate = new Date();
      const apiDate = new Date(data.nextPayDate);
      const timeDifference = apiDate.getTime() - currentDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      // console.log(`Number of days difference for ${data.nextPayDate}: ${daysDifference}`);

      if (dateFilter !== "others") {
        const selectedDays = parseInt(dateFilter, 10);
        return daysDifference === selectedDays;
      } else {
        return daysDifference > 7;
      }
    });
  };

  const filteredData = filterAndMapData();
  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleCopyClick = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <main className=" w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <div className="flex items-center justify-between w-full">
              <h1 className="text-base font-semibold ">Next Pay</h1>
              <div className="relative md:w-[30rem] w-fit">
                <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
                <input
                  type="search"
                  name="searchedUser"
                  id="searchedUser"
                  value={searchedUser}
                  onChange={(e) => searchForUsersWithEmail(e.target.value)}
                  placeholder="Search user using email"
                  className="border p-2 rounded-md indent-7 w-full"
                  disabled={data.length === 0}
                />
              </div>
              <div>
                <label htmlFor="filterOccupation">
                  Sort by:
                  <select
                    name="filterOccupation"
                    id="filterOccupation"
                    className="border p-2 ml-4"
                    onChange={(e) => setOccupationFilter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="employed">Employed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Students</option>
                  </select>
                </label>
                <label htmlFor="filterDays">
                  <select
                    name="filterDays"
                    id="filterDays"
                    className="border p-2 ml-4"
                    onChange={(e) => setDateFilter(e.target.value)}
                  >
                    <option value="1">Today</option>
                    <option value="2">1 day</option>
                    <option value="3">2 days</option>
                    <option value="4">3 days</option>
                    <option value="5">4 days</option>
                    <option value="6">5 days</option>
                    <option value="7">6 days</option>
                    <option value="others">Others</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <section className="overflow-x-auto">
            <table className="w-full" style={{ minWidth: "1200px" }}>
              <thead className="bg-gray-50 font-bold text-left">
                <tr>
                  <th className="p-3 ">S/N</th>
                  <th className="p-3">Name</th>
                  <th className="p-3">Phone number</th>
                  <th className="p-3">Contact</th>
                  <th className="p-3">Email</th>
                  <th className="p-3 ">BVN</th>
                  <th className="p-3">Loan amount</th>
                  <th className="p-3">Eligible amount</th>
                  <th className="p-3">Amount left</th>
                  <th className="p-3">Amount to be paid</th>
                  <th className="p-3">Next Pay Date</th>
                </tr>
              </thead>
              <tbody className="px-4">
                {filteredData.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={7} className="p-10 text-secondary">
                      No Next Pay available!
                    </td>
                  </tr>
                ) : searchedUser.length > 0 ? (
                  searchResults && searchResults.length > 0 ? (
                    searchResults.map((data: any, index: any) => (
                      <tr className="border-b hover:bg-gray-50" key={index}>
                        <td className="text-secondary px-2 py-4">
                          {index + 1}
                        </td>
                        <td className="px-2 py-4">{data?.fullname}</td>
                        <td className="px-2 py-4">{data?.phoneNumber}</td>
                        <td className="p-2 flex justify-evenly">
                          <button type="button" title="call customer">
                            <a href={`tel:${data?.phoneNumber}`}>
                              <FaPhone />
                            </a>
                          </button>
                          <button type="button" title="text via whatsapp">
                            <a
                              href={`https://wa.me/${data?.phoneNumber?.replace(
                                /^0/,
                                "234"
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaWhatsapp />
                            </a>
                          </button>
                        </td>

                        <td className="px-2 py-4">{data?.email}</td>
                        <td className="px-2 py-4 flex items-end gap-2 justify-between">
                          <p>{data?.bvn}</p>
                          <button
                            type="button"
                            className=""
                            onClick={() => handleCopyClick(data?.bvn)}
                          >
                            <FaRegCopy className="w-5 h-5 text-gray-500" />
                          </button>
                        </td>
                        <td className="px-2 py-4">
                          {formatNumber((data?.loanAmount).toFixed(1))}
                        </td>
                        <td className="px-2 py-4">
                          {formatNumber((data?.eligibleAmount).toFixed(1))}
                        </td>
                        <td className="px-2 py-4">
                          {formatNumber((data?.amountLeft).toFixed(1))}
                        </td>
                        <td className="px-2 py-4">
                          {formatNumber((data?.amountToBePaid).toFixed(1))}
                        </td>
                        <td className="px-2 py-4">
                          {new Date(data?.nextPayDate).toLocaleString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        className="text-center text-secondary p-3"
                      >
                        User with email not found
                      </td>
                    </tr>
                  )
                ) : (
                  currentItems.map((data: any, index: any) => (
                    <tr className="border-b hover:bg-gray-50" key={index}>
                      <td className="text-secondary px-2 py-4">
                        {index + indexOfFirstPost + 1}
                      </td>
                      <td className="px-2 py-4">{data?.fullname}</td>
                      <td className="px-2 py-4">{data?.phoneNumber}</td>
                      <td className="p-2 flex justify-evenly">
                        <button type="button" title="call customer">
                          <a href={`tel:${data?.phoneNumber}`}>
                            <FaPhone />
                          </a>
                        </button>
                        <button type="button" title="text via whatsapp">
                          <a
                            href={`https://wa.me/${data?.phoneNumber?.replace(
                              /^0/,
                              "234"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaWhatsapp />
                          </a>
                        </button>
                      </td>
                      <td className="px-2 py-4">{data?.email}</td>
                      <td className="px-2 py-4 flex items-end gap-2 justify-between">
                        <p>{data?.bvn}</p>
                        <button
                          type="button"
                          className=""
                          onClick={() => handleCopyClick(data?.bvn)}
                        >
                          <FaRegCopy className="w-5 h-5 text-gray-500" />
                        </button>
                      </td>
                      <td className="px-2 py-4">
                        {formatNumber((data?.loanAmount).toFixed(1))}
                      </td>
                      <td className="px-2 py-4">
                        {formatNumber((data?.eligibleAmount).toFixed(1))}
                      </td>
                      <td className="px-2 py-4">
                        {formatNumber((data?.amountLeft).toFixed(1))}
                      </td>
                      <td className="px-2 py-4">
                        {formatNumber((data?.amountToBePaid).toFixed(1))}
                      </td>
                      <td className="px-2 py-4">
                        {new Date(data?.nextPayDate).toLocaleString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </section>
          <section className="p-3 my-5">
            <Pagination
              length={
                searchResults.length > 0
                  ? searchResults.length
                  : filteredData.length
              }
              itemsPerPage={itemsPerPage}
              handlePagination={handlePagination}
              currentPage={currentPage}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </section>
        </div>
      </div>
    </main>
  );
};
