import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { verificationStageOne, verificationStageTwo } from "../redux/thunk";

const useDataUpdate = () => {
  const [hasUpdate, setHasUpdate] = useState({
    employed: false,
    selfEmployed: false,
    student: false,
    docCheck: false,
  });

  const [lastCounts, setLastCounts] = useState({
    employed: 0,
    selfEmployed: 0,
    student: 0,
    docCheck: 0,
  });

  const { employed, selfEmployed, student, documentCheck } = useAppSelector(
    (store) => store.verification
  );

  const dispatch = useAppDispatch();

  // 🔹 Fetch Data Without Causing Infinite Loops
  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        await dispatch(verificationStageOne()).unwrap();
        await dispatch(verificationStageTwo()).unwrap();
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };

    fetchUserCount();
    // Polling every 5 seconds
    const interval = setInterval(fetchUserCount, 5000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const newCounts = {
      employed: employed.length,
      selfEmployed: selfEmployed.length,
      student: student.length,
      docCheck: documentCheck.length,
    };

    let shouldUpdate = false;
    const updatedStatus = { ...hasUpdate };

    Object.keys(newCounts).forEach((key) => {
      const typedKey = key as keyof typeof newCounts;
      if (newCounts[typedKey] > lastCounts[typedKey]) {
        updatedStatus[typedKey] = true;
        shouldUpdate = true;
      }
    });

    if (shouldUpdate) {
      setHasUpdate(updatedStatus);
      setLastCounts(newCounts);
    }
  }, [
    employed.length,
    selfEmployed.length,
    student.length,
    documentCheck.length,
  ]);
  return { hasUpdate, setHasUpdate };
};

export default useDataUpdate;
