import { useEffect, useState } from "react";
import { PersonalInfo } from "./PersonalInfo";
import OrderHistory from "./OrderHistory";
import PaymentHistory from "./PaymentHistory";
import LoanHistory from "./LoanHistory";
import { MdClose } from "react-icons/md";
import { Preloader } from "../../components/elements/Preloader";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import {
  getAllIncompleteUsers,
  getFlexibleUsers,
  getOutrightUsers,
  verificationStageOne,
  verificationStageTwo,
} from "../../redux/thunk";

export const CustomerCare = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState({
    findUser: false,
    getLoan: false,
  });
  const [search, setSearch] = useState("");
  const [user, setUser] = useState<any>([]);
  const [userId, setUserId] = useState("");
  const [btnClicked, setBtnClicked] = useState("");
  const { flexible, outright, incomplete } = useAppSelector(
    (state) => state.users
  );
  const { employed, selfEmployed, student, documentCheck } = useAppSelector(
    (store) => store.verification
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getFlexibleUsers());
    dispatch(getOutrightUsers());
    dispatch(getAllIncompleteUsers());
    dispatch(verificationStageOne());
    dispatch(verificationStageTwo());
  }, [dispatch]);

  const handleSearch = () => {
    setIsLoading((prev) => ({
      ...prev,
      findUsers: true,
    }));
    setBtnClicked("");

    try {
      const allUsers = [
        ...flexible,
        ...outright,
        ...incomplete,
        ...employed,
        ...selfEmployed,
        ...student,
        ...documentCheck,
      ];
      const findUser: any = allUsers.filter(
        (user: any) => search.trim() === user.email
      );
      if (findUser) {
        setUser(findUser);
        setUserId(findUser[0]._id);
      } else {
        setUser("Not found");
      }

      setIsLoading((prev) => ({
        ...prev,
        findUsers: false,
      }));
    } catch (error) {
      console.error(error);
      setUser("Not found");
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        findUsers: false,
      }));
    }
  };

  const handleClose = () => {
    setUser([]);
  };

  return (
    <main>
      <div className="flex justify-between items-center mr-10">
        <h2 className="font-bold text-xl">Customer Care</h2>
        <BiSolidMessageRoundedDetail
          title="Chat Notifications"
          onClick={() => navigate("/dashboard/customer-care/customer-chats")}
          className="text-secondary w-9 h-9 cursor-pointer"
        />
      </div>
      <div className="mt-5">
        <input
          type="search"
          name="search"
          id="search"
          className="text-sm p-3 rounded-md w-full bg-gray-100"
          placeholder="Enter customer's email address"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          type="button"
          className="bg-secondary text-sm text-white p-2 rounded-lg my-2"
          onClick={handleSearch}
        >
          {isLoading.findUser ? <Preloader /> : "Search"}
        </button>
      </div>
      <hr />
      {user === "Not found" ? (
        <section className="h-[30vh] mt-5 rounded-md bg-white flex justify-center items-center">
          <p className="text-secondary">User not found!</p>
        </section>
      ) : user.length > 0 ? (
        <>
          <div className="text-right rounded-md relative">
            <button
              type="button"
              className="bg-secondary p-2 text-white mt-3 fixed right-0"
              onClick={handleClose}
            >
              <MdClose className="w-5 h-5" />
            </button>
          </div>

          <section className="bg-white p-6 rounded-md my-3 w-2/3 mx-auto">
            {user[0].isComplete !== "completed" &&
              user[0].adminVerification !== "verified" && (
                <p className="text-center font-semibold mb-4 text-secondary">
                  This user's account is incomplete
                </p>
              )}
            {user[0].isComplete === "completed" &&
              user[0].adminVerification !== "verified" && (
                <p className="text-center font-semibold mb-4 text-secondary">
                  Awaiting approval in{" "}
                  {user[0].adminVerification === "stage1"
                    ? `Stage 1 ${user[0].employmentStatus}`
                    : "Document check"}
                </p>
              )}
            <div className="flex gap-10">
              <div className="w-40 relative h-32">
                <img
                  src={user[0].image || "/assets/Avatar.png"}
                  alt={
                    user[0].lastName
                      ? `${user[0].lastName} ${user[0].firstName}`
                      : "-- --"
                  }
                  className="w-full h-full"
                />
                <p className="text-xs absolute capitalize font-extrabold top-25 right-0 left-0 text-center p-1 text-secondary italic">
                  {user[0]?.accountType}
                </p>
              </div>
              <div className="w-full">
                <div className="text-sm">
                  <label className="font-medium" htmlFor="name">
                    Name:
                  </label>
                  <input
                    className="border p-2 my-1 w-full"
                    type="text"
                    name="name"
                    id="name"
                    value={
                      user[0].firstName
                        ? `${user[0].lastName} ${user[0].firstName}`
                        : "-- --"
                    }
                    disabled
                  />
                </div>
                <div className="text-sm">
                  <label className="font-medium" htmlFor="registered-date">
                    Registered at:
                  </label>
                  <input
                    className="border p-2 my-1 w-full"
                    type="text"
                    name="registered-date"
                    id="registered-date"
                    value={new Date(user[0].createdAt).toLocaleDateString()}
                    disabled
                  />
                </div>
                <div className="text-sm">
                  <label className="font-medium" htmlFor="phone-number">
                    Phone number:
                  </label>
                  <input
                    className="border p-2 my-1 w-full"
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    value={user[0].phoneNumber ? user[0].phoneNumber : "-- --"}
                    disabled
                  />
                </div>
                {user[0].accountType === "flexible" && (
                  <div className="text-sm">
                    <label className="font-medium" htmlFor="gender">
                      Gender:
                    </label>
                    <input
                      className="border p-2 my-1 w-full"
                      type="text"
                      name="gender"
                      id="gender"
                      value={user[0].gender ? user[0].gender : "-- --"}
                      disabled
                    />
                  </div>
                )}
                {user[0].accountType === "flexible" && (
                  <div className="text-sm">
                    <label className="font-medium" htmlFor="dateOfBirth">
                      Date of Birth:
                    </label>
                    <input
                      className="border p-2 my-1 w-full"
                      type="text"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      value={
                        user[0].dateOfBirth ? user[0].dateOfBirth : "-- --"
                      }
                      disabled
                    />
                  </div>
                )}
                <div className="text-sm">
                  <label className="font-medium" htmlFor="employmentStatus">
                    Employment Status:
                  </label>
                  <input
                    className="border p-2 m-1 w-full capitalize"
                    type="text"
                    name="employmentStatus"
                    id="employmentStatus"
                    value={
                      user[0].employmentStatus &&
                      user[0].employmentStatus !== "outRight"
                        ? user[0].employmentStatus
                        : "-- --"
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            {(user[0].isComplete === "completed" ||
              user[0].adminVerification === "verified") && (
              <div className="flex justify-evenly items-center my-3">
                <button
                  type="button"
                  className="bg-secondary text-white text-sm p-2 rounded-md w-32"
                  onClick={() => setBtnClicked("order-history")}
                >
                  Order History
                </button>
                {user[0].accountType === "flexible" && (
                  <button
                    type="button"
                    className="bg-secondary text-white text-sm p-2 rounded-md w-32"
                    onClick={() => setBtnClicked("payment-history")}
                  >
                    Payment History
                  </button>
                )}
                <button
                  type="button"
                  className="bg-secondary text-white text-sm p-2 rounded-md w-32"
                  onClick={() => setBtnClicked("personal-info")}
                >
                  Personal Info
                </button>
                {user[0].accountType === "flexible" && (
                  <button
                    type="button"
                    className="bg-secondary text-white text-sm p-2 rounded-md w-32"
                    onClick={() => setBtnClicked("loan-history")}
                  >
                    Loan History
                  </button>
                )}
              </div>
            )}
          </section>
          <section>
            {btnClicked === "order-history" ? (
              <OrderHistory userId={userId} token={token} />
            ) : btnClicked === "payment-history" ? (
              <PaymentHistory userId={userId} token={token} />
            ) : btnClicked === "personal-info" ? (
              <PersonalInfo user={user} />
            ) : btnClicked === "loan-history" ? (
              <LoanHistory userId={userId} token={token} />
            ) : null}
          </section>
        </>
      ) : null}
    </main>
  );
};
