import React from "react";

const BVNVerificationModal = ({ setBvnModalOpen, selectedBvn }: any) => {
  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">
            BANK VERIFICATION NUMBER DETAILS
          </h2>
          <button onClick={() => setBvnModalOpen(false)} className="text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className=" border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">First Name:</h4>{" "}
          <h5>{selectedBvn.firstName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Middle Name:</h4>{" "}
          <h5>{selectedBvn.middleName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold">Last Name:</h4>{" "}
          <h5>{selectedBvn.lastName}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold mr-2">Address:</h4>{" "}
          <h5 className="text-right">{selectedBvn.address}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold">Bvn</h4> <h5>{selectedBvn.bvn}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold">Date Of Birth</h4>{" "}
          <h5>{selectedBvn.dateOfBirth}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold">Gender</h4>{" "}
          <h5>{selectedBvn.gender}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold mr-2">Photo</h4>{" "}
          <h5>{selectedBvn.image}</h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between overflow-hidden">
          <h4 className="font-semibold">State Of Origin:</h4>{" "}
          <h5>{selectedBvn.stateOfOrigin}</h5>
        </div>
      </div>
    </div>
  );
};

export default BVNVerificationModal;
