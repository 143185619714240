const Employed = ({
  bankStatement,
  setEmployedModalOpen,
  selectedInfo,
}: any) => {
  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">Employment Information </h2>
          <button
            onClick={() => setEmployedModalOpen(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className=" border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">company Name:</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.companyName
              : selectedInfo.companyName || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">company Location:</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.companyLocation
              : selectedInfo.companyLocation || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">company Phone:</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.companyPhone
              : selectedInfo.companyPhone || "not found"}{" "}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">industryType</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.industryType
              : selectedInfo.industryType || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">position In Company</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.positionInCompany
              : selectedInfo.positionInCompany || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">salary amount</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.monthlySalary
              : selectedInfo.monthlySalary || "not found"}{" "}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">salary Account BankName</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.salaryAccountBankName
                ? selectedInfo.employed.salaryAccountBankName
                : selectedInfo.employed.salaryAccounts.length > 0
                ? selectedInfo.employed.salaryAccounts[0].salaryAccountBankName
                : "not found"
              : selectedInfo.salaryAccountBankName
              ? selectedInfo.salaryAccountBankName
              : selectedInfo.salaryAccounts.length > 0
              ? selectedInfo.salaryAccounts[0].salaryAccountBankName
              : "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">salary Account Number</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.salaryAccountNumber
                ? selectedInfo.employed.salaryAccountNumber
                : selectedInfo.employed.salaryAccounts.length > 0
                ? selectedInfo.employed.salaryAccounts[0].salaryAccountNumber
                : "not found"
              : selectedInfo.salaryAccountNumber
              ? selectedInfo.salaryAccountNumber
              : selectedInfo.salaryAccounts.length > 0
              ? selectedInfo.salaryAccounts[0].salaryAccountNumber
              : "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">salaryAccountName</h4>{" "}
          <h5>
            {selectedInfo.employed
              ? selectedInfo.employed.salaryAccountName
                ? selectedInfo.employed.salaryAccountName
                : selectedInfo.employed.salaryAccounts.length > 0
                ? selectedInfo.employed.salaryAccounts[0].salaryAccountName
                : "not found"
              : selectedInfo.salaryAccountName
              ? selectedInfo.salaryAccountName
              : selectedInfo.salaryAccounts.length > 0
              ? selectedInfo.salaryAccounts[0].salaryAccountName
              : "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">Front Work ID</h4>
          <a
            className="w-1/2 truncate"
            href={
              (selectedInfo.employed
                ? selectedInfo.employed.workIDCard[0]
                : selectedInfo.workIDCard[0]) || ""
            }
            target="blank"
          >
            {(selectedInfo.employed
              ? selectedInfo.employed.workIDCard[0]
              : selectedInfo.workIDCard[0]) || "not found"}
          </a>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">Back Work ID</h4>
          <a
            className="w-1/2 truncate"
            href={
              (selectedInfo.employed
                ? selectedInfo.employed.workIDCard[1]
                : selectedInfo.workIDCard[1]) || ""
            }
            target="blank"
          >
            {(selectedInfo.employed
              ? selectedInfo.employed.workIDCard[1]
              : selectedInfo.workIDCard[1]) || "not found"}
          </a>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">Bank Statement</h4>
          <a
            className="w-1/2 truncate"
            href={bankStatement || ""}
            target="blank"
          >
            {bankStatement || "not found"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Employed;
