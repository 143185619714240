const ViewAdminAssignment = ({ setViewAssignment, assignmentData }: any) => {
  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 overflow-y-auto">
      <section className="overflow-y-auto bg-white w-1/3 h-[90%]">
        <div className="bg-white p-4 rounded-md h-screen">
          <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">
            All Assignments of {assignmentData.name}
          </h2>
          <button
            onClick={() => setViewAssignment(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          {assignmentData.data.length > 0 ? (
            <table className="w-full">
              <thead className="border">
                <tr>
                  <th className="py-3">Role</th>
                  <th className="py-3">Date Assigned</th>
                </tr>
              </thead>
              <tbody>
                {assignmentData.data.map((data: any, index: number) => (
                  <tr key={index}>
                    <td className="p-2">{data.role}</td>
                    <td className="p-2">{new Date(data.assignDate).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center py-5">No assignments</p>
          )}
        </section>
      </div>
      </section>
    </div>
  );
};

export default ViewAdminAssignment;
