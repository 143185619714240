import { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllIncompleteUsers } from "../../redux/thunk";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import OutrightIncompleteUsers from "./OutrightIncompleteUsers";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";

const IncompleteUsers = () => {
  const { incomplete, status } = useAppSelector((store) => store.users);
  const [isLoading] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [userToDelete, setUserToDelete] = useState([]);
  const [activeTab, setActiveTab] = useState("flexible");
  const [accountType, setAccountType] = useState({
    flexible: [],
    outright: [],
  });
  const data =
    activeTab === "flexible" ? accountType.flexible : accountType.outright;

  const dispatch = useAppDispatch();

  const [searchedUser, setSearchedUser] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isActive, setIsActive] = useState("day");
  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    dispatch(getAllIncompleteUsers());
  }, []);

  useEffect(() => {
    const filterDataByDate = () => {
      setFilteredData(
        data.filter((data: any) => {
          const acctDate = new Date(data.createdAt).toLocaleDateString();
          const selectedDate = new Date(filterByDate).toLocaleDateString();
          return acctDate === selectedDate;
        })
      );
    };
    filterDataByDate();
  }, [
    accountType.flexible,
    accountType.outright,
    activeTab,
    data,
    filterByDate,
    incomplete,
  ]);

  useEffect(() => {
    const filterData = () => {
      setFilteredData(
        data.filter((data: any) => {
          const currentDate = new Date();
          const apiDate = new Date(data.createdAt);
          const timeDifference = currentDate.getTime() - apiDate.getTime();
          const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

          if (filter === "yesterday") {
            return daysDifference === 1;
          } else if (filter === "aWeek") {
            return daysDifference >= 2 && daysDifference <= 7;
          } else if (filter === "older") {
            return daysDifference > 7;
          } else {
            return true;
          }
        })
      );
    };
    filterData();
  }, [
    accountType.flexible,
    accountType.outright,
    activeTab,
    data,
    filter,
    incomplete,
  ]);

  useEffect(() => {
    const flexibleFilter = () => {
      const flexibleAccounts = incomplete
        .filter((account: any) => account.accountType === "flexible")
        .slice()
        .reverse();
      setAccountType((prev) => ({ ...prev, flexible: flexibleAccounts }));
    };

    const outrightFilter = () => {
      const outrightAccounts = incomplete
        .filter((account: any) => account.accountType === "outright")
        .slice()
        .reverse();
      setAccountType((prev) => ({ ...prev, outright: outrightAccounts }));
    };

    flexibleFilter();
    outrightFilter();
  }, [incomplete]);

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        data?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null; // Fallback to empty array if customers is undefined
      setSearchResults(searchResult);
    } else {
      setSearchResults([]); // Clear results when input is empty
    }
  };

  const handleDeleteClick = (data: any) => {
    setShowDeleteUser((prev) => !prev);
    setUserToDelete(data);
  };

  return (
    <div className="bg-white rounded-md shadow-md px-3 pb-6">
      <div className="flex gap-10 mb-3">
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "outright" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Customers
        </span>
      </div>

      <div className="flex justify-between items-center w-full p-3">
        <h1 className="text-base font-semibold p-3">Incomplete Accounts</h1>
        <div className="flex">
          <label htmlFor="filterByDate">
            Filter by date:
            <input
              type="date"
              name="filterByDate"
              id="filterByDate"
              className={`border p-2 mx-4 ${
                isActive === "date" && "bg-gray-200"
              }`}
              onClick={() => setIsActive("date")}
              onChange={(e) => setFilterByDate(e.target.value)}
            />
          </label>
          <label htmlFor="filter">
            Sort:
            <select
              name="filter"
              id="filter"
              className={`border p-2 mx-4 ${
                isActive === "day" && "bg-gray-200"
              }`}
              onClick={() => setIsActive("day")}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="yesterday">Yesterday</option>
              <option value="aWeek">A week ago</option>
              <option value="older">Older</option>
            </select>
          </label>
        </div>
      </div>
      <div className="relative w-11/12 mx-auto mb-3">
        <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
        <input
          type="search"
          name="searchedUser"
          id="searchedUser"
          value={searchedUser}
          onChange={(e) => searchForUsersWithEmail(e.target.value)}
          placeholder="Search user using email"
          className="border p-2 rounded-md indent-7 w-full"
          disabled={incomplete.length === 0}
        />
      </div>

      <section className="overflow-x-auto">
        <table className="w-[1200px]" style={{ minWidth: "700px" }}>
          <thead className="bg-gray-50 font-bold p-4 text-left text-nowrap">
            <tr>
              <th className="px-2 py-4 ">S/N</th>
              <th className="px-2 py-4 ">Name</th>
              <th className="px-2 py-4">Email</th>
              <th className="px-2 py-4">Phone Number</th>
              <th className="px-2 py-4">Contact</th>
              <th className="px-2 py-4">Address</th>
              <th className="px-2 py-4">Verification stage</th>
              <th className="px-2 py-4">Created on</th>
              <th className="px-2 py-4">Open Password</th>
              <th className="px-2 py-4">Force Delete User</th>
            </tr>
          </thead>
          {activeTab === "flexible" ? (
            <>
              <tbody className="px-4 text-nowrap">
                {status === "loading" ? (
                  <tr>
                    <td colSpan={11} className="text-center p-3">
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : searchedUser.length > 0 ? (
                  searchResults && searchResults.length > 0 ? (
                    searchResults.map((data: any, index) => (
                      <tr
                        className="border-b border-gray-300 py-2 hover:bg-gray-50"
                        key={index}
                      >
                        <td className="text-secondary p-2">
                          {index + indexOfFirstPost + 1}
                        </td>
                        <td className="p-2">
                          {data.firstName && data.lastName
                            ? `${data.lastName} ${data.firstName}`
                            : "Not available"}
                        </td>
                        <td className="p-2">{data.email}</td>
                        <td className="p-2">
                          {data.phoneNumber
                            ? data.phoneNumber
                            : "Not available"}
                        </td>
                        <td className="p-2 text-nowrap flex justify-evenly">
                          <button type="button" title="call customer">
                            <a href={`tel:${data?.phoneNumber}`}>
                              <FaPhone />
                            </a>
                          </button>
                          <button type="button" title="text via whatsapp">
                            <a
                              href={`https://wa.me/${data?.phoneNumber?.replace(
                                /^0/,
                                "234"
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaWhatsapp />
                            </a>
                          </button>
                        </td>

                        <td className="p-2 capitalize">
                          {data.address
                            ? data.address.toLowerCase()
                            : "Not available"}
                        </td>
                        <td className="p-2">{data.isComplete}</td>
                        <td className="p-2">
                          {new Date(data.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </td>
                        <td className="p-2">
                          {data.userPassword ? data.userPassword : "not found"}
                        </td>
                        <td className="p-2">
                          <button
                            type="button"
                            className={`${
                              isLoading ? "bg-red-100" : "bg-red-600"
                            } p-2 text-sm text-white rounded-md`}
                            onClick={() => handleDeleteClick(data)}
                            disabled={isLoading}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        className="text-center text-secondary p-3"
                      >
                        User with email not found
                      </td>
                    </tr>
                  )
                ) : currentItems.length > 0 ? (
                  currentItems.map((data: any, index: number) => {
                    return (
                      <tr
                        className="border-b border-gray-300 py-2 hover:bg-gray-50"
                        key={index}
                      >
                        <td className="text-secondary p-2">
                          {index + indexOfFirstPost + 1}
                        </td>
                        <td className="p-2">
                          {data.firstName && data.lastName
                            ? `${data.firstName} ${data.lastName}`
                            : "Not available"}
                        </td>
                        <td className="p-2">{data.email}</td>
                        <td className="p-2">
                          {data.phoneNumber
                            ? data.phoneNumber
                            : "Not available"}
                        </td>
                        <td className="p-2 text-nowrap flex justify-evenly">
                          <button type="button" title="call customer">
                            <a href={`tel:${data?.phoneNumber}`}>
                              <FaPhone />
                            </a>
                          </button>
                          <button type="button" title="text via whatsapp">
                            <a
                              href={`https://wa.me/${data?.phoneNumber?.replace(
                                /^0/,
                                "234"
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaWhatsapp />
                            </a>
                          </button>
                        </td>
                        <td className="p-2 capitalize">
                          {data.address
                            ? data.address.toLowerCase()
                            : "Not available"}
                        </td>
                        <td className="p-2">{data.isComplete}</td>
                        <td className="p-2">
                          {new Date(data.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </td>
                        <td className="p-2">
                          {data.userPassword ? data.userPassword : "not found"}
                        </td>
                        <td className="p-2">
                          <button
                            type="button"
                            className={`${
                              isLoading ? "bg-red-100" : "bg-red-600"
                            } p-2 text-sm text-white rounded-md`}
                            onClick={() => handleDeleteClick(data)}
                            disabled={isLoading}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center text-secondary p-5">
                      No user available
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          ) : (
            <OutrightIncompleteUsers
              isLoading={isLoading}
              searchedUser={searchedUser}
              searchResults={searchResults}
              handleDeleteClick={handleDeleteClick}
              indexOfFirstPost={indexOfFirstPost}
              currentItems={currentItems}
            />
          )}
        </table>
      </section>
      <section className="p-3 my-5">
        <Pagination
          length={
            searchResults.length > 0
              ? searchResults.length
              : activeTab === "flexible"
              ? accountType.flexible.length
              : accountType.outright.length
          }
          itemsPerPage={itemsPerPage}
          handlePagination={handlePagination}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </section>
    </div>
  );
};

export default IncompleteUsers;
