import axios from "axios";
import { useEffect, useState } from "react";
import PartnershipModal from "../../components/modals/PartnershipModal";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import AddTestimony from "../../components/modals/AddTestimony";
import Pagination from "../../components/Pagination";

const Index = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState("testimonies");
  const [allTestimonies, setAllTestimonies] = useState<any>([]);
  const [allSubscribers, setAllSubscribers] = useState<any>([]);
  const [allPartnerships, setAllPartnerships] = useState<any>([]);
  const [viewPartnershipModal, setViewPartnershipModal] = useState(false);
  const [partnershipDetails, setPartnershipDetails] = useState({});
  const [viewAddTestimony, setViewAddTestimony] = useState(false);

  // Pagination
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const items =
    activeSection === "subscribers" ? allSubscribers : allPartnerships;
  const currentItems = items.slice(indexOfFirstPost, indexOfLastPost);

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(items.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getTestimonies = async () => {
    setIsLoading((prev) => !prev);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/testimonies`
      );
      setAllTestimonies(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTestimonies();
  }, []);

  const getSubscribers = async () => {
    setIsLoading((prev) => !prev);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/subscribers`
      );
      setAllSubscribers(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPartnerships = async () => {
    setIsLoading((prev) => !prev);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/partnersWithUs`
      );
      setAllPartnerships(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <h1 className="font-bold text-xl">Web Visitors</h1>
      <section className="flex my-3 justify-evenly flex-wrap gap-3">
        <div
          onClick={() => {
            getTestimonies();
            setActiveSection("testimonies");
          }}
          className={`cursor-pointer bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-semibold pb-4 text-gray-700 caption-bottom">
            Testimonies
          </h2>
        </div>
        <div
          onClick={() => {
            getSubscribers();
            setActiveSection("subscribers");
          }}
          className={`cursor-pointer bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-semibold pb-4 text-gray-700 caption-bottom">
            Subscribers
          </h2>
        </div>
        <div
          onClick={() => {
            getPartnerships();
            setActiveSection("partnership");
          }}
          className={`cursor-pointer bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-semibold pb-4 text-gray-700 caption-bottom">
            Partnership Requests
          </h2>
        </div>
      </section>
      <section className="p-3">
        {activeSection === "testimonies" && (
          <>
            <button
              type="button"
              onClick={() => setViewAddTestimony((prev) => !prev)}
              className="text-sm text-white bg-secondary p-2 my-2 mb-4"
            >
              Add Testimony
            </button>
            <table className="w-full">
              <thead className="font-bold h-10 bg-gray-300">
                <tr>
                  <td className="px-2 py-3">SN</td>
                  <td className="px-2 py-3">Customer Name</td>
                  <td className="px-2 py-3">Review</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td className="text-center py-10" colSpan={3}>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : allTestimonies && allTestimonies.length > 0 ? (
                  allTestimonies
                    .slice()
                    .reverse()
                    .map((testimony: any, index: number) => (
                      <tr key={index} className="border-b text-sm">
                        <td className="px-2 py-3">{index + 1}</td>
                        <td className="px-2 py-3">{testimony.name}</td>
                        <td className="px-2 py-3">{testimony.content}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center py-4 text-secondary">
                      No testimonies
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
        {activeSection === "subscribers" && (
          <>
            <table className="w-full">
              <thead className="font-bold h-10 bg-gray-300">
                <tr>
                  <td className="px-2 py-3">SN</td>
                  <td className="px-2 py-3">User Email</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td className="text-center py-10" colSpan={2}>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : allSubscribers && allSubscribers.length > 0 ? (
                  allSubscribers
                    .slice()
                    .reverse()
                    .map((subscriber: any, index: number) => (
                      <tr key={index} className="border-b text-sm">
                        <td className="px-2 py-3">{index + 1}</td>
                        <td className="px-2 py-3">{subscriber.email}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={2} className="text-center py-4 text-secondary">
                      No Subscribers
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <section className="p-3 my-5 w-full">
              <Pagination
                length={currentItems.length}
                itemsPerPage={itemsPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </section>
          </>
        )}
        {activeSection === "partnership" && (
          <>
            <table className="w-full">
              <thead className="font-bold h-10 bg-gray-300">
                <tr>
                  <td className="px-2 py-3">SN</td>
                  <td className="px-2 py-3">User Email</td>
                  <td className="px-2 py-3">Details</td>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td className="text-center py-10" colSpan={3}>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : allPartnerships && allPartnerships.length > 0 ? (
                  allPartnerships
                    .slice()
                    .reverse()
                    .map((partnership: any, index: number) => (
                      <tr key={index} className="border-b text-sm">
                        <td className="px-2 py-3">{index + 1}</td>
                        <td className="px-2 py-3">{partnership.name}</td>
                        <td className="px-2 py-3">
                          <button
                            type="button"
                            onClick={() => {
                              setPartnershipDetails(partnership);
                              setViewPartnershipModal((prev) => !prev);
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center py-4 text-secondary">
                      No Partnership Requests
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <section className="p-3 my-5">
              <Pagination
                length={currentItems.length}
                itemsPerPage={itemsPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </section>
          </>
        )}
      </section>
      {viewAddTestimony && (
        <AddTestimony closeModal={() => setViewAddTestimony(false)} />
      )}
      {viewPartnershipModal && (
        <PartnershipModal
          closeModal={() => setViewPartnershipModal(false)}
          details={partnershipDetails}
        />
      )}
    </section>
  );
};

export default Index;
