import axios from "axios";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import LoadingSpinner from "../elements/LoadingSpinner";
import {
  format,
  formatDistanceToNow,
  isSameDay,
  isToday,
  parseISO,
  subDays,
} from "date-fns";
import { TbRefresh } from "react-icons/tb";

const ActivityLog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activityLog, setActivityLog] = useState([]);
  const [filter, setFilter] = useState("all");
  const { token } = useAppSelector((store) => store.auth);

  const getActivityLog = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/getAllEvents`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setActivityLog(res.data);
    } catch (error) {
      console.error("Failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActivityLog();
  }, []);

  const filteredData = activityLog.filter((activity: any) => {
    const activityDate = parseISO(activity.createdAt);

    if (filter === "all") return true; // Show all
    if (filter === "today") return isToday(activityDate);
    if (filter === "yesterday")
      return isSameDay(activityDate, subDays(new Date(), 1));
    if (filter === "2 days ago")
      return isSameDay(activityDate, subDays(new Date(), 2));
    if (filter === "3 days ago")
      return isSameDay(activityDate, subDays(new Date(), 3));
    if (filter === "4 days ago")
      return isSameDay(activityDate, subDays(new Date(), 4));
    if (filter === "5 days ago")
      return isSameDay(activityDate, subDays(new Date(), 5));
    if (filter === "6 days ago")
      return isSameDay(activityDate, subDays(new Date(), 6));
    if (filter === "a month ago") return activityDate < subDays(new Date(), 30);
    return false; // hide items if no match
  });

  return (
    <section className="p-3 shadow-lg mt-20">
      <div className="flex items-center justify-between border-b p-3">
        <h3 className="text-gray-400 font-semibold">Activity Log</h3>
        <button type="button" onClick={() => getActivityLog()}>
          <TbRefresh className="w-6 h-6" />
        </button>
      </div>
      <div className="flex gap-2 overflow-x-auto w-full p-2">
        {[
          "all",
          "today",
          "yesterday",
          "2 days ago",
          "3 days ago",
          "4 days ago",
          "5 days ago",
          "6 days ago",
          "a month ago",
        ].map((label) => (
          <button
            key={label}
            type="button"
            onClick={() => setFilter(label)}
            className={`text-xs border rounded-full px-2 py-1 w-fit text-nowrap ${
              filter === label ? "bg-gray-200" : ""
            }`}
          >
            {label}
          </button>
        ))}
      </div>
      <section className="min-h-[15rem] max-h-[38rem] overflow-y-auto pt-2">
        {isLoading ? (
          <span className="w-fit mx-auto">
            <LoadingSpinner />
          </span>
        ) : filteredData && filteredData.length > 0 ? (
          filteredData
            .slice()
            .reverse()
            .map((activity: any, index: number) => (
              <section key={index} className="my-3">
                <h4 className="font-semibold text-xs leading-7 capitalize mb-1">
                  {activity.message.toLowerCase()}
                </h4>
                <div className="flex justify-between gap-1">
                  <p className="text-xs text-gray-400">
                    {format(parseISO(activity.createdAt), "d-M-yyyy")}
                  </p>
                  <p className="text-xs text-gray-400">
                    {formatDistanceToNow(parseISO(activity.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
              </section>
            ))
        ) : (
          <p className="text-sm text-center">Nothing to see here</p>
        )}
      </section>
    </section>
  );
};

export default ActivityLog;
