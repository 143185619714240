import { formatNumber } from "../../redux/thunk";

export const PersonalInfo = ({ user }: any) => {
  return (
    <section className="bg-white p-5">
      <section className="flex gap-5 mb-5 ">
        <div className="w-1/2">
          <h3 className="font-semibold mb-3 underline">Employment Details</h3>
          {user[0].employmentStatus === "student" ? (
            <>
              <div className="text-sm">
                <label className="font-medium" htmlFor="schoolName">
                  Name of School:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="schoolName"
                  id="schoolName"
                  value={user[0].student.schoolName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="facultyName">
                  Faculty:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="facultyName"
                  id="facultyName"
                  value={user[0].student.facultyName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="departmentName">
                  Department:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="departmentName"
                  id="departmentName"
                  value={user[0].student.departmentName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="level">
                  Level:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="level"
                  id="level"
                  value={user[0].student.level}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="matricNumber">
                  Matric Number:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="matricNumber"
                  id="matricNumber"
                  value={user[0].student.matricNumber}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="accountBankName">
                  Bank Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="accountBankName"
                  id="accountBankName"
                  value={user[0].student.accounts[0].accountBankName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="accountName">
                  Account Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="accountName"
                  id="accountName"
                  value={user[0].student.accounts[0].accountName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="accountNumber">
                  Account Number:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="accountNumber"
                  id="accountNumber"
                  value={user[0].student.accounts[0].accountNumber}
                  disabled
                />
              </div>
            </>
          ) : user[0].employmentStatus === "selfEmployed" ? (
            <>
              <div className="text-sm">
                <label className="font-medium" htmlFor="businessName">
                  Business Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="businessName"
                  id="businessName"
                  value={user[0].selfEmployed.businessName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="industryType">
                  Industry Type:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="industryType"
                  id="industryType"
                  value={user[0].selfEmployed.industryType}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="location">
                  Business Address:
                </label>
                <textarea
                  name="location"
                  id="location"
                  className="border p-2 my-1 w-full"
                  value={user[0].selfEmployed.location}
                  disabled
                ></textarea>
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="numberOfEmployees">
                  Number of Employees:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="numberOfEmployees"
                  id="numberOfEmployees"
                  value={user[0].selfEmployed.numberOfEmployees}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label
                  className="font-medium"
                  htmlFor="businessAccountBankName"
                >
                  Bank Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="businessAccountBankName"
                  id="businessAccountBankName"
                  value={user[0].selfEmployed.businessAccounts[0].businessAccountBankName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="businessAccountName">
                  Account Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="businessAccountName"
                  id="businessAccountName"
                  value={user[0].selfEmployed.businessAccounts[0].businessAccountName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="businessAccountNumber">
                  Account Number:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="businessAccountNumber"
                  id="businessAccountNumber"
                  value={user[0].selfEmployed.businessAccounts[0].businessAccountNumber}
                  disabled
                />
              </div>
            </>
          ) : (
            <>
              <div className="text-sm">
                <label className="font-medium" htmlFor="companyName">
                  Company Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={user[0].employed.companyName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="industryType">
                  Industry Type:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="industryType"
                  id="industryType"
                  value={user[0].employed.industryType}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="positionInCompany">
                  Position in Company:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="positionInCompany"
                  id="positionInCompany"
                  value={user[0].employed.positionInCompany}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="companyLocation">
                  Company Location:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="companyLocation"
                  id="companyLocation"
                  value={user[0].employed.companyLocation}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="companyPhone">
                  Company Phone Number:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="companyPhone"
                  id="companyPhone"
                  value={user[0].employed.companyPhone}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="salaryPayDay">
                  Salary Pay Day:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="salaryPayDay"
                  id="salaryPayDay"
                  value={user[0].employed.salaryPayDay}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="salaryAccountBankName">
                  Bank Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="salaryAccountBankName"
                  id="salaryAccountBankName"
                  value={user[0].employed.salaryAccounts[0].salaryAccountBankName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="salaryAccountName">
                  Account Name:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="salaryAccountName"
                  id="salaryAccountName"
                  value={user[0].employed.salaryAccounts[0].salaryAccountName}
                  disabled
                />
              </div>
              <div className="text-sm">
                <label className="font-medium" htmlFor="salaryAccountNumber">
                  Account Number:
                </label>
                <input
                  className="border p-2 my-1 w-full"
                  type="text"
                  name="salaryAccountNumber"
                  id="salaryAccountNumber"
                  value={user[0].employed.salaryAccounts[0].salaryAccountNumber}
                  disabled
                />
              </div>
            </>
          )}
        </div>
        <div className="w-1/2">
          <h3 className="font-semibold mb-3 underline">Personal Information</h3>
          <div className="text-sm">
            <label className="font-medium" htmlFor="creditScore">
              Credit Score:
            </label>
            <input
              className="border p-2 my-1 w-full"
              type="text"
              name="creditScore"
              id="creditScore"
              value={user[0].creditScore}
              disabled
            />
          </div>
          <div className="text-sm">
            <label className="font-medium" htmlFor="bvn">
              BVN:
            </label>
            <input
              className="border p-2 my-1 w-full"
              type="text"
              name="bvn"
              id="bvn"
              value={user[0].bvn}
              disabled
            />
          </div>
          <div className="text-sm">
            <label className="font-medium" htmlFor="address">
              Address:
            </label>
            <textarea
              name="address"
              id="address"
              className="border p-2 my-1 w-full"
              value={user[0].address}
              disabled
            ></textarea>
          </div>
          <div className="text-sm">
            <label className="font-medium" htmlFor="deliveryAddress">
              Delivery Address:
            </label>
            <textarea
              name="deliveryAddress"
              id="deliveryAddress"
              className="border p-2 my-1 w-full"
              value={user[0].deliveryAddress}
              disabled
            ></textarea>
          </div>
        </div>
      </section>
      {user[0].accountType === "flexible" && (
        <section className="flex gap-5">
          <div className="w-1/2">
            <h3 className="font-semibold mb-2 underline">Mono Account</h3>
            <div className="text-sm">
              <label className="font-medium" htmlFor="idNo">
                Identification Number:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="idNo"
                id="idNo"
                value={user[0].monoAccount.identification_no}
                disabled
              />
            </div>
            <div className="text-sm">
              <label className="font-medium" htmlFor="createdAt">
                Created At:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="createdAt"
                id="createdAt"
                value={new Date(
                  user[0].monoAccount.createdAt
                ).toLocaleDateString()}
                disabled
              />
            </div>
          </div>
          <div className="w-1/2">
            <h3 className="font-semibold mb-2 underline">Virtual Account</h3>
            <div className="text-sm">
              <label className="font-medium" htmlFor="accountName">
                Account Name:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="accountName"
                id="accountName"
                value={user[0].virtualAccount.accountName}
                disabled
              />
            </div>
            <div className="text-sm">
              <label className="font-medium" htmlFor="bankName">
                Bank Name:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="bankName"
                id="bankName"
                value={user[0].virtualAccount.bankName}
                disabled
              />
            </div>
            <div className="text-sm">
              <label className="font-medium" htmlFor="virtualAccountNumber">
                Virtual Account Number:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="virtualAccountNumber"
                id="virtualAccountNumber"
                value={user[0].virtualAccount.virtualAccountNumber}
                disabled
              />
            </div>
            <div className="text-sm">
              <label className="font-medium" htmlFor="walletBalance">
                Wallet Balance:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="walletBalance"
                id="walletBalance"
                value={formatNumber(user[0].virtualAccount.walletbalance)}
                disabled
              />
            </div>
            <div className="text-sm">
              <label className="font-medium" htmlFor="customerIdentifier">
                Customer Identifier:
              </label>
              <input
                className="border p-2 my-1 w-full"
                type="text"
                name="customerIdentifier"
                id="customerIdentifier"
                value={user[0].virtualAccount.customerIdentifier}
                disabled
              />
            </div>
          </div>
        </section>
      )}
    </section>
  );
};
