import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { Preloader } from "../elements/Preloader";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

export const CloseDisbute = ({ setShowCloseDisbute, id, fetchDisbutes }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const handleCloseDispute = async () => {
    setIsLoading((prev) => !prev);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/closeDispute/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Dispute closed successfully");
      fetchDisbutes();
      setShowCloseDisbute(false)
    } catch (error) {
      toast.error("Failed to close dispute")
      setShowCloseDisbute(false)
    } finally {
      setIsLoading((prev) => !prev);
    }
  };
  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center flex-col text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <button
              onClick={() => setShowCloseDisbute(false)}
              className="text-dark absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div>
            <h3 className="text-xl m-3 font-semibold text-center">
              Close this disbute?
            </h3>
            <button
              type="button"
              className={`w-28 px-5 mr-3 py-3 rounded text-white bg-[#008b50] ${
                isLoading && "pb-[1.1rem]"
              }`}
              onClick={handleCloseDispute}
            >
              {isLoading ? <Preloader /> : "Yes"}
            </button>
            <button
              type="button"
              className="w-28 px-5 py-3 bg-red-500 rounded text-white "
              onClick={() => setShowCloseDisbute((prev: any) => !prev)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
