import { MdOutlineCancel } from "react-icons/md";
import Modal, { ModalContent } from "../elements/Modal";
import { useState } from "react";
import axios from "axios";
import { useAppSelector } from "../../redux/hooks";
import toast from "react-hot-toast";

const AddTestimony = ({ closeModal }: any) => {
  const { token } = useAppSelector((store) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [inputValue, setInputValue] = useState({
    name: "",
    review: "",
  });

  const formValidation = () => {
    let hasErrors = false;
    const newErrors: any = {};

    if (!inputValue.name && inputValue.name.trim().length === 0) {
      newErrors.name = "Please enter customer's name";
      hasErrors = true;
    }
    if (!inputValue.review && inputValue.name.trim().length === 0) {
      newErrors.review = "Please enter customer's review";
      hasErrors = true;
    }
    setErrors(newErrors);
    return hasErrors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formValidation()) {
      return;
    }

    setIsLoading((prev) => !prev);
    const payload = {
      name: inputValue.name,
      content: inputValue.review,
      isPublished: true,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/testimonies`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message);
    } catch (error: any) {
      console.error(error);
      toast.error(error.data.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <section className="w-full p-2">
          <div className="flex justify-between">
            <h3 className="font-bold text-lg">Add Testimony</h3>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <form>
            <div className="my-2">
              <label className="text-sm" htmlFor="name">
                Customer name
              </label>
              <input
                type="text"
                className="text-sm border w-full p-2 my-2"
                name="name"
                id="name"
                value={inputValue.name}
                onChange={(e) =>
                  setInputValue((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              {errors.name && (
                <p className="text-red-400 text-xs">{errors.name}</p>
              )}
            </div>
            <div>
              <label className="text-sm" htmlFor="review">
                Customer review
              </label>
              <input
                type="text"
                className="text-sm border w-full p-2 my-2"
                name="review"
                id="review"
                value={inputValue.review}
                onChange={(e) =>
                  setInputValue((prev) => ({ ...prev, review: e.target.value }))
                }
              />
              {errors.review && (
                <p className="text-red-400 text-xs">{errors.review}</p>
              )}
            </div>
            <div className="flex justify-center">
              <input
                type="submit"
                value={isLoading ? "..." : "Create"}
                className="text-sm w-20 bg-secondary cursor-pointer text-white p-2"
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          </form>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default AddTestimony;
