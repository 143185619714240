import axios from "axios";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import toast from "react-hot-toast";
import { Preloader } from "../components/elements/Preloader";
import {
  getAllIncompleteUsers,
  getFlexibleUsers,
  getOutrightUsers,
} from "../redux/thunk";

const PushNotication = () => {
  const { token } = useAppSelector((store) => store.auth);
  const [pushMessage, setPushMessage] = useState("");
  const [broadcastTitle, setBroadcastTitle] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [customerId, setCustomerId] = useState("");

  const validateSingleForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (!customerEmail) {
      newErrors.email = "Please enter customer email";
      isValid = false;
    }
    if (!messageTitle) {
      newErrors.messageTitle = "Please enter message title";
      isValid = false;
    }
    if (!messageBody) {
      newErrors.messageBody = "Please enter the message";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateBroadcastForm = () => {
    let isValid = true;
    const newErrors: any = {};

    if (!customerEmail) {
      newErrors.email = "Please enter customer email";
      isValid = false;
    }
    if (!broadcastTitle) {
      newErrors.broadcastTitle = "Please enter message title";
      isValid = false;
    }
    if (!pushMessage) {
      newErrors.pushMessage = "Please enter the message";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const { flexible, outright, incomplete } = useAppSelector(
    (state) => state.users
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getFlexibleUsers());
    dispatch(getOutrightUsers());
    dispatch(getAllIncompleteUsers());
  }, [dispatch]);

  const findCustomerWithEmail = () => {
    try {
      const allUsers = [...flexible, ...outright, ...incomplete];
      const findUser: any = allUsers.filter(
        (user: any) => customerEmail.trim() === user.email
      );
      if (findUser) {
        setCustomerId(findUser[0]._id);
      } else {
        setCustomerId("not found");
      }
    } catch (error) {
      console.error(error);
      setCustomerId("not found");
    }
  };

  const sendSingleNotification = async () => {
    await findCustomerWithEmail();
    if (customerId !== "not found" && customerId.length > 0) {
      const payload = {
        userId: customerId,
        title: messageTitle,
        body: messageBody,
      };

      if (!validateSingleForm()) {
        toast.error("Please complete the form to send message");
        return;
      }

      setIsLoading((prev) => !prev);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/sendNotification
        `,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        toast.success(res.data.message || "Sent successfully");
        setCustomerEmail("");
        setMessageTitle("");
        setMessageBody("");
        setCustomerId("");
      } catch (error) {
        console.error("Failed to send notification:", error);
        toast.error("Failed to send notification");
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("Customer not found");
    }
  };
  
  const sendPushNotification = async () => {
    const payload = {
      title: broadcastTitle,
      body: pushMessage,
    };

    if (!validateBroadcastForm()) {
      toast.error("Please complete the form to send message");
      return;
    }

    setIsLoading((prev) => !prev);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendMultipleNotification
        `,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success(res.data.message || "Sent successfully");
      setBroadcastTitle("");
      setPushMessage("");
    } catch (error) {
      console.error("Failed to send notification:", error);
      toast.error("Failed to send notification");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <section>
        <h1 className="font-bold text-xl">Push Notification</h1>
      </section>
      <section className="p-5 m-3 rounded-md px-10 border">
        <h3 className="font-semibold text-lg mb-4">To One Customer</h3>
        <div>
          <label htmlFor="email" className="font-semibold">
            Customer Email Address:
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Abaya@lagos.com"
            className="border w-4/5 block my-3 text-sm p-2 rounded-md"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
          />
        </div>
        {errors.email && (
          <p className="text-red-500 text-xs mb-2">{errors.email}</p>
        )}
        <div>
          <label htmlFor="title" className="font-semibold">
            Enter title of message:
          </label>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Hi ..."
            className="border w-4/5 block my-3 text-sm p-2 rounded-md"
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
          />
        </div>
        {errors.messageTitle && (
          <p className="text-red-500 text-xs mb-2">{errors.messageTitle}</p>
        )}
        <div>
          <label htmlFor="message" className="font-semibold">
            Add message body:
          </label>
          <textarea
            name="message"
            id="message"
            rows={10}
            value={messageBody}
            onChange={(e) => setMessageBody(e.target.value)}
            className="border w-4/5 block my-3 text-sm p-2 rounded-md"
            placeholder="Dear friends ..."
          ></textarea>
        </div>
        {errors.messageBody && (
          <p className="text-red-500 text-xs mb-2">{errors.messageBody}</p>
        )}
        <button
          type="button"
          onClick={sendSingleNotification}
          className="bg-secondary text-sm p-3 text-white rounded-md"
        >
          {isLoading ? <Preloader /> : "Send"}
        </button>
      </section>
      <section className="p-5 m-3 rounded-md px-10 border">
        <h3 className="font-semibold text-lg mb-4">To Every Customer</h3>
        <div>
          <label htmlFor="title" className="font-semibold">
            Enter title of message:
          </label>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Hi ..."
            className="border w-4/5 block my-3 text-sm p-2 rounded-md"
            value={broadcastTitle}
            onChange={(e) => setBroadcastTitle(e.target.value)}
          />
        </div>
        {errors.broadcastTitle && (
          <p className="text-red-500 text-xs mb-2">{errors.broadcastTitle}</p>
        )}
        <div>
          <label htmlFor="message" className="font-semibold">
            Add broadcast message:
          </label>
          <textarea
            name="message"
            id="message"
            rows={10}
            value={pushMessage}
            onChange={(e) => setPushMessage(e.target.value)}
            className="border w-4/5 block my-3 text-sm p-2 rounded-md"
            placeholder="Dear friends ..."
          ></textarea>
        </div>
        {errors.pushMessage && (
          <p className="text-red-500 text-xs mb-2">{errors.pushMessage}</p>
        )}
        <button
          type="button"
          onClick={sendPushNotification}
          className="bg-secondary text-sm p-3 text-white rounded-md"
        >
          {isLoading ? <Preloader /> : "Send to All"}
        </button>
      </section>
    </main>
  );
};

export default PushNotication;
