import { MdOutlineCancel } from "react-icons/md";
import Modal, { ModalContent } from "../elements/Modal";
import { formatNumber, getFlexibleUsers } from "../../redux/thunk";
import { useState } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import toast from "react-hot-toast";
import { RootState } from "../../redux/store";

const UpdateCreditLimit = ({ userData, closeModal }: any) => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [creditLimit, setCreditLimit] = useState("");
  const dispatch = useAppDispatch();

  const handleUpdate = async (e: any) => {
    setIsLoading(true);
    const payload = {
      userId: userData._id,
      creditLimit:
        e.target.innerText.toLowerCase() === "add value"
          ? Number(creditLimit)
          : -Number(creditLimit),
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/update-credit-limit`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch(getFlexibleUsers());
      toast.success(res.data.message || "Credit limit updated!");
      closeModal();
    } catch (error: any) {
      console.error("An error occured: ", error);
      toast.error(error.message || "Failed to update credit limit");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <section className="w-full p-2">
          <div className="flex justify-between">
            <h3 className="font-bold text-lg">Update Credit Limit</h3>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <section className="my-2 shadow-md p-3 capitalize leading-10">
            <p>
              Customer's Name:{" "}
              <span className="font-semibold">{`${userData.lastName.toLowerCase()} ${userData.firstName.toLowerCase()}`}</span>
            </p>
            <p>
              Current Credit limit:{" "}
              <span className="font-semibold">
                N{formatNumber(userData.creditLimit)}
              </span>
            </p>
          </section>
          <section className="mt-5">
            <input
              type="text"
              name="creditLimit"
              id="creditLimit"
              placeholder="Enter value"
              className="p-2 border w-full text-sm"
              value={creditLimit}
              onChange={(e) => setCreditLimit(e.target.value)}
            />
            <div className="flex gap-4 items-center justify-center">
              <button
                type="button"
                className="p-2 my-3 bg-secondary text-sm text-white w-32"
                onClick={(e) => handleUpdate(e)}
              >
                {isLoading ? "..." : "Add value"}
              </button>
              <button
                type="button"
                className="p-2 my-3 bg-secondary text-sm text-white w-32"
                onClick={(e) => handleUpdate(e)}
              >
                {isLoading ? "..." : "Subtract value"}
              </button>
            </div>
          </section>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default UpdateCreditLimit;
