import { useState } from "react";
import Pagination from "../Pagination";
import { IoSearchOutline } from "react-icons/io5";

const OutrightCustomers = ({
  outright,
  status,
  isLoadingDelete,
  handleDeleteClick,
}: any) => {
  const [searchedUser, setSearchedUser] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = outright.slice(indexOfFirstPost, indexOfLastPost);

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(outright.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        outright?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div className="bg-white rounded-md shadow-md pb-6">
      <div
        className={`w-full overflow-x-auto ${
          status === "loading" && "animate-pulse h-[50vh]"
        }`}
      >
        <div
          className="flex items-center justify-between p-6"
          style={{ minWidth: "700px" }}
        >
          <div className="flex justify-between w-full">
            <h1 className="text-base font-semibold ">Outright Customers</h1>
            <div className="relative md:w-[30rem] w-fit">
              <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
              <input
                type="search"
                name="searchedUser"
                id="searchedUser"
                value={searchedUser}
                onChange={(e) => searchForUsersWithEmail(e.target.value)}
                placeholder="Search user using email"
                className="border p-2 text-sm rounded-md indent-7 w-full"
                disabled={outright.length === 0}
              />
            </div>
          </div>
        </div>
        <section className="overflow-x-auto">
          <table className="w-[1020px]" style={{ minWidth: "700px" }}>
            <thead className="bg-gray-50 font-bold p-4 text-left">
              <tr>
                <th className="p-2 ">S/N</th>
                <th className="p-2">Name</th>
                <th className="p-2">Email</th>
                <th className="p-2">Address</th>
                <th className="p-2 text-nowrap">Verification stage</th>
                <th className="p-2 text-nowrap">Open Password</th>
                <th className="p-2 text-nowrap">Force Delete User</th>
              </tr>
            </thead>
            <tbody className="px-4">
              {searchedUser.length > 0 ? (
                searchResults && searchResults.length > 0 ? (
                  searchResults.map((data: any, index) => (
                    <tr className="border-b border-gray-300 py-2" key={index}>
                      <td className="text-secondary p-2">
                        {index + indexOfFirstPost + 1}
                      </td>
                      <td className="p-2 text-nowrap">
                        {data.firstName ? data.lastName : null}{" "}
                        {data.lastName ? data.firstName : null}
                      </td>
                      <td className="p-2 text-nowrap">{data.email}</td>
                      <td className="p-2 text-nowrap">
                        {data.deliveryAddress ? data.deliveryAddress : null}
                      </td>
                      <td className="p-2 text-nowrap">{data.isComplete}</td>
                      <td className="p-2">
                        {data.userPassword ? data.userPassword : "not found"}
                      </td>
                      <td className="p-2 text-center">
                        <button
                          type="button"
                          className={`${
                            isLoadingDelete ? "bg-red-100" : "bg-red-600"
                          } p-2 text-sm text-white rounded-md`}
                          onClick={() => handleDeleteClick(data)}
                          disabled={isLoadingDelete}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center text-secondary p-3">
                      User not found
                    </td>
                  </tr>
                )
              ) : (
                currentItems.map((data: any, index: number) => {
                  return (
                    <tr className="border-b border-gray-300 py-2" key={index}>
                      <td className="text-secondary p-2">
                        {index + indexOfFirstPost + 1}
                      </td>
                      <td className="p-2 text-nowrap">
                        {data.firstName ? data.lastName : null}{" "}
                        {data.lastName ? data.firstName : null}
                      </td>
                      <td className="p-2 text-nowrap">{data.email}</td>
                      <td className="p-2 text-nowrap">
                        {data.deliveryAddress ? data.deliveryAddress : null}
                      </td>
                      <td className="p-2 text-nowrap">{data.isComplete}</td>
                      <td className="p-2">
                        {data.userPassword ? data.userPassword : "not found"}
                      </td>
                      <td className="p-2 text-center">
                        <button
                          type="button"
                          className={`${
                            isLoadingDelete ? "bg-red-100" : "bg-red-600"
                          } p-2 text-sm text-white rounded-md`}
                          onClick={() => handleDeleteClick(data)}
                          disabled={isLoadingDelete}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </section>
      </div>
      <section className="p-3 my-5">
        <Pagination
          length={
            searchResults.length > 0 ? searchResults.length : outright.length
          }
          itemsPerPage={itemsPerPage}
          handlePagination={handlePagination}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </section>
    </div>
  );
};

export default OutrightCustomers;
