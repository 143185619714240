import { MdOutlineCancel } from "react-icons/md";
import Modal, { ModalContent } from "../elements/Modal";

const ViewCard = ({ closeModal, cardDetails }: any) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[55%] lg:w-[50%] xl:w-[30%] ">
        <section className="w-full min-h-[10rem] max-h-[20rem] overflow-y-scroll p-2">
          <div className="flex justify-between">
            <h3 className="font-bold text-lg">Card Information</h3>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <section className="mt-3">
            <p className="font-semibold">
              No. of Cards:{" "}
              {Array.isArray(cardDetails) ? cardDetails.length : 0}
            </p>
            {Array.isArray(cardDetails) && cardDetails.length > 0 ? (
              cardDetails.map((card: any, index: number) => (
                <div
                  key={index}
                  className="border rounded-md shadow-md my-2 p-2"
                >
                  <div className="flex justify-between items-center gap-5 py-2">
                    <p>Customer Email:</p>
                    <p>{card.customerEmail}</p>
                  </div>
                  <div className="flex justify-between items-center gap-5 py-2">
                    <p>Card Holder Name:</p>
                    <p>{card.cardholderName}</p>
                  </div>
                  <div className="flex justify-between items-center gap-5 py-2">
                    <p>Expiry date:</p>
                    <p>
                      {new Date(card.expiryDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-secondary text-center py-3">
                {cardDetails}
              </p>
            )}
          </section>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default ViewCard;
