import axios from "axios";
import toast from "react-hot-toast";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import { Preloader } from "../elements/Preloader";
import { BiChevronLeft } from "react-icons/bi";

const PackageModal = ({
  setViewPackage,
  id,
  selectedPackage,
  refreshPage,
}: any) => {
  const { token } = useAppSelector((store) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [image, setImage] = useState<any>(null);

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.name === "webImage") {
        setImage((prev: any) => ({ ...prev, web: e.target.files[0] }));
      } else {
        setImage((prev: any) => ({ ...prev, mobile: e.target.files[0] }));
      }
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    let hasErrors: boolean = false;

    if (!image || !image.hasOwnProperty("web")) {
      newErrors.webImage = "please upload a photo for the web app";
      hasErrors = true;
    }
    if (!image || !image.hasOwnProperty("mobile")) {
      newErrors.mobileImage = "please upload a photo for the mobile app";
      hasErrors = true;
    }
    setErrors(newErrors);
    return hasErrors;
  };

  const handleEditImage = async () => {
    if (validateForm()) {
      return;
    }

    const payload = new FormData();
    payload.append("image", image.web);
    payload.append("image2", image.mobile);

    setIsLoading((prev) => !prev);

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/packages/${id}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success(res.data.message || "Package updated successfully");
    } catch (error: any) {
      console.error(error);
      toast.error(error.response.data.message || "Failed to update");
    } finally {
      setIsLoading(false);
      setViewPackage(false);
      refreshPage();
    }
  };

  const getAccuratePackageData = Array.isArray(selectedPackage)
    ? selectedPackage[0]
    : selectedPackage; // solve endpoint issue that returns an array first before an object

  const deletePackage = async () => {
    setIsLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/packages/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success(res.data.message);
    } catch (error: any) {
      console.error(error);
      toast.error(error.message || "Deletion failed!");
    } finally {
      setIsLoading(false);
      setViewPackage(false);
      refreshPage();
    }
  };

  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between  flex-col text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <button
              onClick={() => setViewPackage(false)}
              className="text-dark absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section>
            <h3 className="font-semibold text-xl text-center capitalize">
              {getAccuratePackageData.name}
            </h3>

            {showEdit ? (
              <section>
                <button type="button" onClick={() => setShowEdit(false)}>
                  <BiChevronLeft className="w-6 h-6" />
                </button>
                <section>
                  <label className="font-semibold" htmlFor="webImage">
                    New Web Image:
                  </label>
                  <input
                    type="file"
                    name="webImage"
                    className="w-full text-sm border p-2 my-1"
                    id="webImage"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                  />
                  {errors.mobileImage && (
                    <span className="block -mt-2 text-red-500 text-sm">
                      {errors.mobileImage}
                    </span>
                  )}

                  <label className="font-semibold" htmlFor="mobileImage">
                    New Mobile Image:
                  </label>
                  <input
                    type="file"
                    name="mobileImage"
                    className="w-full text-sm border p-2 my-1"
                    id="mobileImage"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                  />
                  {errors.mobileImage && (
                    <span className="block -mt-2 text-red-500 text-sm">
                      {errors.mobileImage}
                    </span>
                  )}
                </section>
                <button
                  type="button"
                  className="text-white bg-secondary text-sm w-40 p-2 block mt-2 mx-auto"
                  onClick={handleEditImage}
                >
                  {isLoading ? <Preloader /> : "Save"}
                </button>
              </section>
            ) : (
              <>
                <figure className="w-36 mx-auto my-2">
                  <img
                    className="w-full"
                    src={
                      getAccuratePackageData.image &&
                      getAccuratePackageData.image
                    }
                    alt={getAccuratePackageData.name}
                  />
                </figure>
                <section>
                  <p className="font-semibold mb-2 text-sm">Products:</p>
                  <table className="w-full text-left mb-3">
                    <thead className="border bg-gray-50 text-sm">
                      <tr>
                        <th>Name</th>
                        <th>Measurement</th>
                        <th>Type</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAccuratePackageData.productIds?.map(
                        (product: any, index: number) => (
                          <tr key={index}>
                            <td className="p-2 border text-xs">
                              {product.name}
                            </td>
                            <td className="p-2 border text-xs">
                              {product.measurement}
                            </td>
                            <td className="p-2 border text-xs">
                              {product.type}
                            </td>
                            <td className="p-2 border text-xs">
                              {product.price}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <p className="text-sm">
                    <span className="font-semibold my-2">Package Price</span>: ₦
                    {getAccuratePackageData.amount}
                  </p>
                  <p className="text-sm">
                    <span className="font-semibold my-2">Description:</span>{" "}
                    {getAccuratePackageData.description}
                  </p>
                  <p className="text-sm">
                    <span className="font-semibold my-2">Created At: </span>
                    {new Date(
                      getAccuratePackageData.createdAt
                    ).toLocaleDateString()}
                  </p>
                </section>
                <div className="flex items-center justify-center mt-3">
                  <button
                    type="button"
                    className=" px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                    onClick={deletePackage}
                  >
                    {isLoading ? <Preloader /> : "Delete this package"}
                  </button>
                  <button
                    type="button"
                    className=" px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                    onClick={() => setShowEdit(true)}
                  >
                    Edit Image
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
