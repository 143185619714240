import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineGraph = () => {
  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  const data = {
    labels: [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "june",
      "july",
    ],
    datasets: [
      {
        label: "ordered",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderWidth: 1,
        backgroundColor: "#008B50",
        borderColor: "#008B50",
        tension: 0.4,
      },
      {
        label: "delivered",
        data: [56, 95, 8, 18, 65, 55, 42],
        fill: true,
        borderWidth: 1,
        backgroundColor: "#F88B0F",
        borderColor: "#F88B0F",
        tension: 0.4,
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default LineGraph;
