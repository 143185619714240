import axios from "axios";
import { useState, useEffect } from "react";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import SelfEmployed from "../../components/modals/SelfEmployed";
import Employed from "../../components/modals/Employed";
import OutrightCustomers from "../../components/users/OutrightCustomers";
import Student from "../../components/modals/Student";
import { AllBanksModal } from "../../components/modals/AllBanksModal";
import { DeleteUser } from "../../components/modals/DeleteUser";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AddBankModal } from "../../components/modals/AddBankModal";
import { IoAdd, IoSearchOutline } from "react-icons/io5";
import Pagination from "../../components/Pagination";
import toast from "react-hot-toast";
import DeactivatedAccounts from "../../components/users/DeactivatedAccounts";
import {
  formatNumber,
  getAllIncompleteUsers,
  getFlexibleUsers,
  getOutrightUsers,
} from "../../redux/thunk";
import { getDeactivated } from "../../redux/users/userSlice";
import { FaRegCopy } from "react-icons/fa6";
import UpdateCreditLimit from "../../components/modals/UpdateCreditLimit";

const Users = () => {
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState("");
  const [selectedInfo, setSelectedInfo] = useState<any>({});
  const [activeTab, setActiveTab] = useState("flexible");
  const [isLoadingDelete, setIsLoadingDetete] = useState(false);
  const [selfEmployedModalOpen, setSelfEmployedModalOpen] = useState(false);
  const [allBanksModal, setAllBanksModal] = useState(false);
  const [allBanksDetails, setAllBanksDetails] = useState([]);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [reactivateUserIsLoading, setReactivateUserIsLoading] = useState(false);
  const [addBankModal, setAddBankModal] = useState(false);
  const [userToAddBank, setUserToAddBank] = useState({});
  const [searchedUser, setSearchedUser] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [userData, setUserData] = useState([]);
  const [viewUpdateCreditLimit, setViewUpdateCreditLimit] = useState(false);

  const dispatch = useAppDispatch();
  const { flexible, outright, deactivated, status } = useAppSelector(
    (store) => store.users
  );

  console.log("flexible", flexible);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = flexible.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(flexible.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    dispatch(getFlexibleUsers());
    dispatch(getOutrightUsers());
    dispatch(getAllIncompleteUsers());
  }, []);

  useEffect(() => {
    dispatch(getDeactivated());
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowDeleteUser(false);
    dispatch(getFlexibleUsers());
    dispatch(getOutrightUsers());
    dispatch(getAllIncompleteUsers());
  };

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleViewAllBank = (id: string, linkedBanks: any) => {
    setUserId(id);
    setAllBanksDetails(linkedBanks);
    setAllBanksModal((prev) => !prev);
  };

  const handleUpdateCreditLimit = (data: any) => {
    setUserData(data);
    setViewUpdateCreditLimit((prev) => !prev);
  };

  const handleJobClick = (details: string, occupation: any) => {
    setSelectedInfo(details);
    if (
      occupation.toLowerCase() === "self-employed" ||
      occupation.toLowerCase() === "selfemployed"
    ) {
      setSelfEmployedModalOpen(true);
    } else if (occupation.toLowerCase() === "employed") {
      setEmployedModalOpen(true);
    } else {
      setStudentModalOpen(true);
    }
  };

  const handleDeleteClick = (data: any) => {
    setShowDeleteUser((prev) => !prev);
    setUserToDelete(data);
  };

  const handleAddBank = (firstName: any, lastName: any, id: string) => {
    setUserToAddBank({ name: lastName + " " + firstName, id });
    setAddBankModal((prev) => !prev);
  };

  const searchForUsers = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const isPhoneNumber = /^\d+$/.test(value);
      const searchResult =
        flexible?.filter((user: any) => {
          if (isPhoneNumber) {
            return user.phoneNumber
              ?.toLowerCase()
              .includes(value.toLowerCase());
          } else {
            return user.email?.toLowerCase().includes(value.toLowerCase());
          }
        }) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const handleReactivation = async (id: string) => {
    setReactivateUserIsLoading(true);
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/unblockCustomer/${id}`
      );
      dispatch(getFlexibleUsers());
      dispatch(getOutrightUsers());
      dispatch(getAllIncompleteUsers());
      toast.success(res.data.message || "Account re-activated!");
    } catch (error: any) {
      toast.error(error.message || "Failed to re-activate account");
    } finally {
      setReactivateUserIsLoading(false);
    }
  };

  const handleCopyClick = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="flex gap-10 mb-6">
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "outright" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "deactivated" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("deactivated")}
        >
          Deactivated users
        </span>
      </div>
      {activeTab === "flexible" ? (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div
            className={`w-full overflow-x-auto ${
              status === "loading" && "animate-pulse h-[50vh]"
            }`}
          >
            <div className="overflow-x-auto">
              <div className="flex justify-between md:items-center p-6 min-w-[700px]">
                <h1 className="text-base font-semibold ">User Details</h1>
                <div className="relative md:w-[30rem] w-fit">
                  <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
                  <input
                    type="search"
                    name="searchedUser"
                    id="searchedUser"
                    value={searchedUser}
                    onChange={(e) => searchForUsers(e.target.value)}
                    placeholder="Search with email or phone number"
                    className="border p-2 rounded-md text-sm indent-7 w-full"
                    disabled={flexible.length === 0}
                  />
                </div>
                <label htmlFor="filter">
                  Sort:
                  <select
                    name="filter"
                    id="filter"
                    className="border p-2 ml-4"
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="employed">Employed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Students</option>
                  </select>
                </label>
              </div>
            </div>
            <section className="overflow-x-auto">
              <table className="w-[1200px]" style={{ minWidth: "700px" }}>
                <thead className="bg-gray-50 font-bold p-4 text-left">
                  {filter === "student" && (
                    <tr>
                      <th className="p-2">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Education</th>
                      <th className="p-2 text-nowrap">Open Password</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                    </tr>
                  )}
                  {filter === "employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Job</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Open Password</th>
                      <th className="p-2 text-nowrap">Action</th>
                      <th className="p-2 text-nowrap">Force Delete User</th>
                    </tr>
                  )}
                  {filter === "all" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Occupation</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Open Password</th>
                      <th className="p-2 text-nowrap">Action</th>
                      <th className="p-2 text-nowrap">Force Delete User</th>
                    </tr>
                  )}
                  {filter === "self-employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2 ">BVN</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Business</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Open Password</th>
                      <th className="p-2 text-nowrap">Action</th>
                      <th className="p-2 text-nowrap">Force Delete User</th>
                    </tr>
                  )}
                </thead>
                <tbody className="px-4">
                  {searchedUser.length > 0 ? (
                    searchResults && searchResults.length > 0 ? (
                      searchResults.map((data: any, index) => (
                        <tr
                          className="border-b border-gray-300 py-2"
                          key={index}
                        >
                          <td className="text-secondary p-2">
                            {index + indexOfFirstPost + 1}
                          </td>
                          <td className="p-2 text-nowrap">
                            {data.lastName} {data.firstName}
                          </td>
                          <td className="p-2">
                            <div className="flex justify-between gap-2 items-center">
                              <p>{data.email}</p>
                              <button
                                type="button"
                                className="mr-3"
                                onClick={() => handleCopyClick(data.email)}
                              >
                                <FaRegCopy className="w-5 h-5 text-gray-500" />
                              </button>
                            </div>
                          </td>
                          <td className="p-2 text-nowrap">
                            {data.phoneNumber}
                          </td>
                          <td className="p-2">
                            <div className="flex items-center gap-2 justify-between">
                              <button
                                onClick={() => handleBvnClick(data)}
                                className="text-blue-500 hover:underline focus:outline-none"
                              >
                                {data.bvn}
                              </button>
                              <button
                                type="button"
                                className=""
                                onClick={() => handleCopyClick(data.bvn)}
                              >
                                <FaRegCopy className="w-5 h-5 text-gray-500" />
                              </button>
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className="text-secondary border"
                              onClick={() =>
                                handleAddBank(
                                  data.firstName,
                                  data.lastName,
                                  data._id
                                )
                              }
                            >
                              <IoAdd className="w-9 h-9" />
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className="bg-secondary text-white text-sm rounded-md p-2"
                              onClick={() =>
                                handleViewAllBank(data._id, data.linkedBanks)
                              }
                            >
                              View
                            </button>
                          </td>
                          <td className="p-2 text-nowrap">
                            <button
                              onClick={() =>
                                handleJobClick(data, data.employmentStatus)
                              }
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {data.employmentStatus}
                            </button>
                          </td>
                          <td className="p-2">
                            ₦{formatNumber(data.creditScore)}
                          </td>
                          <td className="p-2">
                            ₦{formatNumber(data.creditLimit)}
                          </td>
                          <td className="p-2">
                            {data.userPassword
                              ? data.userPassword
                              : "not found"}
                          </td>
                          <td className="p-2 text-nowrap">
                            <button
                              type="button"
                              className="p-2 text-sm text-secondary border border-secondary"
                              onClick={() => handleUpdateCreditLimit(data)}
                            >
                              Update Credit Limit
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              type="button"
                              className={`${
                                isLoadingDelete ? "bg-red-100" : "bg-red-600"
                              } p-2 text-sm text-white rounded-md`}
                              onClick={() => handleDeleteClick(data)}
                              disabled={isLoadingDelete}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={10}
                          className="text-center text-secondary p-3"
                        >
                          User not found
                        </td>
                      </tr>
                    )
                  ) : (
                    currentItems
                      .filter((customer: any) => {
                        if (filter === "employed") {
                          return customer.employmentStatus === "employed";
                        } else if (filter === "self-employed") {
                          return customer.employmentStatus === "selfEmployed";
                        } else if (filter === "student") {
                          return customer.employmentStatus === "student";
                        } else if (filter === "all") {
                          return customer.employmentStatus;
                        }
                        return true;
                      })
                      .map((data: any, index: any) => {
                        const filteredJob =
                          data.employmentStatus === "employed"
                            ? "Employed"
                            : data.employmentStatus === "selfEmployed"
                            ? "Self-Employed"
                            : data.employmentStatus === "student"
                            ? "Student"
                            : null;

                        const filteredJobObject =
                          data.employmentStatus === "employed"
                            ? data.employed
                            : data.employmentStatus === "selfEmployed"
                            ? data.selfEmployed
                            : data.employmentStatus === "student"
                            ? data.student
                            : null;
                        return (
                          <tr
                            className="border-b border-gray-300 py-2"
                            key={index}
                          >
                            <td className="text-secondary p-2">
                              {index + indexOfFirstPost + 1}
                            </td>
                            <td className="p-2 text-nowrap capitalize">
                              {data.lastName.toLowerCase()}{" "}
                              {data.firstName.toLowerCase()}
                            </td>
                            <td className="p-2">
                              <div className="flex gap-2 justify-between items-center">
                                <p>{data.email}</p>
                                <button
                                  type="button"
                                  className="mr-3"
                                  onClick={() => handleCopyClick(data.email)}
                                >
                                  <FaRegCopy className="w-5 h-5 text-gray-500" />
                                </button>
                              </div>
                            </td>
                            <td className="p-2 text-nowrap">
                              {data.phoneNumber}
                            </td>
                            <td className="p-2">
                              <div className="flex items-center gap-2 justify-between">
                                <button
                                  onClick={() => handleBvnClick(data)}
                                  className="text-blue-500 hover:underline focus:outline-none"
                                >
                                  {data.bvn}
                                </button>
                                <button
                                  type="button"
                                  className=""
                                  onClick={() => handleCopyClick(data.bvn)}
                                >
                                  <FaRegCopy className="w-5 h-5 text-gray-500" />
                                </button>
                              </div>
                            </td>
                            <td className="p-2 text-center">
                              <button
                                className="text-secondary border"
                                onClick={() =>
                                  handleAddBank(
                                    data.firstName,
                                    data.lastName,
                                    data._id
                                  )
                                }
                              >
                                <IoAdd className="w-9 h-9" />
                              </button>
                            </td>
                            <td className="p-2 text-center">
                              <button
                                className="bg-secondary text-white text-sm rounded-md p-2"
                                onClick={() =>
                                  handleViewAllBank(data._id, data.linkedBanks)
                                }
                              >
                                View
                              </button>
                            </td>
                            <td className="p-2 text-nowrap">
                              <button
                                onClick={() =>
                                  handleJobClick(filteredJobObject, filteredJob)
                                }
                                className="text-blue-500 hover:underline focus:outline-none"
                              >
                                {filteredJob}
                              </button>
                            </td>
                            <td className="p-2">
                              ₦{formatNumber(data.creditScore)}
                            </td>
                            <td className="p-2">
                              ₦{formatNumber(data.creditLimit)}
                            </td>
                            <td className="p-2">
                              {data.userPassword
                                ? data.userPassword
                                : "not found"}
                            </td>
                            <td className="p-2 text-nowrap">
                              <button
                                type="button"
                                className="p-2 text-sm text-secondary border border-secondary"
                                onClick={() => handleUpdateCreditLimit(data)}
                              >
                                Update Credit Limit
                              </button>
                            </td>
                            <td className="p-2 text-center">
                              <button
                                type="button"
                                className={`${
                                  isLoadingDelete ? "bg-red-100" : "bg-red-600"
                                } p-2 text-sm text-white rounded-md`}
                                onClick={() => handleDeleteClick(data)}
                                disabled={isLoadingDelete}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </section>
          </div>
          <section className="p-3 my-5">
            <Pagination
              length={
                searchResults.length > 0
                  ? searchResults.length
                  : flexible.length
              }
              itemsPerPage={itemsPerPage}
              handlePagination={handlePagination}
              currentPage={currentPage}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </section>
        </div>
      ) : activeTab === "outright" ? (
        <OutrightCustomers
          outright={outright}
          status={status}
          isLoadingDelete={isLoadingDelete}
          handleDeleteClick={handleDeleteClick}
        />
      ) : (
        <DeactivatedAccounts
          deactivated={deactivated}
          isLoading={reactivateUserIsLoading}
          handleReactivation={handleReactivation}
        />
      )}

      {showDeleteUser && (
        <DeleteUser
          userToDelete={userToDelete}
          handleCloseModal={handleCloseModal}
        />
      )}

      {allBanksModal && (
        <AllBanksModal
          setAllBanksModal={setAllBanksModal}
          setAllBanksDetails={setAllBanksDetails}
          allBanksDetails={allBanksDetails}
          userId={userId}
        />
      )}

      {viewUpdateCreditLimit && (
        <UpdateCreditLimit
          userData={userData}
          closeModal={() => setViewUpdateCreditLimit(false)}
        />
      )}

      {addBankModal && (
        <AddBankModal
          setAddBankModal={setAddBankModal}
          userToAddBank={userToAddBank}
        />
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}

      {selfEmployedModalOpen && (
        <SelfEmployed
          setSelfEmployedModalOpen={setSelfEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {employedModalOpen && (
        <Employed
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {studentModalOpen && (
        <Student
          selectedInfo={selectedInfo}
          setStudentModalOpen={setStudentModalOpen}
        />
      )}
    </main>
  );
};

export default Users;
