import React from "react";

const VAcctModal = ({ setShowVAcct, acctData }: any) => {
  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">VIRTUAL ACCOUNT</h2>
          <button onClick={() => setShowVAcct(false)} className="text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {acctData ? (
          <>
            <div className=" border-b border-gray-300 mb-8 flex justify-between">
              <h4 className="font-semibold">Bank Name:</h4>{" "}
              <h5>{acctData.bankName}</h5>
            </div>
            <div className="border-b border-gray-300 mb-8 flex justify-between">
              <h4 className="font-semibold">Bank Account Holder:</h4>{" "}
              <h5>{acctData.accountName}</h5>
            </div>
            <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
              <h4 className="font-semibold">Virtual Account Number:</h4>{" "}
              <h5>{acctData.virtualAccountNumber}</h5>
            </div>
          </>
        ) : (
          <p className="text-center">No Virtual Account</p>
        )}
      </div>
    </div>
  );
};

export default VAcctModal;
