import axios from "axios";
import { useState, useEffect } from "react";
import { RootState } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import toast from "react-hot-toast";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import Employed from "../../components/modals/Employed";
import { Preloader } from "../../components/elements/Preloader";
import DisapproveModal from "../../components/DisapproveModal";
import { verificationStageOne, verificationStageTwo } from "../../redux/thunk";
import Pagination from "../../components/Pagination";
import { IoSearchOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa6";

const StageOneVerification = () => {
  const [actionSelected, setActionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  // const [jobModalOpen, setJobModalOpen] = useState(false);
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [selectedBvn, setSelectedBvn] = useState("");
  const [selectedJob, setSelectedJob] = useState([]);
  const [bankStatement, setBankStatement] = useState("");
  const [disapproveMsg, setDisapproveMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useAppDispatch();
  const { employed } = useAppSelector((store) => store.verification);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any>([]);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = employed.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(employed.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (employed.length === 0) {
      dispatch(verificationStageOne());
    }
  }, [dispatch, employed.length]);

  const handleAction = (e: any) => {
    setActionSelected(e.target.value);
  };

  const handleClearSelection = () => {
    setActionSelected("");
    const selectElement = document.getElementById("select") as any;
    if (selectElement) {
      selectElement.selectedIndex = 0;
    }
  };

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleJobClick = (details: any, bankStatement: string) => {
    setSelectedJob(details);
    setBankStatement(bankStatement);
    setEmployedModalOpen(true);
  };

  const handleDisapproveClick = (msg: string) => {
    setDisapproveMsg(msg);
    setShowMsg(true);
  };

  const approveCustomerHandler = async () => {
    setApproveLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/approveStageOneProcess/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setId("");
      setActionSelected("");
      toast.success(res.data.message);
      dispatch(verificationStageOne());
      dispatch(verificationStageTwo());
    } catch (error) {
      setActionSelected("");
      toast.error("Error approving user, please try again later");
    } finally {
      setApproveLoading(false);
    }
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        employed?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const handleCopyClick = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const data =
    searchedUser && searchedUser.length > 0 ? searchResults : currentItems;

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">
              Employed Verification Stage 1(Bvn & Job Details Check)
            </h1>
            <div className="relative md:w-[30rem] w-fit">
              <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
              <input
                type="search"
                name="searchedUser"
                id="searchedUser"
                value={searchedUser}
                onChange={(e) => searchForUsersWithEmail(e.target.value)}
                placeholder="Search user using email"
                className="border p-2 rounded-md indent-7 w-full"
                disabled={employed.length === 0}
              />
            </div>
          </div>
          <section className="overflow-x-auto">
            <table className="w-full" style={{ minWidth: "1200px" }}>
              <thead className="bg-gray-50 text-left">
                <tr>
                  <th className="p-2">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2 ">BVN</th>
                  <th className="p-2">Job</th>
                  <th className="p-2">Action</th>
                  <th className="p-2">Status</th>
                </tr>
              </thead>
              <tbody className="px-4">
                {employed.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={7} className="p-10 text-secondary">
                      No pending verification!
                    </td>
                  </tr>
                ) : data && data.length > 0 ? (
                  data.map((user: any, index: number) => {
                    return (
                      <tr className="border-b border-gray-300 py-2" key={index}>
                        <td className="text-secondary p-2">
                          {index + indexOfFirstPost + 1}
                        </td>
                        <td className="p-2">
                          {user.lastName} {user.firstName}
                        </td>
                        <td className="p-2">{user.email}</td>
                        <td className="p-2 flex items-end gap-2">
                          <button
                            onClick={() => handleBvnClick(user)}
                            className="text-blue-500 hover:underline focus:outline-none"
                          >
                            {user.bvn}
                          </button>
                          <button
                            type="button"
                            className=""
                            onClick={() => handleCopyClick(user.bvn)}
                          >
                            <FaRegCopy className="w-5 h-5 text-gray-500" />
                          </button>
                        </td>
                        <td className="p-2">
                          <button
                            onClick={() =>
                              handleJobClick(user.employed, user.bankStatement)
                            }
                            className="text-blue-500 hover:underline focus:outline-none"
                          >
                            {user.employmentStatus}
                          </button>
                        </td>
                        <td className="p-2">
                          <select
                            name="select"
                            id="select"
                            className="cursor-pointer"
                            onChange={(e) => {
                              handleAction(e);
                              setId(user._id);
                            }}
                            disabled={user.adminVerification === "dstage1"}
                          >
                            <option value="">Select an action</option>
                            <option value="approve">Approve</option>
                            <option value="disapprove">Disapprove</option>
                          </select>
                        </td>
                        <td className="p-2">
                          <button
                            // onClick={
                            //   data.msg
                            //     ? () => handleDisapproveClick(data.msg)
                            //     : undefined
                            // }
                            className={
                              user.adminVerification !== "stage1"
                                ? "text-red-600"
                                : "text-yellow-400"
                            }
                          >
                            {user.adminVerification !== "stage1"
                              ? "Disapproved"
                              : "Pending..."}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center p-3 text-secondary">
                      {searchedUser.length > 0 ? "User not found" : "Not found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
        <section className="p-3 my-5">
          <Pagination
            length={
              searchedUser.length > 0 ? searchResults.length : employed.length
            }
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </div>
      {showMsg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setShowMsg(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>{disapproveMsg}</p>
              <div>
                <button
                  type="button"
                  onClick={() => setShowMsg(false)}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {actionSelected === "approve" && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want approve this user?</p>
              <div>
                <button
                  type="button"
                  onClick={approveCustomerHandler}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  {approveLoading ? <Preloader /> : "Yes"}
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {actionSelected === "disapprove" && (
        <DisapproveModal
          handleClearSelection={handleClearSelection}
          id={id}
          setId={setId}
          setActionSelected={setActionSelected}
        />
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}
      {employedModalOpen && (
        <Employed
          bankStatement={bankStatement}
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedJob}
        />
      )}
    </main>
  );
};

export default StageOneVerification;
