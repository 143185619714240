import { useEffect, useState } from "react";
import { verificationStageOne, verificationStageTwo } from "../../redux/thunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import toast from "react-hot-toast";
import axios from "axios";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import DisapproveModal from "../../components/DisapproveModal";
import Student from "../../components/modals/Student";
import Pagination from "../../components/Pagination";
import { RootState } from "../../redux/store";
import { IoSearchOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa6";

export const StudentVerification = () => {
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [eduModalOpen, setEduModalOpen] = useState(false);
  const [selectedBvn, setSelectedBvn] = useState<any>({});
  const [selectedEdu, setSelectedEdu] = useState<any>([]);
  const [actionSelected, setActionSelected] = useState("");
  const [bankStatement, setBankStatement] = useState("");
  const [disapproveMsg, setDisapproveMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [id, setId] = useState("");
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any>([]);

  const dispatch = useAppDispatch();
  const { student } = useAppSelector((store) => store.verification);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = student.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(student.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    dispatch(verificationStageOne());
  }, [dispatch]);

  const handleAction = (e: any) => {
    setActionSelected(e.target.value);
  };

  const handleClearSelection = (e: any) => {
    setActionSelected("");

    const selectElement = document.getElementById("select") as any;
    if (selectElement) {
      selectElement.selectedIndex = 0;
    }
  };

  const handleBvnClick = ({
    firstName,
    middleName,
    lastName,
    gender,
    bvn,
    image,
    address,
    dateOfBirth,
    stateOfOrigin,
  }: any) => {
    setSelectedBvn({
      firstName,
      middleName,
      lastName,
      gender,
      bvn,
      image,
      dateOfBirth,
      address,
      stateOfOrigin,
    });
    setBvnModalOpen(true);
  };

  const handleEduClick = (education: string, bankStatement: string) => {
    setSelectedEdu(education);
    setBankStatement(bankStatement);
    setEduModalOpen(true);
  };

  const handleDisapproveClick = (msg: string) => {
    setDisapproveMsg(msg);
    setShowMsg(true);
  };

  const approveCustomerHandler = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/approveStageOneProcess/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setId("");
      setActionSelected("");
      toast.success(res.data.message);
      dispatch(verificationStageOne());
      dispatch(verificationStageTwo());
      setIsLoading(false);
    } catch (error) {
      setActionSelected("");
      toast.error("Error approving user, please try again later");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        student?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const handleCopyClick = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const data =
    searchedUser && searchedUser.length > 0 ? searchResults : currentItems;

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">
              Student Verification Stage 1(Bvn & Student Details Check)
            </h1>
            <div className="relative md:w-[30rem] w-fit">
              <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
              <input
                type="search"
                name="searchedUser"
                id="searchedUser"
                value={searchedUser}
                onChange={(e) => searchForUsersWithEmail(e.target.value)}
                placeholder="Search user using email"
                className="border p-2 rounded-md indent-7 w-full"
                disabled={student.length === 0}
              />
            </div>
          </div>
          <section className="overflow-x-auto">
            <table className="w-full" style={{ minWidth: "1200px" }}>
              <thead className="bg-gray-50 text-left">
                <tr>
                  <th className="p-2">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2">BVN</th>
                  <th className="p-2">Education</th>
                  <th className="p-2">Action</th>
                  <th className="p-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {student.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={7} className="p-10 text-secondary">
                      No pending verification!
                    </td>
                  </tr>
                ) : data && data.length > 0 ? (
                  data.map((data: any, index: number) => (
                    <tr className="border-b" key={index}>
                      <td className="text-secondary p-2">
                        {index + indexOfFirstPost + 1}
                      </td>
                      <td className="p-2 text-sm">
                        {data.lastName} {data.firstName}
                      </td>
                      <td className="p-2 text-sm">{data.email}</td>
                      <td className="p-2 text-sm flex items-end gap-2 ">
                        <button
                          onClick={() =>
                            handleBvnClick({
                              firstName: data.firstName,
                              middleName: data.middleName,
                              lastName: data.lastName,
                              bvn: data.bvn,
                              gender: data.gender,
                              image: data.image,
                              address: data.address,
                              dateOfBirth: data.dateOfBirth,
                              stateOfOrigin: data.stateOfOrigin,
                            })
                          }
                          className="text-blue-500 hover:underline focus:outline-none"
                        >
                          {data.bvn}
                        </button>
                        <button
                          type="button"
                          className=""
                          onClick={() => handleCopyClick(data.bvn)}
                        >
                          <FaRegCopy className="w-5 h-5 text-gray-500" />
                        </button>
                      </td>
                      <td className="p-2">
                        <button
                          onClick={() =>
                            handleEduClick(data.student, data.bankStatement)
                          }
                          className="text-blue-500 hover:underline focus:outline-none"
                        >
                          {data.student.departmentName}
                        </button>
                      </td>
                      <td className="p-2">
                        <select
                          name="select"
                          id="select"
                          className="cursor-pointer"
                          onChange={(e) => {
                            handleAction(e);
                            setId(data._id);
                          }}
                          disabled={data.adminVerification === "dstage1"}
                        >
                          <option className="text-sm" value="">
                            Select an action
                          </option>
                          <option className="text-sm" value="approve">
                            Approve
                          </option>
                          <option className="text-sm" value="disapprove">
                            Disapprove
                          </option>
                        </select>
                      </td>
                      <td className="p-2">
                        <button
                          onClick={
                            data.msg
                              ? () => handleDisapproveClick(data.msg)
                              : undefined
                          }
                          className={
                            data.adminVerification !== "stage1"
                              ? "text-red-600"
                              : "text-yellow-400"
                          }
                        >
                          {data.adminVerification !== "stage1"
                            ? "Disapproved"
                            : "Pending"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center p-3 text-secondary">
                      {searchedUser.length > 0 ? "User not found" : "Not found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
        <section className="p-3 my-5">
          <Pagination
            length={
              searchedUser.length > 0 ? searchResults.length : student.length
            }
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </div>
      {showMsg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-4 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setShowMsg(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>{disapproveMsg}</p>
              <div>
                <button
                  type="button"
                  onClick={() => setShowMsg(false)}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {actionSelected === "approve" && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want approve this user?</p>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={approveCustomerHandler}
                >
                  {isLoading ? <LoadingSpinner /> : "Yes"}
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {actionSelected === "disapprove" && (
        <DisapproveModal
          handleClearSelection={handleClearSelection}
          id={id}
          setId={setId}
          setActionSelected={setActionSelected}
        />
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          selectedBvn={selectedBvn}
          setBvnModalOpen={setBvnModalOpen}
        />
      )}

      {eduModalOpen && (
        <Student
          bankStatement={bankStatement}
          selectedInfo={selectedEdu}
          setStudentModalOpen={setEduModalOpen}
        />
      )}
    </main>
  );
};
