import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  approveOrder,
  getOrders,
  getSingleCustomer,
  getAllVendors,
} from "../../redux/thunk";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import OrderDetails from "../../components/modals/OrderDetails";
import axios from "axios";

import { RootState } from "../../redux/store";
import Pagination from "../../components/Pagination";
import ViewCard from "../../components/modals/ViewCard";
import { FaRegCopy } from "react-icons/fa6";
import { Preloader } from "../../components/elements/Preloader";
import { IoSearchOutline } from "react-icons/io5";

export const Order = () => {
  const [activeTab, setActiveTab] = useState("flexible");
  const [viewDetails, setViewDetails] = useState(false);
  const [viewCancelOrder, setViewCancelOrder] = useState({
    details: {},
    status: false,
    btn: false,
  });
  const [cancelReason, setCancelReason] = useState("");
  const [users, setUsers] = useState<any>([]);
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [pickupDetails, setPickupDetails] = useState<any>([]);
  const [filter, setFilter] = useState("");
  const [error, setError] = useState<any>({});
  const [ordersWithUserId, setOrdersWithUserId] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [searchedUser, setSearchedUser] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const dispatch = useAppDispatch();
  const { orders } = useAppSelector((store) => store.orders);

  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;

  useEffect(() => {
    setIsLoading((prev) => !prev);
    dispatch(getOrders()).then(() => {
      setIsLoading((prev) => !prev);
    });
    dispatch(getAllVendors());
  }, []);

  useEffect(() => {
    if (orders.length > 0)
      setOrdersWithUserId(orders.filter((order: any) => order.userId));
  }, [orders]);

  useEffect(() => {
    const extractedUserIds = ordersWithUserId.map(
      (item: any) => item?.userId?.id
    );
    const fetchUsersData = async () => {
      const usersData = await Promise.all(
        extractedUserIds.map(async (id: any) => {
          const response = await dispatch(getSingleCustomer(id));

          return response.payload;
        })
      );
      setUsers(usersData);
    };

    if (extractedUserIds.length > 0) {
      fetchUsersData();
    }
  }, [dispatch, ordersWithUserId]);

  const validateOrderApproval = () => {
    let isValid = true;
    let newError: any = {};

    pickupDetails.forEach((item: any) => {
      if (item.vendorId.length === 0) {
        newError.pickupDetails = "Please select vendor";
        isValid = false;
      }
    });
    setError(newError);
    return isValid;
  };

  const handleCopyClick = (email: string) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard");
  };

  const handleInitialApprove = async (id: string, pickUpDetails: string) => {
    if (pickUpDetails.length === 0) {
      error.initialPickup = "Enter pickup location";
      return;
    }

    const payload = {
      orderId: id,
      pickUpDetails,
    };

    try {
      setIsLoading((prev) => !prev);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/approveOrderInitial`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message || "Order approved successfully");
      dispatch(getOrders());
      setIsLoading((prev) => !prev);
      setViewDetails((prev) => !prev);
    } catch (error: any) {
      toast.error(error.message || "Failed to approve order!");
    }
  };

  const handleViewCard = (cardDetails: any) => {
    setCardDetails(cardDetails);
    setShowCard(true);
  };

  const handleApproveOrder = async (id: string) => {
    if (!validateOrderApproval()) {
      return;
    }

    try {
      setIsLoading((prev) => !prev);
      await dispatch(
        approveOrder({ orderId: id, pickUpDetails: pickupDetails })
      );

      dispatch(getOrders());
      setIsLoading((prev) => !prev);
      setViewDetails((prev) => !prev);
    } catch (error: any) {
      toast.error(error.message || "Failed to approve order!");
    }
  };

  const handleViewDetails = (orderData: any) => {
    setOrderDetails(orderData);
    setViewDetails((prev) => !prev);
  };

  const filteredOrders = ordersWithUserId.filter((order: any) => {
    const user = users.find(
      (user: any) => user?.customer?.id === order?.userId?.id
    );

    if (!user || !user.customer) {
      return false;
    }

    if (activeTab === "christmasPackage") {
      return order.isXmasPackage;
    } else if (activeTab === "flexible" && !order.isXmasPackage) {
      return user.customer.accountType === "flexible";
    } else if (activeTab === "outright" && !order.isXmasPackage) {
      return user.customer.accountType === "outright";
    }

    return false;
  });

  const handleCancelOrder = async (orderId: string) => {
    if (cancelReason.trim().length === 0) {
      return;
    }

    setViewCancelOrder((prev) => ({
      ...prev,
      btn: !prev.btn,
    }));
    const payload = {
      cancelReason: cancelReason,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/cancelOrder/order/${orderId}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(res.data.message || "Order canceled successfully");
      dispatch(getOrders());
    } catch (error: any) {
      console.error("error", error);
      toast.error(error.message || "Failed to cancel order");
    } finally {
      setViewCancelOrder((prev) => ({
        details: {},
        status: false,
        btn: !prev.btn,
      }));
    }
  };

  // pagination
  const reverseFilteredOrders = filteredOrders.slice().reverse();
  const currentItems = reverseFilteredOrders.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (
      currentPage !== Math.ceil(reverseFilteredOrders.length / itemsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        reverseFilteredOrders?.filter((user: any) =>
          user.userId.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <main>
      <h2 className="font-semibold text-lg">Clients' Orders</h2>
      <div className="relative w-full text-sm my-3">
        <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
        <input
          type="search"
          name="searchedUser"
          id="searchedUser"
          value={searchedUser}
          onChange={(e) => searchForUsersWithEmail(e.target.value)}
          placeholder="Search user using email"
          className="border p-2 rounded-md indent-7 w-full"
          disabled={reverseFilteredOrders.length === 0}
        />
      </div>

      <div className=" my-6">
        <span
          className={`cursor-pointer mr-5 pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-secondary" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Orders
        </span>
        <span
          className={`cursor-pointer mr-5 pb-2 ${
            activeTab === "outright" ? "border-b-2 border-secondary" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Orders
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "christmasPackage"
              ? "border-b-2 border-secondary"
              : "null"
          }`}
          onClick={() => setActiveTab("christmasPackage")}
        >
          Christmas Package
        </span>
        <span className="float-right">
          <label htmlFor="filter">
            Sort:
            <select
              name="filter"
              id="filter"
              className="border p-2 ml-4 text-sm"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="delivered">Delivered</option>
              <option value="cancel">Canceled</option>
              <option value="rider">Rider</option>
            </select>
          </label>
        </span>
      </div>
      <section className="w-full bg-white p-3">
        <table className="w-full">
          <thead className="text-left">
            <tr>
              <th>SN</th>
              <th>Client</th>
              <th>Email</th>
              <th>Card</th>
              {activeTab === "christmasPackage" && <th>Account Type</th>}
              <th>Action</th>
              <th>Status</th>
              <th>Action 2</th>
            </tr>
          </thead>
          <tbody className="relative">
            {isLoading ? (
              <tr>
                <td colSpan={7}>
                  <LoadingSpinner />
                </td>
              </tr>
            ) : searchedUser.length > 0 ? (
              searchResults && searchResults.length > 0 ? (
                searchResults.map((data: any, index) => (
                  <tr
                    className="border-b border-gray-300 py-2 hover:bg-gray-50"
                    key={index}
                  >
                    <td className="text-secondary p-2">
                      {index + indexOfFirstPost + 1}
                    </td>
                    <td>
                      <span className="capitalize">
                        {`${data.userId.firstName.toLowerCase()} ${data.userId.lastName.toLowerCase()}` ||
                          "---"}
                      </span>
                    </td>
                    <td>
                      <div className="flex gap-3 items-center">
                        <button
                          type="button"
                          className=""
                          onClick={() => handleCopyClick(data.userId.email)}
                        >
                          <FaRegCopy className="w-5 h-5 text-gray-500" />
                        </button>
                        <span>{data.userId?.email || "---"}</span>
                      </div>
                    </td>
                    <td className="py-1">
                      <button
                        type="button"
                        className="text-secondary text-sm"
                        onClick={() => handleViewCard(data.cards)}
                      >
                        View
                      </button>
                    </td>
                    {activeTab === "christmasPackage" && (
                      <td className="capitalize">
                        <span>
                          {`${data.userId.firstName} ${data.userId.lastName}` ||
                            "---"}
                        </span>
                      </td>
                    )}
                    <td className="py-1">
                      <button
                        type="button"
                        className="p-2 bg-secondary rounded text-white text-sm"
                        onClick={() => handleViewDetails(data)}
                      >
                        View details
                      </button>
                    </td>
                    <td className="py-1">
                      <p
                        className={`p-2 w-fit rounded text-white text-sm ${
                          data.status === "approved"
                            ? "bg-secondary"
                            : "bg-yellow-300"
                        }`}
                      >
                        {data.status === "cancel" ? "Canceled" : data.status}
                      </p>
                    </td>
                    <td className="py-1">
                      <button
                        type="button"
                        className={`p-2 rounded text-secondary border border-secondary text-sm ${
                          data.status !== "pending" && "bg-gray-300"
                        }`}
                        onClick={() =>
                          setViewCancelOrder((prev) => ({
                            details: data,
                            status: !prev.status,
                            btn: false,
                          }))
                        }
                        disabled={data.status !== "pending"}
                      >
                        {data.status === "cancel"
                          ? "Canceled"
                          : "Cancel Order"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center text-secondary p-3">
                    User not found
                  </td>
                </tr>
              )
            ) : (
              currentItems.length > 0 &&
              currentItems
                .filter((order: any) => {
                  if (filter === "pending") {
                    return order.status === "pending";
                  } else if (filter === "approved") {
                    return order.status === "approved";
                  } else if (filter === "delivered") {
                    return order.status === "delivered";
                  } else if (filter === "cancel") {
                    return order.status === "cancel";
                  } else if (filter === "rider") {
                    return order.status === "rider";
                  } else {
                    return order;
                  }
                })
                .map((order: any, index: number) => (
                  <tr key={index}>
                    <td>{index + indexOfFirstPost + 1}</td>
                    <td>
                      <span className="capitalize">
                        {`${order.userId.firstName.toLowerCase()} ${order.userId.lastName.toLowerCase()}` ||
                          "---"}
                      </span>
                    </td>
                    <td>
                      <div className="flex gap-3 items-center">
                        <button
                          type="button"
                          className=""
                          onClick={() => handleCopyClick(order.userId.email)}
                        >
                          <FaRegCopy className="w-5 h-5 text-gray-500" />
                        </button>
                        <span>{order.userId?.email || "---"}</span>
                      </div>
                    </td>
                    <td className="py-1">
                      <button
                        type="button"
                        className="text-secondary text-sm"
                        onClick={() => handleViewCard(order.cards)}
                      >
                        View
                      </button>
                    </td>
                    {activeTab === "christmasPackage" && (
                      <td className="capitalize">
                        <span>
                          {`${order.userId.firstName} ${order.userId.lastName}` ||
                            "---"}
                        </span>
                      </td>
                    )}
                    <td className="py-1">
                      <button
                        type="button"
                        className="p-2 bg-secondary rounded text-white text-sm"
                        onClick={() => handleViewDetails(order)}
                      >
                        View details
                      </button>
                    </td>
                    <td className="py-1">
                      <p
                        className={`p-2 w-fit rounded text-white text-sm ${
                          order.status === "approved"
                            ? "bg-secondary"
                            : "bg-yellow-300"
                        }`}
                      >
                        {order.status === "cancel" ? "Canceled" : order.status}
                      </p>
                    </td>
                    <td className="py-1">
                      <button
                        type="button"
                        className={`p-2 rounded text-secondary border border-secondary text-sm ${
                          order.status !== "pending" && "bg-gray-300"
                        }`}
                        onClick={() =>
                          setViewCancelOrder((prev) => ({
                            details: order,
                            status: !prev.status,
                            btn: false,
                          }))
                        }
                        disabled={order.status !== "pending"}
                      >
                        {order.status === "cancel"
                          ? "Canceled"
                          : "Cancel Order"}
                      </button>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
        <section className="p-3 my-5">
          <Pagination
            length={searchResults.length > 0
              ? searchResults.length : filteredOrders.length}
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </section>

      {viewCancelOrder.status && (
        <div className="fixed z-40 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-1/2 flex items-center flex-col">
            <h3 className="text-lg font-semibold">Cancel Order </h3>
            <p className="">Why are you canceling this order?</p>
            <textarea
              name="reason"
              id="reason"
              className="w-full border p-2 text-sm mt-2"
              placeholder="Enter reason for canceling order"
              rows={3}
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            ></textarea>
            <div className="flex justify-end gap-3 text-sm mt-5">
              <button
                type="button"
                className="p-2 w-40 bg-secondary text-white rounded-md"
                onClick={() => {
                  setCancelReason("");
                  setViewCancelOrder((prev) => ({
                    details: {},
                    btn: false,
                    status: !prev.status,
                  }));
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="p-2 w-40 bg-red-600 text-white rounded-md"
                onClick={() =>
                  handleCancelOrder((viewCancelOrder.details as any)._id)
                }
                disabled={cancelReason.trim().length === 0}
              >
                {viewCancelOrder.btn ? <Preloader /> : "Proceed"}
              </button>
            </div>
          </div>
        </div>
      )}
      {viewDetails && (
        <OrderDetails
          accountType={activeTab}
          orderDetails={orderDetails}
          setViewDetails={setViewDetails}
          pickupDetails={pickupDetails}
          setPickupDetails={setPickupDetails}
          handleApproveOrder={handleApproveOrder}
          handleInitialApprove={handleInitialApprove}
          error={error}
          setError={setError}
          isLoading={isLoading}
        />
      )}
      {showCard && (
        <ViewCard
          closeModal={() => setShowCard(false)}
          cardDetails={cardDetails}
        />
      )}
    </main>
  );
};
