const Student = ({ bankStatement, selectedInfo, setStudentModalOpen }: any) => {
  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">EDUCATION INFORMATION </h2>
          <button
            onClick={() => setStudentModalOpen(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className=" border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Name of Institute:</h4>{" "}
          <h5>
            {(selectedInfo.student
              ? selectedInfo.student.schoolName
              : selectedInfo.schoolName) || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Name of Faculty:</h4>{" "}
          <h5>
            {(selectedInfo.student
              ? selectedInfo.student.facultyName
              : selectedInfo.facultyName) || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Name of Department:</h4>{" "}
          <h5>
            {(selectedInfo.student
              ? selectedInfo.student.departmentName
              : selectedInfo.departmentName) || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Matriculation Number</h4>{" "}
          <h5>
            {(selectedInfo.student
              ? selectedInfo.student.matricNumber
              : selectedInfo.matricNumber) || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Level</h4>{" "}
          <h5>
            {(selectedInfo.student
              ? selectedInfo.student.level
              : selectedInfo.level) || "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Account Name</h4>{" "}
          <h5>
            {selectedInfo.student
              ? selectedInfo.student.accountName
                ? selectedInfo.student.accountBankName
                : selectedInfo.student.accounts?.length > 0
                ? selectedInfo.student.accounts[0].accountBankName
                : "not found"
              : selectedInfo.accountName
              ? selectedInfo.accountBankName
              : selectedInfo.accounts?.length > 0
              ? selectedInfo.accounts[0].accountBankName
              : "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Account Number</h4>{" "}
          <h5>
            {selectedInfo.student
              ? selectedInfo.student.accountNumber
                ? selectedInfo.student.accountNumber
                : selectedInfo.student.accounts?.length > 0
                ? selectedInfo.student.accounts[0].accountNumber
                : "not found"
              : selectedInfo.accountNumber
              ? selectedInfo.accountNumber
              : selectedInfo.accounts?.length > 0
              ? selectedInfo.accounts[0].accountNumber
              : "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">Account Bank Name</h4>{" "}
          <h5>
            {selectedInfo.student
              ? selectedInfo.student.accountBankName
                ? selectedInfo.student.accountName
                : selectedInfo.student.accounts?.length > 0
                ? selectedInfo.student.accounts[0].accountName
                : "not found"
              : selectedInfo.accountBankName
              ? selectedInfo.accountName
              : selectedInfo.accounts?.length > 0
              ? selectedInfo.accounts[0].accountName
              : "not found"}
          </h5>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">ID Card Front</h4>
          <a
            className="w-1/2 truncate"
            href={
              (selectedInfo.student
                ? selectedInfo.student.studentIdCard[0]
                : selectedInfo.studentIdCard[0]) || ""
            }
            target="blank"
          >
            {(selectedInfo.student
              ? selectedInfo.student.studentIdCard[0]
              : selectedInfo.studentIdCard[0]) || "not found"}
          </a>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold">ID Card Back</h4>
          <a
            className="w-1/2 truncate"
            href={
              (selectedInfo.student
                ? selectedInfo.student.studentIdCard[1]
                : selectedInfo.studentIdCard[1]) || ""
            }
            target="blank"
          >
            {(selectedInfo.student
              ? selectedInfo.student.studentIdCard[1]
              : selectedInfo.studentIdCard[1]) || "not found"}
          </a>
        </div>
        <div className="border-b border-gray-300 mb-3 text-sm flex justify-between">
          <h4 className="font-semibold capitalize">Bank Statement</h4>
          <a
            className="w-1/2 truncate"
            href={bankStatement || ""}
            target="blank"
          >
            {bankStatement || "not found"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Student;
