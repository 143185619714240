import { useState } from "react";
import { Preloader } from "../elements/Preloader";
import { IoSearchOutline } from "react-icons/io5";
import Pagination from "../Pagination";

const DeactivatedAccounts = ({
  deactivated,
  isLoading,
  handleReactivation,
}: any) => {
  const [searchedUser, setSearchedUser] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = deactivated.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(deactivated.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        deactivated?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div className="bg-white rounded-md shadow-md pb-6">
      <div className="flex justify-between items-center w-full p-3">
        <h1 className="text-base font-semibold p-3">Deactivated Users</h1>
        <div>
          <div className="relative md:w-[30rem] w-fit mb-3">
            <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
            <input
              type="search"
              name="searchedUser"
              id="searchedUser"
              value={searchedUser}
              onChange={(e) => searchForUsersWithEmail(e.target.value)}
              placeholder="Search user using email"
              className="border p-2 rounded-md indent-7 w-full"
              disabled={deactivated.length === 0}
            />
          </div>
        </div>
      </div>

      <section className="overflow-x-auto">
        <table className="w-full" style={{ minWidth: "700px" }}>
          <thead className="bg-gray-50 font-bold p-4 text-left text-nowrap">
            <tr>
              <th className="px-2 py-4 ">S/N</th>
              <th className="px-2 py-4 ">Name</th>
              <th className="px-2 py-4">Phone Number</th>
              <th className="px-2 py-4">Email address</th>
              <th className="px-2 py-4"></th>
            </tr>
          </thead>
          <tbody className="px-4 text-nowrap">
            {isLoading ? (
              <Preloader />
            ) : searchedUser.length > 0 ? (
              searchResults && searchResults.length > 0 ? (
                searchResults.map((data: any, index) => (
                  <tr
                    className="border-b border-gray-300 py-2 hover:bg-gray-50"
                    key={index}
                  >
                    <td className="text-secondary p-2">
                      {index + indexOfFirstPost + 1}
                    </td>
                    <td className="p-2">
                      {data.firstName && data.lastName
                        ? `${data.firstName} ${data.lastName}`
                        : "Not available"}
                    </td>
                    <td className="p-2">
                      {data.phoneNumber ? data.phoneNumber : "Not available"}
                    </td>
                    <td className="p-2">
                      {data.email ? data.email : "Not available"}
                    </td>
                    <td className="p-2">
                      <button
                        type="button"
                        className={`${
                          isLoading.reactivate ? "bg-red-100" : "bg-red-600"
                        } p-2 text-sm text-white rounded-md`}
                        onClick={() => handleReactivation(data.id)}
                        disabled={isLoading.reactivate}
                      >
                        Re-activate
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center text-secondary p-3">
                    User with email not found
                  </td>
                </tr>
              )
            ) : currentItems.length > 0 ? (
              currentItems.map((data: any, index: number) => {
                return (
                  <tr
                    className="border-b border-gray-300 py-2 hover:bg-gray-50"
                    key={index}
                  >
                    <td className="text-secondary p-2">
                      {index + indexOfFirstPost + 1}
                    </td>
                    <td className="p-2">
                      {data.firstName && data.lastName
                        ? `${data.firstName} ${data.lastName}`
                        : "Not available"}
                    </td>
                    <td className="p-2">
                      {data.phoneNumber ? data.phoneNumber : "Not available"}
                    </td>
                    <td className="p-2">
                      {data.email ? data.email : "Not available"}
                    </td>
                    <td className="p-2">
                      <button
                        type="button"
                        className={`${
                          isLoading.reactivate ? "bg-red-100" : "bg-red-600"
                        } p-2 text-sm text-white rounded-md`}
                        onClick={() => handleReactivation(data.id)}
                        disabled={isLoading.reactivate}
                      >
                        Re-activate
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center text-secondary p-5">
                  No user available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section
        className={`p-3 my-5 ${
          searchResults.length > 0 || deactivated.length === 0
            ? "hidden"
            : "block"
        }`}
      >
        <Pagination
          length={
            searchResults.length > 0
              ? searchResults.length
              : deactivated.length
          }
          itemsPerPage={itemsPerPage}
          handlePagination={handlePagination}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </section>
    </div>
  );
};

export default DeactivatedAccounts;
