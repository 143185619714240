import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import LogoutModal from "../components/LogoutModal";
import { checkTokenExpiration } from "../redux/thunk";

export const BasePage = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [tabNavigation, setTabNavigation] = useState(false);
  const { lastName } = useAppSelector((store: RootState) => store.auth);
  const [role, setRole] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [src, setSrc] = useState("");
  const [modals, setModals] = useState({
    logout: false,
  });
  useEffect(() => {
    getAllAdmins();
  }, []);

  useEffect(() => {
    if (token) {
      checkTokenExpiration();
    }
  }, [token]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 900000); // 15 minutes

    return () => clearInterval(intervalId);
  }, []);

  const toggleTabNavigation = () => {
    setTabNavigation(!tabNavigation);
  };

  const openModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: true }));
  };

  const closeModal = (modalName: string) => {
    setModals((prevState) => ({ ...prevState, [modalName]: false }));
  };

  const getAllAdmins = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-admins-except-superadmin`
      );
      const filteredAdmin = res.data.filter(
        (admin: any) => admin.lastName === lastName
      );
      setRole(filteredAdmin[0].adminRole);
    } catch (error) {}
  };

  return (
    <div className="lg:flex">
      <div className={`transition ${showSidebar ? "lg:block" : "hidden"}`}>
        <SideBar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          tabNavigation={tabNavigation}
          toggleTabNavigation={toggleTabNavigation}
          openModal={() => openModal("logout")}
          role={role}
          setSrc={setSrc}
        />
      </div>
      <div
        className={`w-full ml-0 overflow-x-hidden ${
          showSidebar && "lg:ml-[20%]"
        }`}
      >
        <Navbar
          tabNavigation={tabNavigation}
          toggleTabNavigation={toggleTabNavigation}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        <div className="pt-[5rem] lg:pt-28 py-1 px-4 2xl:px-8 pb-8">
          {src ? (
            <iframe
              src={src}
              className="w-full h-screen border-none"
              title="web app"
            ></iframe>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
      {modals.logout && <LogoutModal closeModal={() => closeModal("logout")} />}
    </div>
  );
};
