export const DeliveryDetails = ({
  setShowOrderDetails,
  showOrderDetails,
  orderDetails,
  setViewItems,
  viewItems,
}: any) => {
  const handleCloseModal = () => {
    if (viewItems) {
      setViewItems(false);
    }
    if (showOrderDetails) {
      setShowOrderDetails(false);
    }
  };

  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "700px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="font-semibold">DELIVERY DETAILS</h2>
          <button onClick={handleCloseModal} className="text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          <table className="w-full text-left mb-5">
            <thead className="border bg-gray-50">
              <tr>
                <th className="p-2 text-sm">Item</th>
                <th className="p-2 text-sm">Quantity</th>
                {viewItems ? null : (
                  <>
                    <th className="p-2 text-sm">Price</th>
                    <th className="p-2 text-sm">Vendor Name</th>
                    <th className="p-2 text-sm">Vendor Address</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {orderDetails.items.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="p-2 border text-sm">{item.name}</td>
                  <td className="p-2 border text-sm">{item.quantity}</td>
                  {viewItems ? null : (
                    <>
                      <td className="p-2 border text-sm">{item.totalPrice}</td>
                      <td className="p-2 border text-sm">
                        {item.pickUpDetails
                          ? item.pickUpDetails.fullName || 'loading'
                          : "No vendor"}
                      </td>
                      <td className="p-2 border text-sm">
                        {item.pickUpDetails
                          ? item.pickUpDetails.address || 'loading'
                          : "No vendor"}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {viewItems ? null : (
            <div>
              <div className=" border-b border-gray-300 mb-8 flex justify-between">
                <h4 className="font-semibold w-32 text-sm">
                  Delivery Location:
                </h4>{" "}
                <h5 className="text-sm">{orderDetails.deliveryLocation}</h5>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};
