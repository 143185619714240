import BarChart from "./BarChart";
import PieChart from "./PieChart";

const Analytics = () => {
  return (
    <section className="flex flex-col md:flex-row justify-between gap-10">
      <section className="md:w-1/2 bg-white shadow-md p-5 rounded-b-lg">
        <div className="flex justify-between items-center mb-10 px-10">
          <h3 className="font-semibold text-lg">VERIFIED USERS</h3>
        </div>
        <section className="flex items-end px-2 relative mb-5">
          <p className="border-b-2 font-bold border-red-500 block w-fit">Pie</p>
          <img
            src="/assets/line.svg"
            alt=""
            className="absolute -bottom-1 right-0 w-11/12"
          />
        </section>
        <section className="">
          <div className="text-xs">
            <div className="flex items-center my-1 gap-2">
              <div className="bg-[#92E3A9] w-3 h-3"></div>
              <p>Employed</p>
            </div>
            <div className="flex items-center my-1 gap-2">
              <div className="bg-[#F1D63A] w-3 h-3"></div>
              <p>Self-Employed</p>
            </div>
            <div className="flex items-center my-1 gap-2">
              <div className="bg-[#7086FD] w-3 h-3"></div>
              <p>Student</p>
            </div>
          </div>
          <PieChart />
        </section>
      </section>

      <section className="md:w-1/2 bg-white shadow-md p-5 rounded-b-lg">
        <div className="flex justify-between items-center mb-10 px-10">
          <h3 className="font-semibold text-lg">REQUEST APPROVAL</h3>
        </div>
        <section className="flex items-end px-2 relative mb-5">
          <p className="border-b-2 font-bold border-red-500 block w-fit">Bar</p>
          <img
            src="/assets/line.svg"
            alt=""
            className="absolute -bottom-1 right-0 w-11/12"
          />
        </section>
        <section className="h-full">
          <BarChart />
        </section>
      </section>
    </section>
  );
};

export default Analytics;
