import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Signup from "./pages/Authentication/Signup";
import Dashboard from "./pages/Dashboard";
import AdminSignup from "./pages/Authentication/Admin/AdminSignup";
import { SelfEmployedVerificationStage1 } from "./pages/VerificationStage1/SelfEmployedVerificationStage1";
import { StudentVerification } from "./pages/VerificationStage1/StudentVerification";
import { Assignment } from "./pages/Assignment";
import { DocumentCheck } from "./pages/DocumentCheck";
import { CreditScore } from "./pages/CreditScore";
import { BasePage } from "./pages/BasePage";
import StageOneVerification from "./pages/VerificationStage1/StageOneTable";
import { NextPay } from "./pages/NextPay";
import { Defaulters } from "./pages/Defaulters";
import { NewsUpdate } from "./pages/NewsUpdate";
import { CreateAdmin } from "./pages/CreateAdmin";
import { Store } from "./pages/Store";
import { Faq } from "./pages/Faq";
import Login from "./pages/Authentication/Login";
import Users from "./pages/Users/Users";
import { Order } from "./pages/Orders/Order";
import { Repayment } from "./pages/Repayment";
import { Logistics } from "./pages/Logistics/Logistics";
import { RiderActivity } from "./pages/Logistics/RiderActivity";
import { BusyRiders } from "./pages/Logistics/BusyRiders";
import { AssignDelivery } from "./pages/Logistics/AssignDelivery";
import { AssignedOrders } from "./pages/Logistics/AssignedOrders";
import { AllDeliveries } from "./pages/Logistics/AllDeliveries";
import { Disbutes } from "./pages/Logistics/Disbutes";
import { VirtualAccount } from "./pages/VirtualAccount";
import { Emandate } from "./pages/Emandate/Emandate";
import { Restaurants } from "./pages/Restaurant/Restaurants";
import { RestaurantInfo } from "./pages/Restaurant/RestaurantInfo";
import { CustomerCare } from "./pages/CustomerService/CustomerCare";
import { Settlement } from "./pages/Restaurant/Settlement";
import AddRestaurant from "./pages/Restaurant/AddRestaurant";
import InstituteUpload from "./pages/InstituteUpload";
import Vendors from "./pages/Vendor/Vendors";
import VendorInfo from "./pages/Vendor/VendorInfo";
import { VendorSettlement } from "./pages/Vendor/VendorSettlement";
import Dietitian from "./pages/Dietitian/Dietitian";
import Staff from "./pages/Staff/Staff";
import DietitianRequests from "./pages/Dietitian/DietitianRequests";
import ProtectedRoute from "./components/ProtectedRoute";
import Operations from "./pages/Operations";
import CustomerChat from "./pages/CustomerService/CustomerChat";
import UserDetails from "./pages/Users/UserDetails";
import CreditScorePage from "./pages/CreditScore/CreditScorePage";
import CreditDetails from "./pages/CreditScore/CreditDetails";
import PendingEmandates from "./pages/Emandate/PendingEmandate";
import Index from "./pages/Packages/Index";
import ReferralIndex from "./pages/Referrals/ReferralIndex";
import OrderHistory from "./pages/Orders/OrderHistory";
import Analytics from "./pages/Dashboard/Analytics";
import Notification from "./pages/Notification";
import AllCanceledMandates from "./pages/Emandate/AllCanceledMandates";
import ReferrerDetails from "./pages/Referrals/ReferrerDetails";
import IncompleteUsers from "./pages/IncompleteUsers/IncompleteUsers";
import PushNotication from "./pages/PushNotication";
import WebVisitors from "./pages/WebVisitors/Index";


function App() {
  return (
    <>
      <Toaster position="top-right" />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/admin-signup" element={<AdminSignup />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <BasePage />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="analytics"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />
            <Route
              path="push-notification"
              element={
                <ProtectedRoute>
                  <PushNotication />
                </ProtectedRoute>
              }
            />
            <Route
              path="notifications"
              element={
                <ProtectedRoute>
                  <Notification />
                </ProtectedRoute>
              }
            />
            <Route
              path="web-visitors"
              element={
                <ProtectedRoute>
                  <WebVisitors />
                </ProtectedRoute>
              }
            />
            <Route
              path="users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="user-details"
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="incomplete-users"
              element={
                <ProtectedRoute>
                  <IncompleteUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="virtual-account"
              element={
                <ProtectedRoute>
                  <VirtualAccount />
                </ProtectedRoute>
              }
            />
            <Route path="order-requests" element={<Order />} />
            <Route path="order-history" element={<OrderHistory />} />
            <Route
              path="employed-verification1"
              element={
                <ProtectedRoute>
                  <StageOneVerification />
                </ProtectedRoute>
              }
            />
            <Route
              path="self-employed-verification1"
              element={
                <ProtectedRoute>
                  <SelfEmployedVerificationStage1 />
                </ProtectedRoute>
              }
            />
            <Route
              path="student-verification1"
              element={
                <ProtectedRoute>
                  <StudentVerification />
                </ProtectedRoute>
              }
            />
            <Route
              path="institute-upload"
              element={
                <ProtectedRoute>
                  <InstituteUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="store"
              element={
                <ProtectedRoute>
                  <Store />
                </ProtectedRoute>
              }
            />
            <Route
              path="credit-score"
              element={
                <ProtectedRoute>
                  <CreditScorePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="credit-score/credit-details"
              element={
                <ProtectedRoute>
                  <CreditDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="customer-care"
              element={
                <ProtectedRoute>
                  <CustomerCare />
                </ProtectedRoute>
              }
            />
            <Route
              path="customer-care/customer-chats"
              element={
                <ProtectedRoute>
                  <CustomerChat />
                </ProtectedRoute>
              }
            />
            <Route
              path="dietitians"
              element={
                <ProtectedRoute>
                  <Dietitian />
                </ProtectedRoute>
              }
            />
            <Route
              path="dietitians/dietitian-requests"
              element={<DietitianRequests />}
            />
            <Route
              path="staff"
              element={
                <ProtectedRoute>
                  <Staff />
                </ProtectedRoute>
              }
            />
            <Route
              path="operations"
              element={
                <ProtectedRoute>
                  <Operations />
                </ProtectedRoute>
              }
            />
            <Route
              path="restaurants"
              element={
                <ProtectedRoute>
                  <Restaurants />
                </ProtectedRoute>
              }
            />
            <Route
              path="restaurants/restaurant-information"
              element={<RestaurantInfo />}
            />
            <Route
              path="restaurants/add-new-restaurant"
              element={<AddRestaurant />}
            />
            <Route path="restaurants/settlement" element={<Settlement />} />
            <Route
              path="logistics"
              element={
                <ProtectedRoute>
                  <Logistics />
                </ProtectedRoute>
              }
            />
            <Route
              path="logistics/rider-activity"
              element={<RiderActivity />}
            />
            <Route path="logistics/busy-riders" element={<BusyRiders />} />
            <Route
              path="logistics/assign-delivery"
              element={<AssignDelivery />}
            />
            <Route
              path="logistics/assigned-orders"
              element={<AssignedOrders />}
            />
            <Route
              path="logistics/all-deliveries"
              element={<AllDeliveries />}
            />
            <Route path="logistics/disbutes" element={<Disbutes />} />
            <Route
              path="e-mandate"
              element={
                <ProtectedRoute>
                  <Emandate />
                </ProtectedRoute>
              }
            />
            <Route
              path="pending-emandate"
              element={
                <ProtectedRoute>
                  <PendingEmandates />
                </ProtectedRoute>
              }
            />
            <Route
              path="all-canceled-mandates"
              element={
                <ProtectedRoute>
                  <AllCanceledMandates />
                </ProtectedRoute>
              }
            />
            <Route
              path="faq"
              element={
                <ProtectedRoute>
                  <Faq />
                </ProtectedRoute>
              }
            />
            <Route
              path="vendors"
              element={
                <ProtectedRoute>
                  <Vendors />
                </ProtectedRoute>
              }
            />
            <Route path="vendors/settlement" element={<VendorSettlement />} />
            <Route path="vendors/vendor-information" element={<VendorInfo />} />
            <Route
              path="assignment"
              element={
                <ProtectedRoute>
                  <Assignment />
                </ProtectedRoute>
              }
            />
            <Route
              path="document-check"
              element={
                <ProtectedRoute>
                  <DocumentCheck />
                </ProtectedRoute>
              }
            />
            <Route
              path="next-pay"
              element={
                <ProtectedRoute>
                  <NextPay />
                </ProtectedRoute>
              }
            />
            <Route
              path="defaulters"
              element={
                <ProtectedRoute>
                  <Defaulters />
                </ProtectedRoute>
              }
            />
            <Route
              path="news-update"
              element={
                <ProtectedRoute>
                  <NewsUpdate />
                </ProtectedRoute>
              }
            />
            <Route
              path="packages"
              element={
                <ProtectedRoute>
                  <Index />
                </ProtectedRoute>
              }
            />
            <Route
              path="referrals"
              element={
                <ProtectedRoute>
                  <ReferralIndex />
                </ProtectedRoute>
              }
            />
            <Route
              path="referrals/referrer-details"
              element={
                <ProtectedRoute>
                  <ReferrerDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="create-admin"
              element={
                <ProtectedRoute>
                  <CreateAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="repayment-history"
              element={
                <ProtectedRoute>
                  <Repayment />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
