import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { formatNumber, getAllRepaymentHistory } from "../redux/thunk";
import { IoSearchOutline } from "react-icons/io5";
import RepaymentModal from "../components/RepaymentModal";
import Pagination from "../components/Pagination";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import axios from "axios";

export const Repayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [repaymentDetails, setRepaymentDetails] = useState({});
  const { token } = useAppSelector((store) => store.auth);
  const [stats, setStats] = useState<any>({});

  const dispatch = useAppDispatch();

  const getDashboardStats = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getDashboardStatistic`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setStats(response.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setStats({});
    }
  };

  const handleViewDetails = (details: any) => {
    setRepaymentDetails(details);
    setShowDetails((prev) => !prev);
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllRepaymentHistory()).then((response: any) => {
      setData(response.payload);
      setIsLoading(false);
    });
  }, [dispatch]);

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        data?.filter((user: any) =>
          user.userId?.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = data.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <main className=" w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <div className="flex justify-between w-full">
              <h1 className="text-base font-semibold ">Repayment History</h1>
            </div>
            <div className="relative md:w-[30rem] w-fit">
              <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
              <input
                type="search"
                name="searchedUser"
                id="searchedUser"
                value={searchedUser}
                onChange={(e) => searchForUsersWithEmail(e.target.value)}
                placeholder="Search user using email"
                className="border p-2 rounded-md indent-7 w-full"
                disabled={data.length === 0}
              />
            </div>
          </div>
          <section className="flex my-3 justify-evenly flex-wrap gap-3">
            <div
              className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
                isLoading && "animate-pulse "
              }`}
            >
              <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
                Total - Total Loans
              </h2>
              <p className="font-semibold text-3xl">
                {formatNumber(Math.round(
                  Object.keys(stats).length > 0
                    ? stats.totalLoans.overall.toFixed(2)
                    : "0"
                ))}
              </p>
            </div>
            <div
              className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
                isLoading && "animate-pulse "
              }`}
            >
              <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
                Total - Paid Loans
              </h2>
              <p className="font-semibold text-3xl">
                {formatNumber(Math.round(
                  Object.keys(stats).length > 0
                    ? stats.totalPaidLoans.overall.toFixed(2)
                    : "0"
                ))}
              </p>
            </div>
            <div
              className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
                isLoading && "animate-pulse "
              }`}
            >
              <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
                Total - Amount left
              </h2>
              <p className="font-semibold text-3xl">
                {formatNumber(
                  Math.round(
                    (Object.keys(stats).length > 0
                      ? stats.totalLoans.overall.toFixed(2)
                      : "0") -
                      Math.round(
                        Object.keys(stats).length > 0
                          ? stats.totalPaidLoans.overall.toFixed(2)
                          : "0"
                      )
                  )
                )}
              </p>
            </div>
          </section>
          <section className="overflow-x-auto">
            <table className="md:w-[1350px] w-[700px] my-3 text-nowrap">
              <thead className="bg-gray-50 font-bold text-left p-4">
                <tr>
                  <th className="p-2 ">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2 text-nowrap">Phone number</th>
                  <th className="p-2 text-nowrap">Contact</th>
                  <th className="p-2 text-nowrap">Email address</th>
                  <th className="p-2"></th>
                </tr>
              </thead>
              <tbody className="px-4">
                {searchedUser.length > 0 ? (
                  searchResults && searchResults.length > 0 ? (
                    searchResults.map((user: any, index: number) => (
                      <tr className="border-b border-gray-300" key={index}>
                        <td className="py-4 px-2 text-secondary p-2">
                          {index + 1}
                        </td>
                        <td className="py-4 px-2 text-nowrap">
                          {user.userId?.lastName +
                            " " +
                            user.userId?.firstName || "not found"}
                        </td>
                        <td className="py-4 px-2">
                          {user.userId?.phoneNumber || "not found"}
                        </td>
                        <td className="px-2 py-4 text-nowrap flex gap-4">
                          <button type="button" title="call customer">
                            <a href={`tel:${user.userId?.phoneNumber}`}>
                              <FaPhone />
                            </a>
                          </button>
                          <button type="button" title="text via whatsapp">
                            <a
                              href={`https://wa.me/${user.userId?.phoneNumber?.replace(
                                /^0/,
                                "234"
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaWhatsapp />
                            </a>
                          </button>
                        </td>

                        <td className="py-4 px-2">
                          {user.userId?.email || "not found"}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="bg-secondary text-white rounded-md  p-2 text-sm"
                            onClick={() => handleViewDetails(user.loanRecord)}
                          >
                            View details
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        className="text-center text-secondary p-3"
                      >
                        User with email not found
                      </td>
                    </tr>
                  )
                ) : Array.isArray(data) && data.length > 0 ? (
                  currentItems.map((user: any, index: any) => (
                    <tr className="border-b border-gray-300" key={index}>
                      <td className="py-4 px-2 text-secondary p-2">
                        {index + indexOfFirstPost + 1}
                      </td>
                      <td className="py-4 px-2 text-nowrap">
                        {user.userId?.lastName + " " + user.userId?.firstName ||
                          "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {user.userId?.phoneNumber || "not found"}
                      </td>
                      <td className="p-2 text-nowrap flex gap-4">
                        <button type="button" title="call customer">
                          <a href={`tel:${user.userId?.phoneNumber}`}>
                            <FaPhone />
                          </a>
                        </button>
                        <button type="button" title="text via whatsapp">
                          <a
                            href={`https://wa.me/${user.userId?.phoneNumber?.replace(
                              /^0/,
                              "234"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaWhatsapp />
                          </a>
                        </button>
                      </td>
                      <td className="py-4 px-2">
                        {user.userId?.email || "not found"}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="bg-secondary p-2 text-white rounded-md text-sm"
                          onClick={() => handleViewDetails(user.loanRecord)}
                        >
                          View details
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center p-2">
                      No repayment found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <section className="p-3 my-5">
              <Pagination
                length={
                  searchResults.length > 0
                    ? searchResults.length
                    : currentItems.length
                }
                itemsPerPage={itemsPerPage}
                handlePagination={handlePagination}
                currentPage={currentPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </section>
          </section>
        </div>
      </div>
      {showDetails && (
        <RepaymentModal
          repaymentDetails={repaymentDetails}
          closeModal={() => setShowDetails(false)}
        />
      )}
    </main>
  );
};
