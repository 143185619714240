import {
  Chart as ChartJs,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  ChartOptions,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { getDashboardStats } from "../../redux/thunk";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale
);

const BarChart = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const getStats = async () => {
      await getDashboardStats().then((res: any) => {
        setData(res);
      });
    };
    getStats();
  }, []);

  const barChartData = {
    labels: ["Disapproved", "Approved"],
    datasets: [
      {
        label: "Request approval",
        data: [
          Object.keys(data).length > 0 ? data.disapprovedOrders.overall : 0,
          Object.keys(data).length > 0 ? data.approvedOrders.overall : 0,
        ],
        backgroundColor: ["#92E3A9", "#7086FD"],
        borderWidth: 1,
      },
    ],
  };

  const barChartOptions: ChartOptions<"bar"> = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
        },
      },
    },
    scales: {
      x: {
        min: 0,
        max: 100,
        beginAtZero: true,
        ticks: {
          stepSize: 10,
        },
      },
      y: {
        type: "category",
      },
    },
  };

  return (
    <>
      <Bar options={barChartOptions} data={barChartData} />
    </>
  );
};

export default BarChart;
