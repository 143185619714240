import { useState, useEffect } from "react";
import axios from "axios";
import SelfEmployed from "../components/modals/SelfEmployed";
import BVNVerificationModal from "../components/modals/BVNVerificationModal";
import CreditLimitModal from "../components/modals/CreditLimitModal";
import Employed from "../components/modals/Employed";
import toast from "react-hot-toast";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import Student from "../components/modals/Student";
// import { AllBanksModal } from "../components/modals/AllBanksModal";
import { Preloader } from "../components/elements/Preloader";
import DisapproveModal from "../components/DisapproveModal";
import Pagination from "../components/Pagination";
import { IoSearchOutline } from "react-icons/io5";
import { FaPhone, FaRegCopy, FaWhatsapp } from "react-icons/fa6";

export const DocumentCheck = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [selfEmployedModalOpen, setSelfEmployedModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState<any>({});
  const [selectedInfo, setSelectedInfo] = useState<any>({});
  const [creditLimit, setCreditLimit] = useState("");
  const [action1, setAction1] = useState(false);
  const [disapproveMsg, setDisapproveMsg] = useState("");
  const [action2, setAction2] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [bankStatement, setBankStatement] = useState("");
  const [id, setId] = useState("");
  const [monoModal, setMonoModal] = useState(false);
  const [virtualAcctModal, setVirtualAcctModal] = useState(false);
  const [allAdmins, setAllAdmins] = useState<any>([]);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any>([]);

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = users.slice(indexOfFirstPost, indexOfLastPost);

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(users.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getAllUsers();
        await getAllAdmins();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/verification-stage-two`
      );

      setIsLoading(false);
      const data = res.data.customers.filter(
        (user: any) => user.accountType === "flexible"
      );
      setUsers(data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getAllAdmins = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-admins`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAllAdmins(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const approveCustomerHandler = async () => {
    const checkCreditLimit: any = users.filter((user: any) => user._id === id);
    if (!checkCreditLimit[0].isWallet) {
      toast.error("Set credit limit first!");
      return;
    }
    setApproveLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/approveStageTwoProcess/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status === 200) {
        setId("");
        setAction2("");
        toast.success(res.data.message);
        await getAllUsers();
        await getAllAdmins();
      }
    } catch (error) {
      console.error(error);
      setAction2("");
      toast.error("Error approving user, please try again later");
    } finally {
      setApproveLoading(false);
    }
  };

  const createVirtualAcct = async (id: string) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/create-virtual-account/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      await getAllUsers();
      setVirtualAcctModal(false);
      toast.success("Virtual account created");
    } catch (error) {
      console.error(error);
    }
  };

  const createMonoAcct = async (id: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/create-mono-account/${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      await getAllUsers();
      setMonoModal(false);
      toast.success("Mono account created");
    } catch (error) {
      console.error(error);
    }
  };

  const handleAction = (e: any) => {
    setAction2(e.target.value);
  };

  const handleClearSelection = (e: any) => {
    setAction2("");

    const selectElement = document.getElementById("select") as any;
    if (selectElement) {
      selectElement.selectedIndex = 0;
    }
  };

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleJobClick = (
    details: string,
    occupation: any,
    bankStatement: string
  ) => {
    setSelectedInfo(details);
    setBankStatement(bankStatement);
    if (occupation === "Self-Employed") {
      setSelfEmployedModalOpen(true);
    } else if (occupation === "Employed") {
      setEmployedModalOpen(true);
    } else {
      setStudentModalOpen(true);
    }
  };

  const handleDisapproveClick = (msg: string) => {
    setDisapproveMsg(msg);
    setShowMsg(true);
  };

  const handleConfirmation = async (e: any, userId: string) => {
    const payload = {
      documentCheck: true,
    };

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/update-document-check/${userId}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      await getAllUsers();
      toast.success(res.data.message || "Account confirmed!");
    } catch (error: any) {
      console.error(error);
      toast.error(error.message || "Confirmation failed");
    }
  };

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        users?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const handleCopyClick = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const data =
    searchedUser && searchedUser.length > 0 ? searchResults : currentItems;

  return (
    <main className="overflow-x-auto w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <h1 className="text-base font-semibold ">Document Check</h1>
            <div className="relative md:w-[30rem] w-fit">
              <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
              <input
                type="search"
                name="searchedUser"
                id="searchedUser"
                value={searchedUser}
                onChange={(e) => searchForUsersWithEmail(e.target.value)}
                placeholder="Search user using email"
                className="border p-2 rounded-md indent-7 w-full"
                disabled={users.length === 0}
              />
            </div>

            <label htmlFor="filterOccupation">
              Sort by:
              <select
                name="filterOccupation"
                id="filterOccupation"
                className="border p-2 ml-4"
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All</option>
                <option value="employed">Employed</option>
                <option value="self-employed">Self-employed</option>
                <option value="student">Students</option>
              </select>
            </label>
          </div>
          <section className="overflow-y-auto">
            <table className="w-full" style={{ minWidth: "1200px" }}>
              <thead className="bg-gray-50 text-left">
                <tr>
                  <th className="p-2">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2">Email</th>
                  <th className="p-2 text-nowrap">Phone Number</th>
                  <th className="p-2 text-nowrap">Contact</th>
                  <th className="p-2">BVN</th>
                  <th className="p-2">Occupation</th>
                  <th className="p-2">Admin Officer</th>
                  <th className="p-2 text-nowrap">Action 1</th>
                  <th className="p-2 text-nowrap">Action 2 MA</th>
                  <th className="p-2 text-nowrap">Action 3 VA</th>
                  <th className="p-2 text-nowrap">Action 4</th>
                  <th className="p-2">Confirmation</th>
                  <th className="p-2">Bank Statement</th>
                  <th className="p-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {users.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={9} className="p-10 text-secondary">
                      No pending verification!
                    </td>
                  </tr>
                ) : data && data.length ? (
                  data
                    .filter((user: any) => {
                      if (filter === "employed") {
                        return user.employmentStatus === "employed";
                      } else if (filter === "self-employed") {
                        return user.employmentStatus === "selfEmployed";
                      } else if (filter === "student") {
                        return user.employmentStatus === "student";
                      } else if (filter === "all") {
                        return user.employmentStatus;
                      }
                      return true;
                    })
                    .slice()
                    .reverse()
                    .map((filteredUser: any, index: number) => {
                      const filteredJob =
                        filteredUser.employmentStatus === "employed"
                          ? "Employed"
                          : filteredUser.employmentStatus === "selfEmployed"
                          ? "Self-Employed"
                          : filteredUser.employmentStatus === "student"
                          ? "Student"
                          : null;

                      const filteredJobObject =
                        filteredUser.employmentStatus === "employed"
                          ? filteredUser.employed
                          : filteredUser.employmentStatus === "selfEmployed"
                          ? filteredUser.selfEmployed
                          : filteredUser.employmentStatus === "student"
                          ? filteredUser.student
                          : null;
                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-secondary p-2">
                            {index + indexOfFirstPost + 1}
                          </td>
                          <td className="p-2 text-nowrap">
                            {filteredUser.lastName} {filteredUser.firstName}
                          </td>
                          <td className="p-2">{filteredUser.email}</td>
                          <td className="p-2">{filteredUser.phoneNumber}</td>
                          <td className="p-2 ">
                            <button
                              type="button"
                              className="mx-2"
                              title="call customer"
                            >
                              <a href={`tel:${filteredUser?.phoneNumber}`}>
                                <FaPhone />
                              </a>
                            </button>
                            <button type="button" title="text via whatsapp">
                              <a
                                href={`https://wa.me/${filteredUser?.phoneNumber?.replace(
                                  /^0/,
                                  "234"
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaWhatsapp />
                              </a>
                            </button>
                          </td>

                          <td className="p-2 flex items-end gap-2 ">
                            <button
                              onClick={() => handleBvnClick(filteredUser)}
                              className="text-blue-500 hover:underline focus:outline-none"
                            >
                              {filteredUser.bvn}
                            </button>
                            <button
                              type="button"
                              className=""
                              onClick={() => handleCopyClick(filteredUser.bvn)}
                            >
                              <FaRegCopy className="w-5 h-5 text-gray-500" />
                            </button>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={() =>
                                handleJobClick(
                                  filteredJobObject,
                                  filteredJob,
                                  filteredUser.bankStatement
                                )
                              }
                              className="text-blue-500 hover:underline text-nowrap focus:outline-none"
                            >
                              {filteredJob}
                            </button>
                          </td>
                          <td className="p-2 text-nowrap">
                            {(() => {
                              const admin = allAdmins?.find(
                                (item: any) =>
                                  item.id === filteredUser.adminOfficer
                              );

                              return (
                                <span>
                                  {admin
                                    ? `${admin.lastName} ${admin.firstName}`
                                    : "-- --"}
                                </span>
                              );
                            })()}
                          </td>
                          <td className="p-2 text-nowrap text-secondary">
                            {filteredUser.isWallet ? (
                              "Done"
                            ) : (
                              <button
                                onClick={() => {
                                  setAction1((prev) => !prev);
                                  setId(filteredUser._id);
                                }}
                                className="text-blue-500 hover:underline focus:outline-none"
                              >
                                Set Credit Limit
                              </button>
                            )}
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className={`p-2 border text-sm rounded-md ${
                                !filteredUser.isWallet
                                  ? "bg-gray-300 text-black"
                                  : "bg-secondary text-white"
                              }`}
                              onClick={() => {
                                setId(filteredUser._id);
                                setMonoModal((prev) => !prev);
                              }}
                              disabled={
                                (!filteredUser.isWallet &&
                                  !filteredUser.creditLimit) ||
                                Object.keys(filteredUser.monoAccount).length !==
                                  0
                              }
                              title={
                                !filteredUser.isWallet &&
                                !filteredUser.creditLimit
                                  ? "set credit limit first"
                                  : ""
                              }
                            >
                              {Object.keys(filteredUser.monoAccount).length !==
                              0
                                ? "Done"
                                : "Create"}
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className={`p-2 border text-sm rounded-md ${
                                Object.keys(filteredUser.monoAccount).length ===
                                0
                                  ? "bg-gray-300 text-black"
                                  : "bg-secondary text-white"
                              }`}
                              onClick={() => {
                                setId(filteredUser._id);
                                setVirtualAcctModal((prev) => !prev);
                              }}
                              disabled={
                                Object.keys(filteredUser.monoAccount).length ===
                                  0 || filteredUser.isPaystackIdentified
                              }
                              title={
                                Object.keys(filteredUser.monoAccount).length ===
                                0
                                  ? "create mono account first"
                                  : ""
                              }
                            >
                              {filteredUser.isPaystackIdentified
                                ? "Done"
                                : "Create"}
                            </button>
                          </td>
                          <td className="p-2">
                            <select
                              name="select"
                              id="select"
                              className="cursor-pointer"
                              onChange={(e) => {
                                handleAction(e);
                                setId(filteredUser._id);
                              }}
                              disabled={
                                !filteredUser.virtualAccount.accountName
                              }
                              title={
                                !filteredUser.virtualAccount.accountName
                                  ? "create virtual account first"
                                  : ""
                              }
                            >
                              <option value="">Select an action</option>
                              <option value="approve">Approve</option>
                              <option value="disapprove">Disapprove</option>
                            </select>
                          </td>

                          <td className="p-2 text-center">
                            <input
                              type="checkbox"
                              name="confirmation"
                              id="confirmation"
                              checked={filteredUser.documentCheck}
                              disabled={filteredUser.documentCheck}
                              onChange={(e) => {
                                handleConfirmation(e, filteredUser._id);
                              }}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <span className="p-2 border text-sm bg-secondary text-white rounded-md">
                              <a
                                href={filteredUser.bankStatement}
                                target="blank"
                              >
                                View
                              </a>
                            </span>
                          </td>
                          <td className="p-2">
                            <button
                              onClick={
                                filteredUser.msg
                                  ? () =>
                                      handleDisapproveClick(filteredUser.msg)
                                  : undefined
                              }
                              className={
                                filteredUser.adminVerification === "dstage2"
                                  ? "text-red-600"
                                  : "text-yellow-400"
                              }
                            >
                              {filteredUser.adminVerification === "dstage2"
                                ? "Disapproved"
                                : "Pending"}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={14} className="text-center p-3 text-secondary">
                      {searchedUser.length > 0 ? "User not found" : "Not found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
        <section className="p-3 my-5">
          <Pagination
            length={
              searchedUser.length > 0 ? searchResults.length : users.length
            }
            itemsPerPage={itemsPerPage}
            handlePagination={handlePagination}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </section>
      </div>
      {showMsg && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setShowMsg(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>{disapproveMsg}</p>
              <div>
                <button
                  type="button"
                  onClick={() => setShowMsg(false)}
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {action2 === "approve" && (
        <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={handleClearSelection}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p>Are you sure you want approve this user?</p>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={approveCustomerHandler}
                >
                  {approveLoading ? <Preloader /> : "Yes"}
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={handleClearSelection}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {monoModal && (
        <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setMonoModal(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p className="text-center">
                Are you sure you want create a Mono Account for this user?
              </p>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={() => createMonoAcct(id)}
                >
                  {approveLoading ? <Preloader /> : "Yes"}
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={() => setMonoModal(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {virtualAcctModal && (
        <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between flex-col gap-5 items-center  text-dark p-2 rounded-t-md mb-4">
              <div className="relative w-full">
                <button
                  onClick={() => setVirtualAcctModal(false)}
                  className="text-dark absolute right-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <p className="text-center">
                Are you sure you want to create a Virtual Account for this user?
              </p>
              <div>
                <button
                  type="button"
                  className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
                  onClick={() => createVirtualAcct(id)}
                >
                  {approveLoading ? <Preloader /> : "Yes"}
                </button>
                <button
                  type="button"
                  className="w-20 px-5 py-3 bg-red-500 rounded text-white "
                  onClick={() => setVirtualAcctModal(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {action2 === "disapprove" && (
        <DisapproveModal
          handleClearSelection={handleClearSelection}
          id={id}
          setId={setId}
          setActionSelected={setAction2}
          stage={"documentCheck"}
          getAllUsers={getAllUsers}
        />
      )}

      {action1 && (
        <CreditLimitModal
          id={id}
          setId={setId}
          setAction1={setAction1}
          creditLimit={creditLimit}
          setCreditLimit={setCreditLimit}
          getAllUsers={getAllUsers}
          getAllAdmins={getAllAdmins}
        />
      )}
      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}

      {/* {allBanksModal && (
        <AllBanksModal
          setAllBanksModal={setAllBanksModal}
          setAllBanksDetails={setAllBanksDetails}
          allBanksDetails={allBanksDetails}
          userId={userId}
          getAllUsers={getAllUsers}
          documentCheckPage={documentCheckPage}
          setDocumentCheckPage={setDocumentCheckPage}
        />
      )} */}

      {studentModalOpen && (
        <Student
          bankStatement={bankStatement}
          selectedInfo={selectedInfo}
          setStudentModalOpen={setStudentModalOpen}
        />
      )}
      {selfEmployedModalOpen && (
        <SelfEmployed
          bankStatement={bankStatement}
          setSelfEmployedModalOpen={setSelfEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {employedModalOpen && (
        <Employed
          bankStatement={bankStatement}
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
    </main>
  );
};
