import { MdOutlineCancel } from "react-icons/md";
import Modal, { ModalContent } from "../elements/Modal";

const PartnershipModal = ({ closeModal, details }: any) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      className="flex items-center justify-center"
    >
      <ModalContent className="mx-3 p-6 rounded-md shadow-lg flex flex-col justify-center items-center bg-white w-[80%] md:w-[80%] lg:w-[60%] xl:w-[50%] ">
        <section className="w-full p-2">
          <div className="flex justify-between">
            <h3 className="font-bold text-lg">Partnership Details</h3>
            <MdOutlineCancel
              className="text-2xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
          <section className="mt-2 text-sm">
            <div className="flex justify-between items-center gap-5 py-2">
              <p className="font-semibold">Date:</p>
              <p>
                {new Date(details.createdAt).toLocaleDateString("en-us", {
                  day: "numeric",
                  month: "2-digit",
                  year: "2-digit",
                })}
              </p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p className="font-semibold">Company Name:</p>
              <p>{details.name}</p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p className="font-semibold">Contact person:</p>
              <p>{details.contactPerson}</p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p className="font-semibold">Company email:</p>
              <p>{details.email}</p>
            </div>
            <div className="flex justify-between items-center gap-5 py-2">
              <p className="font-semibold">Phone number:</p>
              <p>{details.phoneNo}</p>
            </div>
            <div className="py-2">
              <p className="mb-2 font-semibold">Food Product / service:</p>
              <p className="border p-2">{details.foodProduct}</p>
            </div>
            {details.whyPartnerWithUs && (
              <div className="py-2">
                <p className="mb-2 font-semibold">Why partner with us:</p>
                <p className="border p-2">{details.whyPartnerWithUs}</p>
              </div>
            )}
            {details.additionalInformation && (
              <div className="py-2">
                <p className="mb-2 font-semibold">Additional information:</p>
                <p className="border p-2">{details.additionalInformation}</p>
              </div>
            )}
          </section>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default PartnershipModal;
