import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { formatNumber } from "../../redux/thunk";
import { useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination";

const ReferrerDetails = () => {
  const [filter, setFilter] = useState("");
  const [isActive, setIsActive] = useState("newest");
  const [isLoading] = useState(false);
  const [searchedUser, setSearchedUser] = useState<any>([]);
  const [searchResults, setSearchResults] = useState([]);

  const location = useLocation();
  const referralCode = location?.state?.referralCode;
  const customerId = location?.state?.customerId;
  const referredCustomersDetails = location?.state?.referredCustomersDetails;
  const totalReferred = location?.state?.totalReferred;
  const totalComplete = location?.state?.totalComplete;
  const totalPending = location?.state?.totalPending;

  const searchForUsersWithEmail = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const searchResult =
        referredCustomersDetails?.filter((user: any) =>
          user.email?.toLowerCase().includes(value.toLowerCase())
        ) || null;
      setSearchResults(searchResult);
    } else {
      setSearchResults([]);
    }
  };

  const [itemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = referredCustomersDetails.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  // Pagination
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (
      currentPage !== Math.ceil(referredCustomersDetails.length / itemsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const data =
    searchedUser && searchedUser.length > 0 ? searchResults : currentItems;

  return (
    <section>
      <section className="flex my-5 flex-wrap gap-8">
        <div
          className={`bg-white p-3 rounded-md shadow-md flex items-center gap-3 ${
            isLoading && "animate-pulse "
          }`}
        >
          <figure className="w-14 h-14 rounded-[50%]">
            <img
              src="/assets/Avatar.png"
              className="w-full rounded-[50%]"
              alt={customerId}
            />
          </figure>
          <div>
            <p className="font-bold pb-2 text-gray-700 caption-bottom">
              {customerId}
            </p>
            <h3 className="font-semibold text-sm text-[#8F7803]">
              Referral Code: {referralCode}
            </h3>
          </div>
        </div>
        <div
          className={`bg-white p-3 rounded-md shadow-md w-48 ${
            isLoading && "animate-pulse "
          }`}
        >
          <p className="font-medium pb-2 text-gray-700 caption-bottom">
            {formatNumber(totalReferred)}
          </p>
          <h3 className="font-semibold text-sm text-[#8F7803]">
            Total Referrals
          </h3>
        </div>
        <div
          className={`bg-white p-3 rounded-md shadow-md w-48 ${
            isLoading && "animate-pulse "
          }`}
        >
          <p className="font-medium pb-2 text-gray-700 caption-bottom">
            {formatNumber(totalComplete)}
          </p>
          <h3 className="font-semibold text-sm text-secondary">
            Complete Referrals
          </h3>
        </div>
        <div
          className={`bg-white p-3 rounded-md shadow-md w-48 ${
            isLoading && "animate-pulse "
          }`}
        >
          <p className="font-medium pb-2 text-gray-700 caption-bottom">
            {formatNumber(totalPending)}
          </p>
          <h3 className="font-semibold text-sm text-[#F59040]">
            Pending Referrals
          </h3>
        </div>
      </section>

      <section className="my-10">
        <h3 className="font-bold text-lg">Referrals</h3>
        <section className="flex justify-between items-center pb-4">
          <h3 className="text-secondary text-sm">Activities</h3>
          <div className="flex items-center gap-5">
            <div className="relative">
              <IoSearchOutline className="w-5 h-5 absolute top-[0.5rem] left-1 text-gray-300 text-sm" />
              <input
                type="search"
                name="search"
                id="search"
                onChange={(e) => searchForUsersWithEmail(e.target.value)}
                className="p-2 indent-5 text-xs bg-[#F9FBFF]"
                placeholder="Search by email"
              />
            </div>
            <label htmlFor="filter" className="text-xs px-2 bg-[#F9FBFF]">
              Sort by:
              <select
                name="filter"
                id="filter"
                className={`py-2 px-1 font-semibold mx-4 text-xs ${
                  isActive === "newest" && "bg-[#F9FBFF]"
                }`}
                onClick={() => setIsActive("newest")}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="newest">newest</option>
                <option value="oldest">oldest</option>
              </select>
            </label>
          </div>
        </section>
        <table className="w-full">
          <thead>
            <tr className="text-[#B5B7C0] text-sm border-b">
              <th className="p-3">SN</th>
              <th className="p-3">User name</th>
              <th className="p-3">Email address</th>
              <th className="p-3">Status</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item: any, index: number) => (
                <tr key={index} className="text-center">
                  <td className="p-3 text-sm border-b">
                    {String(index + 1).padStart(2, "0")}
                  </td>
                  <td className="p-3 text-sm border-b">
                    {item.firstName && item.lastName
                      ? `${item.firstName} ${item.lastName}`
                      : "-- --"}
                  </td>
                  <td className="p-3 text-sm border-b">
                    {item.email ? item.email : "-- --"}
                  </td>
                  <td className="p-2 border-b">
                    <div
                      className={`p-1 mx-auto w-40 text-sm font-medium ${
                        item.orderStatus === "Order"
                          ? "text-secondary border border-secondary bg-[#16C0981A]"
                          : item.status === "Not Order"
                          ? "text-[#DF0404] border border-[#DF0404] bg-[#FFC5C540]"
                          : "text-[#F59040] border border-[#F59040] bg-[#F5904073]"
                      }`}
                    >
                      {item.orderStatus}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="p-3 text-center text-secondary">
                  {searchedUser.length > 0
                    ? "Referred user not found"
                    : "Nothing to see here"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section className="p-3 my-5">
        <Pagination
          length={
            searchedUser.length > 0
              ? searchResults.length
              : referredCustomersDetails.length
          }
          itemsPerPage={itemsPerPage}
          handlePagination={handlePagination}
          currentPage={currentPage}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </section>
    </section>
  );
};

export default ReferrerDetails;
