import axios from "axios";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { IoAdd, IoSearchOutline } from "react-icons/io5";
import { AllBanksModal } from "../../components/modals/AllBanksModal";
import { AddBankModal } from "../../components/modals/AddBankModal";
import BVNVerificationModal from "../../components/modals/BVNVerificationModal";
import SelfEmployed from "../../components/modals/SelfEmployed";
import Employed from "../../components/modals/Employed";
import Student from "../../components/modals/Student";
import Pagination from "../../components/Pagination";
import toast from "react-hot-toast";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import UpdateCreditLimit from "../../components/modals/UpdateCreditLimit";

const UserDetails = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [bvnModalOpen, setBvnModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedBvn, setSelectedBvn] = useState("");
  const [selectedInfo, setSelectedInfo] = useState<any>({});
  const [customers, setCustomers] = useState([]);
  const [outright, setOutright] = useState([]);
  const [activeTab, setActiveTab] = useState("flexible");
  const [isLoading, setIsLoading] = useState<{
    main: boolean;
    delete: boolean;
  }>({
    main: false,
    delete: false,
  });
  const [isFlexibleLoading, setIsFlexibleLoading] = useState(false);
  const [selfEmployedModalOpen, setSelfEmployedModalOpen] = useState(false);
  const [allBanksModal, setAllBanksModal] = useState(false);
  const [allBanksDetails, setAllBanksDetails] = useState([]);
  const [employedModalOpen, setEmployedModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [addBankModal, setAddBankModal] = useState(false);
  const [userToAddBank, setUserToAddBank] = useState({});
  const [searchedUser, setSearchedUser] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [userData, setUserData] = useState([]);
  const [viewUpdateCreditLimit, setViewUpdateCreditLimit] = useState(false);

  const [itemsPerPage] = useState(20);
  const [flexibleCurrentPage, setFlexibleCurrentPage] = useState(1);
  const [outrightCurrentPage, setOutrightCurrentPage] = useState(1);
  const flexibleIndexOfLastPost = flexibleCurrentPage * itemsPerPage;
  const outrightIndexOfLastPost = outrightCurrentPage * itemsPerPage;
  const flexibleIndexOfFirstPost = flexibleIndexOfLastPost - itemsPerPage;
  const outrightIndexOfFirstPost = outrightIndexOfLastPost - itemsPerPage;
  const flexibleCurrentItems = customers.slice(
    flexibleIndexOfFirstPost,
    flexibleIndexOfLastPost
  );
  const outrightCurrentItems = outright.slice(
    outrightIndexOfFirstPost,
    outrightIndexOfLastPost
  );

  // Pagination
  const handlePaginationFlexible = (pageNumber: number) => {
    setFlexibleCurrentPage(pageNumber);
  };
  const handlePaginationOutright = (pageNumber: number) => {
    setOutrightCurrentPage(pageNumber);
  };

  const prevPageFlexible = () => {
    if (flexibleCurrentPage !== 1) {
      setFlexibleCurrentPage(flexibleCurrentPage - 1);
    }
  };

  const nextPageFlexible = () => {
    if (flexibleCurrentPage !== Math.ceil(customers.length / itemsPerPage)) {
      setFlexibleCurrentPage(flexibleCurrentPage + 1);
    }
  };
  const prevPageOutright = () => {
    if (outrightCurrentPage !== 1) {
      setOutrightCurrentPage(outrightCurrentPage - 1);
    }
  };

  const nextPageOutright = () => {
    if (outrightCurrentPage !== Math.ceil(outright.length / itemsPerPage)) {
      setOutrightCurrentPage(outrightCurrentPage + 1);
    }
  };

  useEffect(() => {
    getFlexibleCustomers();
    getOutrightCustomers();
  }, []);

  const handleBvnClick = (bvn: string) => {
    setSelectedBvn(bvn);
    setBvnModalOpen(true);
  };

  const handleViewAllBank = (id: string, linkedBanks: any) => {
    setUserId(id);
    setAllBanksDetails(linkedBanks);
    setAllBanksModal((prev) => !prev);
  };

  const handleUpdateCreditLimit = (data: any) => {
    setUserData(data);
    setViewUpdateCreditLimit((prev) => !prev);
  };

  const handleJobClick = (details: string, occupation: any) => {
    setSelectedInfo(details);
    if (occupation === "Self-Employed") {
      setSelfEmployedModalOpen(true);
    } else if (occupation === "Employed") {
      setEmployedModalOpen(true);
    } else {
      setStudentModalOpen(true);
    }
  };

  const getFlexibleCustomers = async () => {
    setIsLoading((prev) => ({ ...prev, main: true }));
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/getapprovedCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsLoading((prev) => ({ ...prev, main: false }));
      const data = res.data.data;
      setCustomers(data.slice().reverse());
    } catch (error) {
      console.error(error);
      setCustomers([]);
      setIsLoading((prev) => ({ ...prev, main: false }));
    }
  };

  const getOutrightCustomers = async () => {
    setIsFlexibleLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/getOutrightCustomers`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsFlexibleLoading(false);
      const data = res.data;
      setOutright(data.slice().reverse());
    } catch (error) {
      console.error(error);
      setIsFlexibleLoading(false);
      setOutright([]);
    }
  };

  const handleAddBank = (firstName: any, lastName: any, id: string) => {
    setUserToAddBank({ name: lastName + " " + firstName, id });
    setAddBankModal((prev) => !prev);
  };

  const searchForUsers = (value: string) => {
    setSearchedUser(value);
    if (value.length > 0) {
      const isPhoneNumber = /^\d+$/.test(value);
      const searchResult =
        activeTab === "flexible"
          ? customers?.filter((user: any) =>
              isPhoneNumber
                ? user.phoneNumber?.toLowerCase().includes(value.toLowerCase())
                : user.email?.toLowerCase().includes(value.toLowerCase())
            ) || []
          : outright?.filter((user: any) =>
              isPhoneNumber
                ? user.phoneNumber?.toLowerCase().includes(value.toLowerCase())
                : user.email?.toLowerCase().includes(value.toLowerCase())
            ) || [];
      setSearchResults(searchResult);
      searchResult.length === 0 && toast.error("User not found");
    } else {
      setSearchResults([]);
    }
  };

  return (
    <main className="overflow-x-auto w-full">
      <div className="flex gap-10 mb-6">
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "flexible" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("flexible")}
        >
          Flexible Customers
        </span>
        <span
          className={`cursor-pointer pb-2 ${
            activeTab === "outright" ? "border-b-2 border-green-500" : "null"
          }`}
          onClick={() => setActiveTab("outright")}
        >
          Outright Customers
        </span>
      </div>
      {activeTab === "flexible" ? (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div
            className={`w-full overflow-x-auto ${
              isLoading.main && "animate-pulse h-[50vh]"
            }`}
          >
            <div
              className="flex items-center justify-between p-6"
              style={{ minWidth: "700px" }}
            >
              <div className="flex justify-between w-full">
                <h1 className="text-base font-semibold ">User Details</h1>
                <div className="relative md:w-[30rem] w-fit">
                  <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
                  <input
                    type="search"
                    name="searchedUser"
                    id="searchedUser"
                    value={searchedUser}
                    onChange={(e) => searchForUsers(e.target.value)}
                    placeholder="Search with email or phone number"
                    className="border p-2 text-sm rounded-md indent-7 w-full"
                  />
                </div>
                <label htmlFor="filter">
                  Sort:
                  <select
                    name="filter"
                    id="filter"
                    className="border p-2 ml-4"
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="employed">Employed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Students</option>
                  </select>
                </label>
              </div>
            </div>
            <section className="overflow-x-auto">
              <table className="w-[1200px]" style={{ minWidth: "700px" }}>
                <thead className="bg-gray-50 font-bold p-4 text-left">
                  {filter === "student" && (
                    <tr>
                      <th className="p-2">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2 text-nowrap">Contact</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Action</th>
                    </tr>
                  )}
                  {filter === "employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2 text-nowrap">Contact</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Action</th>
                    </tr>
                  )}
                  {filter === "all" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2 text-nowrap">Contact</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Action</th>
                    </tr>
                  )}
                  {filter === "self-employed" && (
                    <tr>
                      <th className="p-2 ">S/N</th>
                      <th className="p-2 text-nowrap">Name</th>
                      <th className="p-2 text-nowrap">Email</th>
                      <th className="p-2 text-nowrap">Phone Number</th>
                      <th className="p-2 text-nowrap">Contact</th>
                      <th className="p-2 text-nowrap">Add Bank</th>
                      <th className="p-2 text-nowrap">All Banks</th>
                      <th className="p-2 text-nowrap">Credit Score(Balance)</th>
                      <th className="p-2 text-nowrap">Credit Score</th>
                      <th className="p-2 text-nowrap">Action</th>
                    </tr>
                  )}
                </thead>
                <tbody className="px-4">
                  {searchResults.length > 0 && activeTab === "flexible"
                    ? searchResults.map((data: any, index) => (
                        <tr
                          className="border-b border-gray-300 py-2"
                          key={index}
                        >
                          <td className="text-secondary p-2">
                            {index + flexibleIndexOfFirstPost + 1}
                          </td>
                          <td className="p-2 text-nowrap capitalize">
                            {data.lastName.toLowerCase()}{" "}
                            {data.firstName.toLowerCase()}
                          </td>
                          <td className="p-2">{data.email}</td>
                          <td className="p-2 text-nowrap">
                            {data?.phoneNumber || "No number"}
                          </td>
                          <td className="p-2">
                            <button
                              className="mr-3"
                              type="button"
                              title="call customer"
                            >
                              <a href={`tel:${data?.phoneNumber}`}>
                                <FaPhone />
                              </a>
                            </button>
                            <button type="button" title="text via whatsapp">
                              <a
                                href={`https://wa.me/${data?.phoneNumber?.replace(
                                  /^0/,
                                  "234"
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaWhatsapp />
                              </a>
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className="text-secondary border"
                              onClick={() =>
                                handleAddBank(
                                  data.firstName,
                                  data.lastName,
                                  data._id
                                )
                              }
                            >
                              <IoAdd className="w-9 h-9" />
                            </button>
                          </td>
                          <td className="p-2 text-center">
                            <button
                              className="bg-secondary text-white text-sm rounded-md p-2"
                              onClick={() =>
                                handleViewAllBank(data._id, data.linkedBanks)
                              }
                            >
                              View
                            </button>
                          </td>
                          <td className="p-2">
                            ₦{data.creditScore.toLocaleString()}
                          </td>
                          <td className="p-2">
                            ₦{data.creditLimit.toLocaleString()}
                          </td>
                          <td className="p-2 text-nowrap">
                            <button
                              type="button"
                              className="p-2 text-sm text-secondary border border-secondary"
                              onClick={() => handleUpdateCreditLimit(data)}
                            >
                              Update Credit Limit
                            </button>
                          </td>
                        </tr>
                      ))
                    : flexibleCurrentItems
                        .filter((customer: any) => {
                          if (filter === "employed") {
                            return customer.employmentStatus === "employed";
                          } else if (filter === "self-employed") {
                            return customer.employmentStatus === "selfEmployed";
                          } else if (filter === "student") {
                            return customer.employmentStatus === "student";
                          } else if (filter === "all") {
                            return customer.employmentStatus;
                          }
                          return true;
                        })
                        .map((data: any, index) => {
                          const filteredJob =
                            data.employmentStatus === "employed"
                              ? "Employed"
                              : data.employmentStatus === "selfEmployed"
                              ? "Self-Employed"
                              : data.employmentStatus === "student"
                              ? "Student"
                              : null;

                          const filteredJobObject =
                            data.employmentStatus === "employed"
                              ? data.employed
                              : data.employmentStatus === "selfEmployed"
                              ? data.selfEmployed
                              : data.employmentStatus === "student"
                              ? data.student
                              : null;
                          return (
                            <tr
                              className="border-b border-gray-300 py-2"
                              key={index}
                            >
                              <td className="text-secondary p-2">
                                {index + flexibleIndexOfFirstPost + 1}
                              </td>
                              <td className="p-2 text-nowrap capitalize">
                                {data.lastName.toLowerCase()}{" "}
                                {data.firstName.toLowerCase()}
                              </td>
                              <td className="p-2">{data.email}</td>
                              <td className="p-2">
                                {data.phoneNumber
                                  ? data.phoneNumber
                                  : "No number"}
                              </td>
                              <td className="p-2">
                                <button
                                  className="mr-3"
                                  type="button"
                                  title="call customer"
                                >
                                  <a href={`tel:${data?.phoneNumber}`}>
                                    <FaPhone />
                                  </a>
                                </button>
                                <button type="button" title="text via whatsapp">
                                  <a
                                    href={`https://wa.me/${data?.phoneNumber?.replace(
                                      /^0/,
                                      "234"
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <FaWhatsapp />
                                  </a>
                                </button>
                              </td>
                              <td className="p-2 text-center">
                                <button
                                  className="text-secondary border"
                                  onClick={() =>
                                    handleAddBank(
                                      data.firstName,
                                      data.lastName,
                                      data._id
                                    )
                                  }
                                >
                                  <IoAdd className="w-9 h-9" />
                                </button>
                              </td>
                              <td className="p-2 text-center">
                                <button
                                  className="bg-secondary text-white text-sm rounded-md p-2"
                                  onClick={() =>
                                    handleViewAllBank(
                                      data._id,
                                      data.linkedBanks
                                    )
                                  }
                                >
                                  View
                                </button>
                              </td>
                              <td className="p-2">
                                ₦{data.creditScore.toLocaleString()}
                              </td>
                              <td className="p-2">
                                ₦{data.creditLimit.toLocaleString()}
                              </td>
                              <td className="p-2 text-nowrap">
                                <button
                                  type="button"
                                  className="p-2 text-sm text-secondary border border-secondary"
                                  onClick={() => handleUpdateCreditLimit(data)}
                                >
                                  Update Credit Limit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                </tbody>
              </table>
            </section>
          </div>
          <section className="p-3 my-5">
            <Pagination
              length={
                searchResults.length > 0
                  ? searchResults.length
                  : customers.length
              }
              itemsPerPage={itemsPerPage}
              handlePagination={handlePaginationFlexible}
              currentPage={flexibleCurrentPage}
              prevPage={prevPageFlexible}
              nextPage={nextPageFlexible}
            />
          </section>
        </div>
      ) : (
        <div className="bg-white rounded-md shadow-md pb-6">
          <div
            className={`w-full overflow-x-auto ${
              isLoading.main && "animate-pulse h-[50vh]"
            }`}
          >
            <div
              className="flex items-center justify-between p-6"
              style={{ minWidth: "700px" }}
            >
              <div className="flex justify-between w-full">
                <h1 className="text-base font-semibold ">Outright Customers</h1>
                <div className="relative md:w-[30rem] w-fit">
                  <IoSearchOutline className="w-6 h-6 absolute top-[0.6rem] left-2 text-gray-300" />
                  <input
                    type="search"
                    name="searchedUser"
                    id="searchedUser"
                    value={searchedUser}
                    onChange={(e) => searchForUsers(e.target.value)}
                    placeholder="Search user using email"
                    className="border p-2 rounded-md indent-7 w-full"
                  />
                </div>
              </div>
            </div>
            <section className="overflow-x-auto">
              <table className="w-[1020px]" style={{ minWidth: "700px" }}>
                <thead className="bg-gray-50 font-bold p-4 text-left">
                  <tr>
                    <th className="p-2 ">S/N</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Email</th>
                    <th className="p-2">Phone number</th>
                    <th className="p-2">Contact</th>
                    <th className="p-2">Address</th>
                    <th className="p-2 text-nowrap">Verification stage</th>
                  </tr>
                </thead>
                <tbody className="px-4">
                  {searchResults.length > 0 && activeTab === "outright"
                    ? searchResults.map((data: any, index) => (
                        <tr
                          className="border-b border-gray-300 py-2"
                          key={index}
                        >
                          <td className="text-secondary p-2">
                            {index + outrightIndexOfFirstPost + 1}
                          </td>
                          <td className="p-2 text-nowrap">
                            {data.firstName ? data.lastName : null}{" "}
                            {data.lastName ? data.firstName : null}
                          </td>
                          <td className="p-2 text-nowrap">{data.email}</td>
                          <td className="p-2 text-nowrap">
                            {data?.phoneNumber || "No number"}
                          </td>
                          <td className="p-2 text-nowrap flex justify-evenly">
                            <button type="button" title="call customer">
                              <a href={`tel:${data?.phoneNumber}`}>
                                <FaPhone />
                              </a>
                            </button>
                            <button type="button" title="text via whatsapp">
                              <a
                                href={`https://wa.me/${data?.phoneNumber?.replace(
                                  /^0/,
                                  "234"
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaWhatsapp />
                              </a>
                            </button>
                          </td>
                          <td className="p-2 text-nowrap">
                            {data.deliveryAddress ? data.deliveryAddress : null}
                          </td>
                          <td className="p-2 text-nowrap">{data.isComplete}</td>
                          <td className="p-2">
                            {data.userPassword
                              ? data.userPassword
                              : "not found"}
                          </td>
                        </tr>
                      ))
                    : outrightCurrentItems.map((data: any, index: number) => {
                        return (
                          <tr
                            className="border-b border-gray-300 py-2"
                            key={index}
                          >
                            <td className="text-secondary p-2">
                              {index + outrightIndexOfFirstPost + 1}
                            </td>
                            <td className="p-2 text-nowrap">
                              {data.firstName ? data.lastName : null}{" "}
                              {data.lastName ? data.firstName : null}
                            </td>
                            <td className="p-2 text-nowrap">{data.email}</td>
                            <td className="p-2 text-nowrap">
                              {data?.phoneNumber || "No number"}
                            </td>
                            <td className="p-2 text-nowrap flex justify-evenly">
                              <button type="button" title="call customer">
                                <a href={`tel:${data?.phoneNumber}`}>
                                  <FaPhone />
                                </a>
                              </button>
                              <button type="button" title="text via whatsapp">
                                <a
                                  href={`https://wa.me/${data?.phoneNumber?.replace(
                                    /^0/,
                                    "234"
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaWhatsapp />
                                </a>
                              </button>
                            </td>
                            <td className="p-2 text-nowrap">
                              {data.deliveryAddress
                                ? data.deliveryAddress
                                : null}
                            </td>
                            <td className="p-2 text-nowrap">
                              {data.isComplete}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </section>
          </div>
          <section className="p-3 my-5">
            <Pagination
              length={
                searchResults.length > 0
                  ? searchResults.length
                  : outright.length
              }
              itemsPerPage={itemsPerPage}
              handlePagination={handlePaginationOutright}
              currentPage={outrightCurrentPage}
              prevPage={prevPageOutright}
              nextPage={nextPageOutright}
            />
          </section>
        </div>
      )}

      {viewUpdateCreditLimit && (
        <UpdateCreditLimit
          userData={userData}
          closeModal={() => setViewUpdateCreditLimit(false)}
        />
      )}

      {allBanksModal && (
        <AllBanksModal
          setAllBanksModal={setAllBanksModal}
          setAllBanksDetails={setAllBanksDetails}
          allBanksDetails={allBanksDetails}
          userId={userId}
        />
      )}

      {addBankModal && (
        <AddBankModal
          setAddBankModal={setAddBankModal}
          userToAddBank={userToAddBank}
        />
      )}

      {bvnModalOpen && (
        <BVNVerificationModal
          setBvnModalOpen={setBvnModalOpen}
          selectedBvn={selectedBvn}
        />
      )}

      {selfEmployedModalOpen && (
        <SelfEmployed
          setSelfEmployedModalOpen={setSelfEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {employedModalOpen && (
        <Employed
          setEmployedModalOpen={setEmployedModalOpen}
          selectedInfo={selectedInfo}
        />
      )}
      {studentModalOpen && (
        <Student
          selectedInfo={selectedInfo}
          setStudentModalOpen={setStudentModalOpen}
        />
      )}
    </main>
  );
};

export default UserDetails;
