import { useState } from "react";
import { Preloader } from "./elements/Preloader";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import toast from "react-hot-toast";
import { verificationStageOne } from "../redux/thunk";

const DisapproveModal = ({
  handleClearSelection,
  id,
  setId,
  setActionSelected,
  stage,
  getAllUsers,
}: any) => {
  const { token } = useAppSelector((store) => store.auth);
  const [disapproveMsg, setDisapproveMsg] = useState("");
  const [selectedDowngradeStage, setSelectedDowngradeStage] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch()

  const formValidation = () => {
    let hasErrors = false;
    const newErrors = { reason: "", stage: "" };

    if (disapproveMsg === "".trim()) {
      newErrors.reason = "Cannot be empty";
      hasErrors = true;
    }

    if (selectedDowngradeStage === "") {
      newErrors.stage = "Select a stage";
      hasErrors = true;
    }
    setErrors(newErrors);
    return hasErrors;
  };

  const disaproveCustomerHandler = async () => {
    const stageOneVerificationEndpoint = "disapproveStageOneProcess";
    const stageTwoVerificationEndpoint = "disapproveStageTwoProcess";

    if (formValidation()) return;
    const payload = {
      reason: disapproveMsg,
      stage: selectedDowngradeStage,
    };
    setIsLoading((prev) => !prev);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/${
          stage === "documentCheck"
            ? stageTwoVerificationEndpoint
            : stageOneVerificationEndpoint
        }/${id}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setId("");
      setDisapproveMsg("");
      setActionSelected("");
      if (stage === "documentCheck") {
        getAllUsers()
      } else {
        dispatch(verificationStageOne())
      }
      toast.success(res.data.message);
      handleClearSelection()
    } catch (error: any) {
      console.error(error);
      setActionSelected("");
      toast.error(error.message || "Disapprove failed!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed z-40 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between  flex-col text-dark p-2 rounded-t-md mb-4">
          <div className="relative w-full">
            <button
              onClick={handleClearSelection}
              className="text-dark absolute right-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <p className="font-semibold text-lg text-center">
            Disapprove this user?
          </p>
          <label htmlFor="reason" className="font-medium">
            Please state your reason:
          </label>
          <textarea
            name="reason"
            id="reason"
            className="border my-2 text-sm p-2"
            placeholder="What is your reason?"
            value={disapproveMsg}
            onChange={(e) => setDisapproveMsg(e.target.value)}
            cols={30}
            rows={5}
          ></textarea>
          {errors.reason && (
            <span className="text-red-500 text-sm">{errors.reason}</span>
          )}
          <label htmlFor="downgradeStage" className="font-medium">
            Select stage to downgrade to:
          </label>
          <select
            name="downgradeStage"
            id="downgradeStage"
            className="border p-2 my-2 text-sm"
            onChange={(e) => setSelectedDowngradeStage(e.target.value)}
          >
            <option value="">Select</option>
            <option value="bvnVerification">Work information</option>
            <option value="outRight">Switch to Outright</option>
          </select>
          {errors.stage && (
            <span className="text-red-500 text-sm">{errors.stage}</span>
          )}

          <div className="flex items-center justify-center mt-3">
            <button
              type="button"
              className="w-20 px-5 py-3 mr-3 rounded text-white bg-[#008b50]"
              onClick={disaproveCustomerHandler}
            >
              {isLoading ? <Preloader /> : "Yes"}
            </button>
            <button
              type="button"
              className="w-20 px-5 py-3 bg-red-500 rounded text-white "
              onClick={handleClearSelection}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisapproveModal;
